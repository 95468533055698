﻿/*------------------------------------*\
    HeaderImage
\*------------------------------------*/
.header-section-container{
	top:27px;
	left: 0;
	z-index: 10;
	&.header-content-section {
		top: 0 !important
	}
}

.header-image {
    width: 100%;
    height: 95px;
    overflow: hidden;
    z-index: -1;
    position: absolute;
    margin-left: 0;

    img {
        height: inherit;
        width: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
    }
}
.logo-group {
	margin: 0;
	position: relative;
	bottom: 1px;
}

.deloitte-rp-logo {
	margin: 10px 125px 0 0;
	.primary-logo {
		width: 87px;
		max-width: 87px;
		margin: 0 0 0 30px;
	}
	
}

.header-container {
	.support-link {
		background-image: url(/images/question-mark-default.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 24px 24px;
		width: 24px;
		height: 24px;
		margin: 6px 10px 0 0;
		padding: 0 0 0 15px;
		// PBI 1955534: Disable: Language Selector
		// border-left: 1px solid $brandedcolor1;
		&:hover {
			background-image: url(/images/question-mark-hover.svg);
		}
		&:active {
			background-image: url(/images/question-mark-pressed.svg);
		}
	}
	&.catalog-header {
		.support-link {
			background-image: url(/images/question_mark-default.svg);
			@include darkMode {
				background-image: url(/images/question-mark-default.svg);
			}
			&:hover {
				background-image: url(/images/question_mark-hover.svg);
				@include darkMode {
					background-image: url(/images/question-mark-hover.svg);
				}
			}
			&:active {
				background-image: url(/images/question_mark-pressed.svg);
				@include darkMode {
					background-image: url(/images/question-mark-pressed.svg);
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.logo-group {
		min-width: 342px;
	}
}
.researchAssistant{
	background-color: white;
	border: 1px solid #d9d9d9;
	display: flex;
	align-items: center;
	justify-content: left;
	font-family: "Roboto", Verdana, sans-serif;
	color: black;
	font-weight: 500;
	padding: 12px 15px;
	box-sizing: border-box;
	border-radius: 4px;
	cursor: pointer;
	@include darkMode {
		background: #212121;
	}
	.chat-icon-img {
		width: 18px;
	}
	.researchAssistant-label {
		padding: 0px 14px;
		cursor: pointer;
		@include darkMode {
			color: #F5F5F5;
		}
	}
	.goto-next-icon {
		width: 12px
	}
	}

	//Outage Notification
	.outage-notification-outer {
		position: relative;
		height: 43px;
		width: auto;
		margin: auto 0;
		z-index: 5;
		.notification-bell-container {
			margin: 6px 10px 0 0;
			padding: 0 10px 0px 0px;
			.notification-bell {
					background: url(/images/bell-ico.png) no-repeat 100%;
					background-size: 28px 28px;
					border: none;
					height: 28px;
					width: 28px;
					cursor: pointer;
					@include darkMode {
						background: url(/images/bell-ico-dark.svg) no-repeat 100%;
						background-size: 28px 28px;
					} 
				}
			.notification-bell.notification-modal-open
			{
				background: url(/images/bell-ico-open.png) no-repeat 100%;
				background-size: 28px 28px;
				border: none;
				height: 28px;
				width: 28px;
				cursor: pointer;
				@include darkMode {
					background: url(/images/bell-ico-open-dark.svg) no-repeat 100%;
					background-size: 28px 28px;
				} 
			}
		}
		.outage-notification-items-box {
			width: 300px;
			border: 1px solid #d9d9d9;
			position: absolute;
			right: 28px;
			top: 48px;
			border-radius: 10px;
			color: inherit;
			z-index: 1;
			background: #f5f5f5;
			background: var(--theme-bg);
			overflow: hidden;
			box-shadow: 0 4px 4px #b8b8b885;
			max-height: 349px;
    		overflow-y: scroll;

			&::-webkit-scrollbar {
				width: 8px;
				background: #ffffff;
		 
				@include darkMode {
					background: #212121;
				}
			}
		 
			&::-webkit-scrollbar-thumb {
				background: #e0e0e0;
				border-radius: 4px;
		 
				@include darkMode {
					background: rgba(255, 255, 255, 0.54);
				}
			}
		 
			&::-webkit-scrollbar-track {
				background: #ffffff;
		 
				@include darkMode {
					background: #212121;
				}
			}

			.hide {
				display: none;
			}
			.outage-notification-item {
				padding: 0px 10px 0px 0px;
				margin-top: 10px;
				.notification-message-dot{
					height: 8px;
					width: 8px;
					border-radius: 50%;
					display: inline-block;
					margin-left: -25px;
					
				}
				.notification-message {
					margin-left: 40px;
					.blue{
						background-color: #0076a8;
					}
					.gray{
						background-color: gray;
					}
					.message-content{
						margin-top: 5px;
					}
				}

			
				.message-title{
					margin-left: 16px;
				}
			}
			.message-divider{
				border-top: 1px solid #cccccc;
				display: block;
				margin-right: -10px;
				position: relative;
			}
		
	}
	.outage-notification-items-box {
		border-radius: 0;
	
	.no-notification {
		padding: 0 10px 0 0;
		margin-top: 10px;
		
		.message-content{
			margin-left: 40px;
		}
	}
}
	}
	
	