
.toc-item-icon {
    background-image: var(--toc-item-icon);
    background-repeat: no-repeat;
    max-width: 18px;
    width: 100%;
    height: 20px;
    margin-top: -3px;
	border: none;
	background-color: unset;
    transition: background-image 0.2s ease;
    @include darkMode {
        transition: none;
    }
    
}

.toc-item-head {
   margin-bottom: 8px;    
}

.bookmark-item-icon {
    background: url(/images/bookmark_dkmode_blackstar_addcolor.svg) no-repeat center center;
    background-size: 38px 38px;
    @include darkMode {
        background: url(/images/bookmark_dkmode_addcolor.svg) no-repeat center center;
    }
}
.expandable-bookmark-item-icon{
    margin-top: 3px;
}
.toc-item-icon-artice-icon {
    margin-top: 8px;
}

.toc-item-icon-expandable-open {
    background-image: url(/images/node-minus.svg);
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    margin-left: 5px;
    background-size: 15px 15px;
    margin-top: 5px;
    &:hover {
        background-image: url(/images/node-minus.svg);
        @include darkMode {
            filter: invert(100%);
        }
    };

    @include darkMode {
        filter: var(--invert-colors-amount);
    }
}

// .toc-item-icon-expandable-realign {
//     margin-top: 5px;
// }

.toc-item-icon-expandable-closed {
    background-image: url(/images/node-plus.svg);
    background-repeat: no-repeat;
    margin-left: 5px;
    background-size: 15px 15px;
    margin-top: 5px;
    &:hover {
        background-image: url(/images/node-plus.svg);
    }
    @include darkMode {
        filter: var(--invert-colors-amount);
    }
}

.icon-closed-sidebar-container {
    width: 38px;
    height: 38px;
    display: flex;
    border-radius: 5px;
    // border: 1px solid var(--main-border-color);
    margin-left: 15px;
    // top: 0px;
    position: absolute;
    transition: 0.2s border-color;

    &:hover {
        border-color: $middark_cool !important;
    }
    //padding-top: 15px; /* <-- cross verify if this is needed (May need to remove) */
}

// check this **
.icon-closed-sidebar {
    position: absolute;
    // background-image: $icon-expand;
    background-repeat: no-repeat, no-repeat;
    background-position: calc(100% - 4px) calc(100% - 3px), center center;
    background-size: 14px 14px;
    width: 38px;
    height: 38px;
    background-color: inherit;
    border: none;
    z-index: 1;
    
    filter: var(--invert-colors-amount);
    &:hover {
        // background-image: $icon-expand-hover;
    }

    @include darkMode {
        background-color: inherit;
        &:hover {
            filter: invert(0);
        }
    }

    padding-top: 15px; /* <-- cross verify if this is needed (May need to remove) */
}

.icon-closed-sidebar-img {
    height: 100%;
    width: 40px;
    margin: auto !important;
    filter: var(--invert-colors-amount);
    border-radius: 50%;
    border: 2px solid black;
}

.icon-closed-sidebar-container-fixed-nav {
    position: fixed;
    top: -5px;
    z-index: 9999999999;
}

// .nonprod-body-container .icon-closed-sidebar-container-fixed-nav {
//     top: 23px  !important;
// }

.icon-closed-sidebar-guidebook {
    background-image: $icon-expand, $icon-guidebook;
    &:hover {
        background-image: $icon-expand-hover, $icon-guidebook;
    }
}

.icon-closed-sidebar-journal {
    background-image: $icon-expand, $icon-journal;
    &:hover {
        background-image: $icon-expand-hover, $icon-journal;
    }
}

.guidebook-section-toc-open {
    background-image: $caret-down-black;
    background-position: center center;
    background-size: 10px 10px;
    margin: 2px 0px;
    margin-top: 0px;
    &:hover {
        background-image: $caret-down-black;

        @include darkMode {
            filter: var(--invert-colors-amount);
        }
    }

    @include darkMode {
        filter: var(--invert-colors-amount);
    }
}

.guidebook-section-toc-closed {
    background-image: $caret-right-black;
    background-position: center center;
    background-size: 10px 10px;
    margin: 2px 0px;
    margin-top: 0px;
    &:hover {
        background-image: $caret-right-black;
    }

    @include darkMode {
        filter: var(--invert-colors-amount);
    }
}

.toc-item-text-container {
    border-radius: 5px;
    padding: 0.15em;
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid transparent;
    word-break: break-word;
	font-weight: 500;
    // margin-top: 7px;
    &:hover {
        text-decoration: underline;
    }
}

.toc-item-text-container-selected {
    background: $brandedcolor30;
    margin-right: 5px;
	border-radius: 7px;

    @include darkMode {
        color: $darkest_neutral;
    }

}

.link-guidebook-section-toc {
    padding-left: 5px;
    font: 12px "Roboto", sans-serif;
    font-weight: 400;
    margin-bottom: 15px;
    border: none !important;
}

.sidebar-toc-hidden {
    width: 0px;
    //height: 0px;
    visibility: hidden;
}

.sidebar-toc-item-link {
    font: 12px "Roboto", sans-serif;
    border-bottom: none;
    background: none;
    transition: all 0.2s ease;
    padding: 0 0 0 5px;
    width: calc(100% - 18px);
    margin: auto;
    text-decoration: none;
    box-sizing: border-box;

    &:hover {
        border-bottom: none;
    }
}

.sidebar-toc-icon-next-to-title {
    height: 30px;
    width: 30px;


    @include darkMode {
        filter: invert(100%);
    }
}

.sidebar-toc-title-link {
    border: none !important;
    margin-left: 8px;
    margin-right: 8px;
    transition: color .3s;
	font-weight: 700;
    &:hover {
        color: $middark_cool;
    }
}

.sidebar-toc-title-link-fixed {
    white-space: nowrap;
    overflow: hidden;
     text-overflow: ellipsis;
     width: calc(100% - 70px);
     margin-top: 6px;
}

.sidebar-toc-title-and-icon-x-outer-container-fixed {
    // margin: auto 0 auto 1px;
    
    // display: flex;
    // position: relative;
    // width: calc(100% - 31px);

    display: flex;
    align-items: center;
    width: 100%;
}

.sidebar-toc-title-container-fixed {
    // transform: translateX(-16px);
    // overflow: hidden;
}

.align {
    align-items: center;
}
.sidebar-toc-title-and-icon-container {
    display: flex;
    padding-top: 19px;
    padding-bottom: 19px;
    align-items: center;
    //margin: auto;
}
.opacity-zero {
    display: none;
}

.toc-icon-fixednav-article {
    margin: auto;
    margin-right: 0;
}

.toc-item-icon-collapse-fixednav-article {
    background-image: url(/images/dbl-chevron-black.svg);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: transparent;
    border: none;
    text-indent: -9999px;
    height: 20px;
    width: 20px;
    min-width: 20px;
    margin-top: 5px;
     margin-left: 10px;
    margin: auto ;
    margin-right: 0;
    cursor: pointer;
        
    @include darkMode {
        background-image: url(/images/dbl-chevron-white.svg);
    }
    
}
.nav-article {
    margin: 12px auto auto -8px ;
}

.toc-item-icon-collapse-sidebar-toc {
    background-image: url(/images/dbl-chevron-black.svg);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: transparent;
    border: none;
    text-indent: -9999px;
    height: 20px;
    width: 20px;
    min-width: 20px;
     margin-top: 5px;
     margin-left: 10px;
    margin: 0 0 0 auto;
    cursor: pointer;
        
    @include darkMode {
        background-image: url(/images/dbl-chevron-white.svg);
    }
    
}

.toc-item-icon-collapse-sidebar-toc-selected {
    background-image: url(/images/dbl-chevron-black.svg);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: transparent;
    border: none;
    text-indent: -9999px;
    height: 20px;
    width: 20px;
    min-width: 20px;
     margin-top: 5px;
     margin-left: 10px;
    margin: auto ;
    margin-right:-10px;
    cursor: pointer;
        
    @include darkMode {
        background-image: url(/images/dbl-chevron-white.svg);
    }

}

.binary-download-open {
    background-image: url(/images/chevron-default.svg);
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    margin-left: 7px;
    background-size: 8px 15px;   
    display: inline;

    &:hover {
        //background-image: url(/images/node-minus.svg);

        @include darkMode {
            filter: invert(100%);
        }
    };

    @include darkMode {
        filter: var(--invert-colors-amount);
    }
}

.binary-download-close {
    background-image: url(/images/chevron-default.svg);
    height: 19px;
    width: 18px;
    background-repeat: no-repeat;
    margin-left: -4px;
    background-size: 8px 26px;        
    display: inline;
    transform: rotate(180deg);    

    &:hover {
       // background-image: url(/images/node-minus.svg);

        @include darkMode {
            filter: invert(100%);
        }
    };

    @include darkMode {
        filter: var(--invert-colors-amount);
    }
}
.binary-panel {
    font-size: 10px;
    padding-left: 25px;
    float: left;
}

.binary-panel-article {
    padding-left: 0px;
    display: inline;

    @include darkMode {
       color: #e8e8e8;
    }
}

.download-button-div {
    float: right;
    width: 30%;
    padding-top: 0.25em;
}

// .folio-picker-dropdown-main {
.folio-picker {
    width: calc(100% - 50px);
   // width: calc(100% - 32px);
   .foliopicker-dropdown-main .dd {
    position: inherit;
   }

.an-dd-folio-label {
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    font-family: "Roboto";
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    transition: color .3s;
    font-weight: 700;
}

.folio-title {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.an-dd-folio-item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px;
    padding: 0.35rem 0.75rem;
    width: 100%;
    max-width: 100%;
      background-color: $lightest_neutral;
    display: flex;
    flex-direction: row;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        background: #f5f5f5 !important;
        color: #000000 !important;

        @include darkMode {
            background: rgba(255, 255, 255, 0.87) !important;
            color: rgba(0, 0, 0, 0.87) !important;
        }
    }
   
 
    
    @include darkMode {
        color: $brandedcolor3;
        background-color: $brandedcolor7;
    }
}

.an-dd-folio-item.no-options {    
    color: #a3a3a3;
    justify-content: center;
}


.ad-dd-folio-item--selected {
    background-color: $brandedcolor30;
  }
  @include darkMode {
    color: $brandedcolor3;
    background-color: $brandedcolor7;
  }
}

.folio-fixed{
    width: calc(100% - 32px);
    .foliopicker-dropdown-main .dd {
        position: relative;
    }
}

.dd__trigger .an-dd-folio-trigger {
    background-image: url(/images/dropdown-arrow-gray.svg);
    background-position: calc(100% - 10px) center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    border: 1px solid #d9d9d9;
    background-color: $lightest_neutral;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px;
    padding: 0.35rem 0.75rem;
    width: 100%;
    max-width: 100%;
    height: 36px;
    cursor: pointer;

    @include darkMode {
        border: 1px solid #d9d9d9;
        background-image: url(/images/dropdown-arrow-blue-default-darkmode.svg);
        color: $lightest_neutral;
        background-color: $brandedcolor7;
        background-size: 30px 30px;
    }
}

.dd__trigger--active .an-dd-folio-trigger{

    background-image: url(/images/up-arrow-gray.png);
    background-position: calc(100% - 10px) center;
    background-size: 15px 10px; 
    background-repeat: no-repeat;
    border: 1px solid #d9d9d9;
    background-color: $lightest_neutral;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px;
    padding: 0.35rem 0.75rem;
    width: 100%;
    max-width: 100%;
    height: 36px;

    @include darkMode {
        border: 1px solid $brandedcolor8;
        background-image: url(/images/up-arrow-blue.png);
        color: $lightest_neutral;
        background-color: $brandedcolor7;
        background-size: 15px 10px;
    }
}

.an-dd-folio-selection {
    width: 8px;
    height: 8px;
    border-radius: 55rem;
    margin-right: 8px;
    flex-shrink: 0;
    background-color: #00a3e0;
    
}
.an-dd-folio-non-selection {
    width: 8px;
    height: 8px;
    border-radius: 55rem;
    margin-right: 8px;
    flex-shrink: 0;
}

.an-dd-container-folio {
    //left: -46px;
    right: 0;
    box-sizing: border-box;
    position: absolute;
    box-shadow: 0 4px 4px #b8b8b885;
    border-radius: 0px;
    z-index: 0;
    border-top: 1px solid #d9d9d9;
    margin-bottom: auto;
    /* min-height: 427px; */
    display: flex;
    width: 100%;
    left: 0;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    top: 55px !important;
    border: none !important;
    border-top: 1px solid #d9d9d9 !important;
    min-height: auto;
    bottom: 0;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 8px;
        background: #ffffff;
 
        @include darkMode {
            background: #212121;
        }
    }
 
    &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
        border-radius: 4px;
 
        @include darkMode {
            background: rgba(255, 255, 255, 0.54);
        }
    }
 
    &::-webkit-scrollbar-track {
        background: #ffffff;
 
        @include darkMode {
            background: #212121;
        }
    }
        
}
.container-folio-fixed {
    top: 36px !important;
    right: -35px;
    left: auto;
}


 .an-dd-panel-folio {
    width: 100%;
}
.folio-search-input {
    border: none;
    width: 82%;
    max-width: 100%;
    height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include darkMode {
        outline: none;
        color: #e8e8e8;
        background: #212121;
    }
}
.folio-search-input::placeholder {
    opacity: 1 !important;
        font-weight: bold !important;
        font-family: "Roboto";
        color: #000;
        @include darkMode {
              outline: none;
              color: #e8e8e8;
              background: #212121;
          }

}

.placeholder-bold::placeholder {
    opacity: 0.7 !important;
        font-weight: normal !important;
        font-family: "Roboto";
        color: #666;
}






