.header-reading-mode {
    background: white;
    border-bottom: 1px solid $brandedcolor1;
    height: 40px;
    position: sticky;
    left: 0;
    z-index: 2;
    top: 8px;
    box-shadow: 0 1px 0 $brandedcolor1;

    @include darkMode {
        background: $brandedcolor7;
        border-bottom: 1px solid $color13;
        box-shadow: none;
    }

    .reading-mode-btn {
        margin: 8px 8px 7px 8px;
    }
}

.fixed-tools-container-bottom-reading-mode {
    position: fixed;
    align-items: center;
    top: calc(100% - 110px);
    transform: translateY(-50%);
    right: calc(2% + 17px);
    margin-left: auto;
}


.icon-scroll-to-top-reading-mode {
    height: 58px;
    width: 58px;
    padding-top: 8px;
    margin-right: 0;
    top: 90%;
    background: transparent url(/images/top_of_article-default.svg) no-repeat center center;
	border: none;

    @include darkMode {
        background: transparent url(/images/top_of_article-default-dark.svg) no-repeat center center;
    }
}

.icon-scroll-to-top-reading-mode:hover {
    height: 58px;
    width: 58px;
    background: transparent url(/images/top_of_article-hover.svg) no-repeat center center;
    cursor: pointer;

    @include darkMode {
        background: transparent url(/images/top_of_article-hover-dark.svg) no-repeat center center;
    }
}

.icon-scroll-to-top-reading-mode:active {
    height: 58px;
    width: 58px;
    background: transparent url(/images/top_of_article-pressed.svg) no-repeat center center;
    cursor: pointer;

    @include darkMode {
        background: transparent url(/images/top_of_article-pressed-dark.svg) no-repeat center center;
    }
}

.icon-scroll-top-label-reading-mode {
    font-size: 0.53em;
    font-weight: 600;
    color: #A3A3A3;

}

.pub-type-logo-reading-mode-outer-container {
    margin: auto 16px;
    border: 1px solid var(--main-border-color);
    border-radius: 15px;
    transition: border-color .2s linear;
}

.pub-type-logo-reading-mode {
    height: 28px;
    width: 28px;
    background-color: inherit;
    display: block;
    border: none;
    filter: var(--invert-colors-amount);
    padding: 0;
}

.pub-type-logo-reading-mode-img {
    height: 25px;
    width: 25px;
    display: flex;
    margin: auto;
}

.reading-mode-in-article-toc-toggle {
    box-sizing: border-box;
    // border: 1px solid var(--main-border-color);
    // border-radius: 5px;
    border: none;
    background-color: transparent;
    height: 34px;
    padding-right: 3em;
    width: 48px;
    margin: auto 8px;
    transition: border-color .2s linear;
}

.reading-mode-in-article-toc-toggle-off {
    background-image: url(/images/toc-default.svg);
    background-repeat: no-repeat;
    background-position: calc(50% - 2px) center;
    background-size: 28px 28px;

    @include darkMode {
        background-image: url(/images/toc-hover.svg);
    }
}

.pub-type-logo-reading-mode-outer-container:hover,
.reading-mode-in-article-toc-toggle-off:hover {
    border-color: #0097a8;
}

.reading-mode-in-article-toc-toggle-on {
    background-image: url(/images/toc-default.svg);
    background-repeat: no-repeat;
    background-position: calc(50% - 2px) center;
    background-size: 28px 28px;
    border-color: #00b500;

    @include darkMode {
        background-image: url(/images/icon-article-toc-on-wh.svg) !important;
    }

}

.reading-mode-in-article-toc-toggle-on:hover {
    border-color: #00b500;
}

.header-title-reading-mode {
    font-size: 16px;
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: clamp(20px, 3%, 50px);
    padding-left: 5px;
    box-sizing: border-box;
    width: 0px;
}

.dashboard-link {
    display: block;
}

.divide-bar{
    width: 90%;
    height: 1px;
    background-color: #ccc;
    border: none;
}

.toggle-container {
    padding: '15px 0'
}


.text-toggle {
    font-size: 10.5px;
    white-space: nowrap;
    margin-right: 0px;
}

.rm-text {
    font-weight: 700;
}

.toggle-reading-mode-on {
    background: url(/images/rev-read-mode-rm.svg) no-repeat center center;
    background-size: 38px 38px;
    width: 38px;
    border: none;
    padding-left: 93px;
    top: 120px;
    display: flex;
    position: fixed;
    right: calc(2% + -4px);
    background-color: transparent;
    height: 38px;

    &:hover {
        background: url(/images/rev-read-mode-hover-rm.svg) no-repeat center center;
        background-size: 38px 38px;
    }

    &:active {
        background: url(/images/rev-read-mode-pressed-rm.svg) no-repeat center center;
        background-size: 38px 38px;
    }
}

.toggle-reading-mode-off {
    background: transparent url(/images/read-mode.svg) no-repeat 50%;
    background-size: 38px 38px;
    width: 38px;
    border: none;

    &:hover {
        background: url(/images/read-mode-hover.svg) no-repeat center center;
        background-size: 38px 38px;
    }

    &:active {
        background: url(/images/read-mode-pressed.svg) no-repeat center center;
        background-size: 38px 38px;
    }

    @include darkMode {
        background-image: url(/images/rev-read-mode-default.svg);
        background-size: 38px 38px;

        &:hover {
            background: url(/images/rev-read-mode-hover.svg) no-repeat center center;
            background-size: 38px 38px;
        }

        &:active {
            background: url(/images/rev-read-mode-pressed.svg) no-repeat center center;
            background-size: 38px 38px;
        }
    }
}

.pannels-container-reading-mode {
    width: 100%;
    margin-top: -34px;
}

.article-container-reading-mode {
    width: 62%;
    padding: 35px 5px 0 5px;
    margin: 0 19%;
    box-sizing: border-box;
    min-height: calc(91vh + 6px);

    embed {
        padding: 10px 40px 0 40px;
        width: calc(100% - 80px);
        height: calc(100vh - 48px);
    }

    a:not(.normal-link-in-reading-mode) {
        color: inherit;
        cursor: default;
    }
}

.article-container-reading-mode-for-pdf {
    padding: 0 16px;
    margin: 0;
    width: 90%;
    min-height: 100vh;
    .article-title-button-container h2 {
        padding-top: 20px;
    }
}

.article-container-reading-mode-with-toc-displaying {
    width: 67%;
    margin-left: 0;
    margin-right: 0;
    padding-right: 8%;
}

.side-pannel-reading-mode {
    width: 32%;
    padding: 0 50px;
    box-sizing: border-box;
}

.in-article-toc-reading-mode {
    border-left: 1px solid $brandedcolor1;
    border-right: 1px solid $brandedcolor1;
    height: calc(100vh - 40px - 8px); // 40px is the header height // 8px is horizontal scrollbar
    max-width: 345px;
    width: 100%;
    overflow-y: auto;
    position: sticky;
    top: 48px; // horiz scrollbar height & header height

}

.article-jump-links-reading-mode {
    padding-left: 16px;
    padding-right: 16px;
    max-height: none;
}

.article-title-reading-mode {
    display: none;
    font-size: 24px;
    margin-bottom: 20px;
}

.footer-reading-mode {
    margin-left: 2%;
}

.article-icon-readingmode {
    background-color: var(--rm-btn-bg-color);
    display: flex;
    position: fixed;
    top: 220px;
    right: calc(2% + 25px);
    height: 38px;
    width: 38px;
    padding: 3.4px;
    z-index: 1;
    cursor: pointer;
}
.download-icon-reading-mode{
    background-color: var(--rm-btn-bg-color);
    display: flex;
    position: fixed;
    top: 170px;
    right: calc(2% + 25px);
    height: 38px;
    width: 38px;
    padding: 3.4px;
    z-index: 1;
    cursor: pointer;
}

.icon-article-readingmode {
    top: 170px;
    display: flex;
    position: fixed;
    right: calc(2% + 25px);
    background-color: var(--rm-btn-bg-color);
    height: 38px;
    width: 38px;
}