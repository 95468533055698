
/*------------------------------------*\
    TIME CAPSULE
\*------------------------------------*/

.compare-versions-header {
	background-color: $brandedcolor17;
	color: $lightest_neutral;
	padding: 20px 30px 30px 30px;
	border-radius: 5px 5px 0 0;
	margin-top: 15px;
	width: 100%;
	box-sizing: border-box;
    position: sticky;
    top: 0;
	z-index: 1;

    @include darkMode() {
		background-color: $darker_neutral;
	}

	.compare-versions-title {
		font-size: 26px;
		margin: 0;
	}
}

.compare-versions-header-fixed {
    border-radius: 0;
}

.compare-versions-title-and-version-container {
	flex-grow: 1;
}

.compare-versions-published-version-text-container {
    font-size: 14px;
}

.compare-versions-published-version-text {
    margin-right: 5px;
    font-weight: 700;
}

.toggle-compare-versions-container {
    margin: 0 50px;
}

.toggle-compare-versions {
	background: url(/images/icon-toggle-off-wh.svg) no-repeat center center;
    background-size: 38px;
	width: 38px;
	height: 38px;
	border: none;
	margin-left: 10px;
	margin-top: 3px;
}

.toggle-compare-versions-on {
	background-image: url(/images/icon-toggle-on.svg);
	background-size: 43px;
}

.compare-versions-past-versions-dropdown-container {
    width: 250px;
}

.compare-versions-article-title {
	font-size: 26px;
}

.compare-versions-article-body {
	border-radius: 0 0 5px 5px;
	box-sizing: border-box;
	width: 100%;
	border: 1px solid $brandedcolor1;
	padding: 10px 30px;
	p, .p {
		display: flow-root;
	}
}
.compare-versions-linktext {
	font-weight: 700;
	font-size: 7px; 
	transform: scale(1.22); 
	margin: 6px auto auto auto; 
	text-align: center;
	margin-left: 47px;
}

.compare-version-body {
    display: none;
	margin: 100px 0 40px 0;
}
