.an-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2341234;
    background-color: rgba(0, 0, 0, 0.7);
}

.an-modal {
    position: absolute;
    width: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $lightest_neutral;
    border: 1px solid $midlight_neutral;
    border-radius: 7px;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15),
        0 0 1px rgba(9, 30, 66, 0.31);

    @include darkMode {
        background-color: $brandedcolor7;
        color: $lightest_neutral;
        border: 1px solid $brandedcolor1;
    }
}

.annot-modal {
    padding: 0 0 1em 0;
    width: 50%;
    background: $lightest_neutral;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 7px;
    height: auto;
    min-height: 240px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    @include darkMode {
        background-color: $brandedcolor7;
    }
}


.an-modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $brandedcolor1;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 0.35rem 0.5rem 0.35rem 1rem;
    box-sizing: border-box;
    height: 44px;
    background: $brandedcolor16;

    @include darkMode {
        background-color: $brandedcolor19;
    }
}

.an-modal-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: $darkest_neutral;

    @include darkMode {
        color: $lightest_neutral;
    }
}

.an-modal-close {
    background: var(--icon-close) no-repeat center center;
    background-size: 20px 20px;
    display: block;
    height: 20px;
    width: 20px;
    transition: transform 0.2s ease;
    border: none;

    &:hover {
        transform: rotate(90deg);
        transition: transform 0.2s ease;
    }
}

.an-modal-content {
    margin: 33px 54px 11px 54px;
}

.an-modal-with-notification-content {
    margin: 12px 54px 12px 54px;
}

.an-notification-close {
    background: var(--icon-close) no-repeat center center;
    background-size: 20px 20px;
    display: block;
    margin-left: 48px;
    transition: transform 0.2s ease;
    border: none;

    &:hover {
        transform: rotate(90deg);
        transition: transform 0.2s ease;
    }
}

.an-modal-comment {
    display: flex;
    margin-top: 18px;
    box-sizing: border-box;
    width: 100%;
    height: 69px;
    border: 1px solid $brandedcolor1;
    border-radius: 2px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;

    @include darkMode {
        background-color: $lightest_neutral;
        border: 1px solid $brandedcolor18;
    }
}

.an-modal-comment-input {
    appearance: none;
    resize: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 10px 0;
    border: none;
    width: 100%;

    &:focus-visible {
        outline: none;

        @include darkMode {
            outline: none;
        }
    }
    &::selection {
        @include darkMode {
            background: #c1c1c1;
        }
    }
}

.an-modal-comment-count {
    font-size: 14px;
    padding-right: 9px;
    color: $midlight_neutral;
}

.an-modal-footer {
    margin-top: 0.8rem;
    text-align: right;
}

.an-modal-save {
    appearance: none;
    font-size: 12px;
    border: none;
    font-weight: 500;
    background-color: $brandedcolor5;
    color: $lightest_neutral;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 55px;
    height: 36.14px;
    border-radius: 2px;

    &:hover,
    &:focus {
        background-color: $brandedcolor2;
    }

    &:active,
    &.active {
        background-color: $brandedcolor4;
    }
        &:disabled,
        &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
    
            &:hover,
            &:focus {
                background-color: $brandedcolor5;
                ;
            }
        }
    @include darkMode {
        border: 1px solid $brandedcolor8;
        background: $brandedcolor8;
        color: $brandedcolor4;

        &:hover {
            background: $brandedcolor25;
            color: $brandedcolor23;
        }

        &:active,
        &.active {
            background: $brandedcolor24;
            border: 1px solid $brandedcolor23;
            color: $brandedcolor23;
        }
         &:disabled,
         &.disabled {
             opacity: 0.6;
             cursor: not-allowed;
    
             &:hover,
             &:focus {
                 background-color: $brandedcolor8;
             }
         }
         }
}

.an-dd {
    width: 100%;
    border-radius: 2px;
}

.an-dd-container {
    width: 100%;
    left: 0;
    top: 100%;
    box-sizing: border-box;
    border-width: 1px;
    border: 1px solid $brandedcolor1;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15),
        0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 2px;
    z-index: 0;

    @include darkMode {
        color: $brandedcolor3;
        background-color: $brandedcolor7;
    }
}

.an-dd-panel {
    width: 100%;
}

.an-dd-scroll-area {
    overflow-y: auto;
}

.an-dd-scroll-area-bookmark {
    overflow-y: auto;
    max-height: 145px;
}

.dd__scroll-area::-webkit-scrollbar,
.an-dd-scroll-area::-webkit-scrollbar {
    display: block;
    width: 8px;
}

.dd__scroll-area::-webkit-scrollbar:hover::-webkit-scrollbar-thumb,
.an-dd-scroll-area:hover::-webkit-scrollbar-thumb {
    background-color: $brandedcolor10;
    border-radius: 4px;

    @include darkMode {
        background-color: $brandedcolor26;
    }
}

.an-dd-category-trigger,
.an-dd-category-item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px;
    padding: 0.35rem 0.75rem;
    width: 100%;
    max-width: 100%;
    height: 36px;
    background-color: $lightest_neutral;

    &.ad-dd-category-item--selected {
        background-color: $brandedcolor30;
    }

    @include darkMode {
        color: $brandedcolor3;
        background-color: $brandedcolor7;
    }
}

.an-dd-category-trigger {
    background-image: url(/images/icon-chevron-down.svg);
    background-position: calc(100% - 10px) center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    border: 1px solid $brandedcolor5;
    background-color: $lightest_neutral;

    @include darkMode {
        border: 1px solid $brandedcolor8;
        background-image: url(/images/icon-chevron-down.svg);
        color: $lightest_neutral;
        background-color: $brandedcolor7;
        background-size: 20px 20px;
    }
}

.an-dd-category-item:hover {
    background-color: #efefef;
}

.an-dd-category-chip {
    width: 8px;
    height: 8px;
    border-radius: 55rem;
    margin-right: 8px;
    flex-shrink: 0;
}

.an-dd-category-label {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.an-dd-category-add {
    width: 100%;
    font-weight: 700;
    margin: 0.5rem 0rem 0.2rem 0rem;
    appearance: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    border: none;
    position: relative;
    border-top: 1px solid $midlight_neutral;
}

.an-dd-category-add-image {
    background-image: url(/images/pointer_default-right.svg);
    background-position: calc(100% - 10px) center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    margin: 4px 4px;
}

.an-dd-category-add-label {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 9.5px;
    left: 19px;
    color: $brandedcolor5;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
}

.an-color-picker-modal {
    padding: 0.8rem 0.7rem 0.5rem;
    z-index: 99;
    position: fixed;
    width: 265px;
    height: 290px;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $lightest_neutral;
    border: 1px solid $midlight_neutral;
    border-radius: 7px;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15),
        0 0 1px rgba(9, 30, 66, 0.31);

    @include darkMode {
        background-color: $brandedcolor7;
    }
}

.an-dd-category-save {
    appearance: none;
    border: none;
    color:green;
    background: transparent;
    width: 20px;
    height: 20px;
    background-image: url(/images/icons/checkmark.svg);
    background-position: center;
    background-size: 12px 12px;
    background-repeat: no-repeat;

    &:hover {
        background-image: url(/images/icons/checkmark.svg);
    }

    @include darkMode {
        background-image: url(/images/icons/checkmark.svg);

        &:hover {
            background-image: url(/images/icons/checkmark.svg);
        }
    }
}

.an-dd-category-edit {
    appearance: none;
    border: none;
    background: transparent;
    width: 20px;
    height: 20px;
    background-image: url(/images/edit_default.svg);
    background-position: center;
    background-size: 12px 12px;
    background-repeat: no-repeat;

    &:hover {
        background-image: url(/images/edit_hover.svg);
    }

    @include darkMode {
        background-image: url(/images/edit_default.svg);

        &:hover {
            background-image: url(/images/edit_hover.svg);
        }
    }
}

.an-dd-category-delete {
    appearance: none;
    border: none;
    background: transparent;
    width: 20px;
    height: 20px;
    background-image: url(/images/delete-default.svg);
    background-position: center;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    margin-left: 12px;

    &:hover {
        background-image: url(/images/delete-hover.svg);
    }

    @include darkMode {
        background-image: url(/images/delete-default.svg);

        &:hover {
            background-image: url(/images/delete-hover.svg);
        }
    }

    &.hide {
        display: none;
    }
}

.an-color-picker-header,
.an-color-picker-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.an-color-picker-header {
    margin-bottom: 1rem;
}

.an-color-picker-input {
    margin-right: 0.5rem;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    resize: none;
    font-family: "Roboto";
    padding: 8px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background-color: $lightest_neutral;
    border: 1px solid $brandedcolor1;
    border-radius: 2px;
    width: 219px;
    height: 25px;
}

.an-color-picker-closer {
    appearance: none;
    border: none;
    background-color: transparent;
    background: var(--icon-close) no-repeat center center;
    background-size: 14px 14px;
    display: block;
    height: 14px;
    transition: transform 0.2s ease;
    border: none;

    &:hover {
        transform: rotate(90deg);
        transition: transform 0.2s ease;
    }
}

.an-color-picker-content {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;

    .react-colorful {
        width: 176px;
        height: 172px;
    }
}

.an-color-picker-label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: $darkest_neutral;
}

.an-color-picker-label-name {
    margin-bottom: 0.3rem;
    color: $darkest_neutral;

    @include darkMode {
        color: $brandedcolor3;
    }
}

.an-color-picker-user-input {
    background: $lightest_neutral;
    border: 1px solid $brandedcolor1;
    border-radius: 2px;
    width: 75px;
    height: 25px;
    padding-left: 10px;
}

.an-color-picker-add {
    appearance: none;
    font-size: 12px;
    border: none;
    background-color: $brandedcolor5;
    color: $lightest_neutral;
    text-transform: uppercase;
    height: 36.14px;
    padding: 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-radius: 2px;
    margin-top: 0.8rem;

    &:hover,
    &:focus {
        background-color: $brandedcolor2;
    }

    &:active,
    &.active {
        background-color: $brandedcolor4;
    }
        &:disabled,
        &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
    
            &:hover,
            &:focus {
                background-color: $brandedcolor5;
            }
        }

    @include darkMode {
        border: 1px solid $brandedcolor8;
        background: $brandedcolor8;
        color: $brandedcolor4;

        &:hover {
            background: $brandedcolor25;
            color: $brandedcolor23;
        }

        &:active,
        &.active {
            background: $brandedcolor24;
            border: 1px solid $brandedcolor23;
            color: $brandedcolor23;
        }
         &:disabled,
         &.disabled {
             opacity: 0.6;
             cursor: not-allowed;
    
             &:hover,
             &:focus {
                 background-color: $brandedcolor8;
             }
         }
         }
}

.annotationdelete-modal {
    padding: 0 0 1em 0;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 7px;
    height: auto;
    min-height: 200px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 91;
    position: absolute;
    width: 708px;
    background-color: $lightest_neutral;
    position: fixed;
    border: 1px solid $brandedcolor1;

    @include darkMode {
        background-color: $brandedcolor7;
    }

    .annotationdelete-modal-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $brandedcolor1;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        padding: 0.35rem 0.5rem 0.35rem 1rem;
        box-sizing: border-box;
        height: 44px;
        background: $brandedcolor16;

        @include darkMode {
            background-color: $brandedcolor19;
            border-bottom: 1px solid $brandedcolor18;
        }

        .an-modal-title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            color: $darkest_neutral;

            @include darkMode {
                color: $lightest_neutral;
            }
        }
    }

    .annotationdelete-modal-close {
        background: var(--icon-close) no-repeat center center;
        background-size: 20px 20px;
        display: block;
        height: 20px;
        position: absolute;
        right: 1em;
        top: 0.75em;
        text-indent: -9999px;
        width: 20px;
        transition: transform 0.2s ease;
        border: none;

        &:hover {
            transform: rotate(90deg);
            transition: transform 0.2s ease;
        }
    }

    .annotationdelete-modal-body {
        padding: 20px 20px 20px 30px;
        color: $darkest_neutral;
        font-weight: 500;
        font-size: 14px;

        .annotationdelete-label {
            padding-top: 10px;
        }

        @include darkMode {
            color: $lightest_neutral;
        }
    }
}

.annotation-modal-delete {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.annotationdelete-modal-update {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
    color: $darkest_neutral;
    font-weight: 500;

    @include darkMode {
        color: $lightest_neutral;
    }
}

.an-modal-deletepopup {
    appearance: none;
    font-size: 12px;
    border: none;
    font-weight: 500;
    background-color: $brandedcolor5;
    color: $lightest_neutral;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 75px;
    height: 40px;
    border-radius: 2px;

    &:hover,
    &:focus {
        background-color: $brandedcolor2;
    }

    &:active,
    &.active {
        background-color: $brandedcolor4;
    }
        &:disabled,
        &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
    
            &:hover,
            &:focus {
                background-color: $brandedcolor5;
            }
        }

    @include darkMode {
        border: 1px solid $brandedcolor8;
        background: $brandedcolor8;
        color: $brandedcolor4;

        &:hover {
            background: $brandedcolor25;
            color: $brandedcolor23;
        }

        &:active,
        &.active {
            background: $brandedcolor24;
            border: 1px solid $brandedcolor23;
            color: $brandedcolor23;
        }
        &:disabled,
        &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
    
            &:hover,
            &:focus {
                background-color: $brandedcolor8;
            }
        }
        }
}

.full-screen-darken-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;
    background-color: $darkest_neutral;
    padding: 0;
    margin: 0;
    opacity: 0.3;
}

.ancatdelete-modal {
    padding: 0 0 1em 0;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 7px;
    height: auto;
    min-height: 200px;
    left: 52%;
    top: 55%;
    transform: translate(-50%, -50%);
    z-index: 99;
    position: fixed;
    width: 708px;
    background-color: $lightest_neutral;
    border: 1px solid $brandedcolor1;

    @include darkMode {
        background-color: $brandedcolor7;
    }

    .ancatdelete-modal-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $brandedcolor1;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        padding: 0.35rem 0.5rem 0.35rem 1rem;
        box-sizing: border-box;
        height: 44px;
        background: $brandedcolor16;

        @include darkMode {
            background-color: $brandedcolor19;
            border-bottom: 1px solid $brandedcolor18;
        }

        .ancatdelete-modal-title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            color: $darkest_neutral;

            @include darkMode {
                color: $lightest_neutral;
            }
        }
    }
}

.ancatdelete-modal-body {
    padding: 30px 30px 20px 30px;
    color: $darkest_neutral;

    @include darkMode {
        color: $lightest_neutral;
    }
}

.ancatdelete-modal-footer {
    display: flex;
    text-align: right;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px 30px 10px 20px;
    color: $darkest_neutral;
}

.ancatdelete-Update-text {
    margin-right: 25px;
    padding: 8px;
    color: $darkest_neutral;

    @include darkMode {
        color: $lightest_neutral;
    }
}


.annot-side-pannel-small {
    width: 22%;
    display: flex;
    position: -webkit-sticky;
    position:sticky;
    top: 5px;
    align-self: flex-start;
}

.nonprod-body-container .annot-side-pannel-small {
    top: 30px;
}

.annot-side-pannel-inner-container {
    border: 1px solid $brandedcolor1;
    border-radius: 7px;
    margin-bottom: auto;
    width: 92%;
    min-height: 152px;

    @include darkMode {
        border-color: $color13;
    }
}

.align-annot-left-panel-items {
    margin-left: 10px;
    margin-bottom: 4px;
    color: $darkest_neutral;

    @include darkMode {
        color: $lightest_neutral;
    }
}

.align-annot-left-panel-items-selected {
    margin-left: 10px;
    margin-bottom: 15px;
    padding-top: 6.5px;
    margin-top: -6px;

    @include darkMode {
        color: $darkest_neutral !important;
    }
}

.annot-delete {
    margin-left: auto;
    margin-right: 29px;
}
.book-delete {
    margin-left: auto;
    margin-right: 39.5px;
    margin-top: 20px;
    position: sticky;
   }

.icon-bin {
    margin-bottom: -15px;
    cursor: pointer;
    height: 27px;
    width: 23px;
    background-image: url(/images/icons/IconBinBL.svg);
    background-repeat: no-repeat;

    @include darkMode {
        background-image: url(/images/icons/IconBinWh.svg);
        background-repeat: no-repeat;
    }
}

.an-nav-item-text-container {
    border-radius: 5px;
    padding: 0.15em;
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid transparent;
    word-break: break-word;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}

.an-nav-item-text-container-selected {
    background: $brandedcolor30;
    border-radius: 7px;
    height: 32px;
    cursor: pointer;

    @include darkMode {
        color: $darkest_neutral;
    }
}


@media only screen and (max-width: 1024px) {
    .an-nav-item-text-container-selected {
        font-size: 12px;
    }

    .annot-side-pannel-inner-container {
        width: 92%;
    }

    .an-nav-item-text-container-unselected {
        font-size: 12px;
    }
}
@media only screen and (max-width: 1228.80px) {
    .an-nav-item-text-container-selected {
        font-size: 13px;
    }

    .annot-side-pannel-inner-container {
        width: 92%;
    }

    .an-nav-item-text-container-unselected {
        font-size: 13px;
    }
}
@media only screen and (max-width: 1396.36px) {
    .an-nav-item-text-container-selected {
        font-size: 15px;
    }

    .annot-side-pannel-inner-container {
        width: 92%;
        position: sticky;
    }

    .an-nav-item-text-container-unselected {
        font-size: 15px;
    }
}

.an-nav-item-text-container-unselected{
    color: white;
    cursor: pointer;
}

.annot-container {
    width: 74%;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    width: 65%;
}

.bookmark-container {
    width: 74%;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    width: 65%;
}
.annot-filters {
    margin-left: -10px;
    align-items: center;
}

.annot-filters-item {
    margin-left: 10px;
}

.annot-filters-item-cat-container-dashboard {
    max-width: 230px;
}

.annot-view-filter {
    align-items: center;
}

.annot-radio {
    padding-right: 10px;

    .annot-radio-input {
        margin-right: 5px;
    }
}

.annot-list {
    border: 2px solid #d9d9d9;
    border-radius: 3px;
    padding: 20px 20px;
    margin-top: 22px;
    overflow-y: scroll;
    max-height: 480px;

    /* width */
    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #d9d9d9;
    }
}
.book-list {
    margin-top: 40px;
    border: 2px solid #d9d9d9;
    border-radius: 3px;
    padding: 20px 20px;
    margin-top: 22px;
    overflow-y: auto;
    max-height: 480px;
}
.book-list::-webkit-scrollbar{
  width: 8px;
}

.book-list::-webkit-scrollbar-thumb{
  background-color: $brandedcolor10;
  border-radius: 4px;

  @include darkMode {
    background-color: $brandedcolor26;
  }
}



.annot-right-delete-panel {
    width: 45px;
    margin-top: 95px;
}
.annot-right-delete-panel-bookmark {
    width: 45px;
   // border: 2px solid $brandedcolor1;
    border-radius: 5px;
    margin-top: 62px;
}
.annot-searchbar {
    width: 22%;
    display: flex;
    position: relative;
    top: 5px;
    align-self: flex-start;
    z-index: 4;
    margin-left: 36px;
}

.annot-delete-hr {
    height: 0px;
    width: 100%;
    background-color: $brandedcolor1;
    margin-top: 50px;
}


.bookmark-item-container {
    border: 2px solid $brandedcolor1;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 94.2%;

    .bookmark-color {
        width: 3px;
        min-height: 96%;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .bookmark-item-inner-container {
        width: 97%;
        cursor: pointer;

        .bookmark-item-inner-container-adj {
            margin-left: 30px;

            .bookmark-text {
                font-size: medium;
                margin-bottom: 10px;
                margin-top: 10px;
                font-weight: bold;
            }

            .bookmark-category {
                margin-bottom: 10px;
                color: #777777;
                font-size: smaller;

                @include darkMode {
                    color: $lightest_neutral;
                }
            }
        }
    }
}

.annot-item-container {
    border: 2px solid $brandedcolor1;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 94.2%;

    .annot-color {
        width: 3px;
        min-height: 96%;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .annot-item-inner-container {
        width: 97%;
        cursor: pointer;

        .annot-item-inner-container-adj {
            margin-left: 30px;

            .an_annot-deleted {
                background-image: url(/images/alert-diamond.svg);
                background-repeat: no-repeat;
                height: 18px;
                width: 18px;
                margin-top: 10px;
                padding-right: 5px;
              }

            .annot-text {
                font-size: medium;
                margin-bottom: 10px;
                margin-top: 10px;
                font-weight: bold;
            }

            .annot-breadcrumb {
                margin-bottom: 10px;
                color: #777777;
                font-size: smaller;
            }

            .annot-comment-container {
                border: 2px solid $brandedcolor1;
                border-radius: 5px;
                margin-bottom: 10px;

                .annot-comment {
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    font-size: small;
                    overflow-wrap: anywhere;
                }
            }
        }
    }

}

.annot-delete-checkbox-container {
    padding-left: 15px;
    z-index: 2;

    .annot-delete-checkbox {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.annot-no-data {
    text-align: center;
    margin-top: 40px;
}

.annot-notification-label {
    padding-top: 3px;
}

.annot-clear-filters {
    margin-left: 16px;
    font-family: "Roboto";
    font-size: 12px;
    border: none;
    font-weight: 500;
    background-color: #ffffff;
    text-decoration: underline;
    color: #000000;
  
    @include darkMode {
      background-color: $brandedcolor7;
      color: $lightest_neutral;
    }
}

.annot-clear-filters-disabled {
    opacity: .36;
    cursor: not-allowed;
    @include darkMode {
       background-color: $brandedcolor7;
        color: $lightest_neutral;
        opacity: .36;
        cursor: not-allowed;
      }
}


.bookmark-section {
    display: flex;
    width: 70%;
}

.bookmark-list {
    margin-top: 50px;
    width: 90%;
}

.bookmark-delete-section {
    margin-left: 20px;
    margin-top: 50px;
    width: 5%;
    border: 1px solid #ccc;
}


.bookmark-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkmark-bookmark {
    margin-bottom: 0;
}

.bookmark-height-item {
    height: 36px;
}

.bookmark-item {
    border-left: 5px solid #f7e494;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 6px 10px;
}

.bookmark-checkbox-item {
    border-left: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    margin-bottom: 10px;
    padding: 6px 10px;
}

.bookmark-last-item {
    margin-bottom: 0px !important;
}

.bookmark-title {
    margin: 0;
    margin-bottom: 5px;
    font-weight: bold;
}

.bookmark-category {
    margin: 0;
    color: #777777;
    font-size: 12px;
    @include darkMode {
        color: $mid_neutral;
      }
}

.bookmark-category-dropdown {
    width: 150px;
}

.bookmarks-checkbox-container {
    vertical-align: middle;
    padding-right: 20px;
    box-sizing: border-box;
    width: 100%;
    padding: 5px 5px;
}

#option-0 {
    border-bottom: 1px solid #cccccc;
}
.search-modal {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    width: 70%;
    z-index: 10;
}
