

.dark-mode-row-container {
    padding: 8px;  
}

.toggle-container-dark-mode {
    margin-right: 0;
    border: 0px solid var(--main-border-color);
    padding: 8px 6px 8px 8px;
    border-radius: 2px;
    background-color: transparent;
    width: calc(100% - 2px);
}

.text-toggle-dark-mode {
    flex-grow: 1;
    text-align: left;
    font-weight: bold;
    color: $middarkest_neutral;
    @include darkMode {
        color: $light_neutral;
    }
}

.toggle-dark-mode-dropdown {
    background: url(/images/icon-toggle-on.svg) no-repeat center center;
    background-size: 26px 26px;
    width: 40px;
    border: none;
    height: 14px;
}

.toggle-dark-mode-dropdown-off {
    background-image: url(/images/icon-toggle-off.svg);

    @include darkMode {
        background-image: url(/images/icon-toggle-off-wh.svg);
    }
}

.toggle-container-dark-mode:hover .toggle-dark-mode-dropdown-off {
    background: url(/images/icon-toggle-off-bl.svg) no-repeat center center;
    background-size: 26px 26px;
}