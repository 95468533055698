/*------------------------------------*\
    HEADER ALPHA
\*------------------------------------*/
.header-homepage {
	position: absolute;
	width: calc(100% - 6em);
	padding: 2.25em 2em 2.25em 4em;
	.logo {
		h1 {
			margin: 0;
		}
		img {
			width: 200px;
		}
		a {
			display: block;
			border-bottom: none;
		}
	}
}
.home-alpha-header {
	float: right;
	display: flex;
	height: 43px;
	&>div:first-child {
		min-width: 81.5px;
	}
}