// react-select based dropdowns (ReactSelectDropdown.js)
.dropdown-container {
  border-radius: 4px;

  .collection-container {
    flex-grow: 1;
    font-size: 14px;
  }
}

.dropdown-container-member-firm-selector {
  min-width: 81.5px;
  margin-right: 10px;
}

.dropdown-container-uk-accounting-dropdown {
  width: auto;
}

html[data-theme="dark"] body .dropdown-btn {
  background-color: transparent;
}

.menu-list {
  background-color: var(--theme-bg);
}

.menu-list button {
  width: 100%;
  border: none;
  padding: 0;
}

.account-balance__menu,
.non-account-balance__menu {
  background-color: var(--theme-bg);
}

.account-balance__menu-list,
.non-account-balance__menu-list {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: $lightest_neutral;

    @include darkMode {
      background-color: $brandedcolor7;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: $brandedcolor10;

    @include darkMode {
      background-color: $brandedcolor26;
    }
  }
}

.page-settings {
  width: 170px;
  border: 1px solid $brandedcolor1;
  position: absolute;
  right: 0;
  top: 33px;
  border-radius: 5px;
  border-top-right-radius: 0;
  color: inherit;
  z-index: 1;
  background: #f5f5f5;
  background: var(--theme-bg);
  overflow: hidden;
  box-shadow: 0 4px 4px #b8b8b885;

  @include darkMode {
    background: $dark_mode_bg;
    border-color: $color13;
    box-shadow: 0 4px 4px #07070785;
  }

  .page-settings-text {
    padding: 18px 8px 0 8px;
    font-size: 11px;
    display: block;

    @include darkMode {
      color: $light_neutral;
    }

    &.hide {
      display: none;
    }
  }

  .user-settings-link-container {
    padding: 18px 9px;
    margin: 0 8px;
    border-bottom: 1px solid $brandedcolor1;

    @include darkMode {
      border-color: $color13;
    }
  }

  .user-settings-link {
    font-size: 13px;
    border: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.toggle-auto-translate-mode-on,
.toggle-dark-mode-page-settings-on {
  background: url(/images/icon-toggle-on.svg) no-repeat center center;
  background-size: 28px;
  width: 26px;
  border: none;
  margin-left: 2px;
}

.toggle-auto-translate-mode-off,
.toggle-dark-mode-page-settings-off {
  background: url(/images/icon-toggle-off-grey.svg) no-repeat center center;
  background-size: 23px;
  width: 26px;
  border: none;
  margin-left: 2px;
}

.text-toggle-auto-translate {
  font-size: 13px;
  margin-left: 6px;
  font-weight: 700;
}

.single-value-auto-translate {
  padding-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 172px;
}

.uk-selector__menu-list {
  margin: 0;
  min-height: 350px;

  .uk-selector__option {
    display: flex;
    font-weight: 400;
    border: none;
    border-bottom: 1px solid $brandedcolor1;
    margin: auto 0;
    border-radius: 0;
    width: 100%;
    padding: 20px 15px;
    background-color: white;

    &:hover {
      background-color: #2697a8;
      color: #f5f5f5;
    }
  }

  .clear-btn {
    display: block;
    padding: 12px;
    // font-size: 14px;
    // text-decoration: none;
    position: relative;
    bottom: -13em;
    right: -8em;
    //background-color: transparent;
    width: auto;
  }

}

.circle-uk-selector {
  box-sizing: border-box;
  border: 1px solid $brandedcolor1;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin: auto 7px auto 0;
  display: none;

  &.option-circle {
    margin-left: 7px;
  }
}

.circle-uk-selector-selected {
  background-color: $color1;
}

.uk-selector__option:hover {
  .circle-uk-selector {
    border-color: $middark_cool;
  }

  .circle-uk-selector-selected {
    background-color: $middark_cool;
  }
}

.uk-selector__control {
  &:hover {
    .circle-uk-selector {
      border-color: $middark_cool;
    }

    .circle-uk-selector-selected {
      background-color: $middark_cool;
    }
  }

  .circle-uk-selector.open {
    background-color: inherit;
    border-color: $lightest_neutral;
  }

  .circle-uk-selector.open.selected {
    background-color: $lightest_neutral;
    border-color: $lightest_neutral;
  }
}

.dropdown-wrapper {
  display: grid;
  grid-template-columns: 230px 1fr;
}

.dd .dropdown__submenu {
  display: block;
  flex-flow: column wrap;
  font-size: 13px;
  width: auto;
  min-height: 350px;
  overflow-y: auto;

  &.show {
    min-width: 200px;
  }

  &.hide {
    min-width: 0px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $brandedcolor10;
    border-radius: 4px;

    @include darkMode {
      background: $brandedcolor26;
    }
  }

  @include darkMode {
    background-color: $brandedcolor7;
  }

  h4 {
    font-size: 13px;
  }

  .submenu-col {
    padding: 20px 20px;
    border-left: 1px solid $brandedcolor1;

    &:first-of-type {
      border-left: none;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: column wrap;
      height: 95%;
      max-height: 95%;
      flex: 1 1 50%;

      @media (max-width: 1070px) {
        flex-flow: column;
      }

      li {
        list-style: none;
        display: flex;
        align-items: flex-start;

        input {
          height: 12px;
          width: 12px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 1px solid #b8b8b885;
          transition-duration: 0.3s;
          cursor: pointer;
          position: relative;
          flex-shrink: 0;
          margin-right: 6px;

        }
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          // font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked {
              ~ {
                .checkmark-header {
                  background-color: #0076a8;

                  &:after {
                    display: block;
                  }
                }
              }
            }
          }

          &:hover {
            input {
              ~ {
                .checkmark-header {
                  background-color: #0076a8;
                }
              }
            }
          }

          .checkmark-header {
            &:after {
              left: 3.5px;
              bottom: 3px;
              width:3.5px;
              height: 8px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }

        .checkmark-header {
          position: absolute;
          top: 0;
          left: 0;
          height: 12px;
          width: 12px;
          // background-color: #eee;
          border: 2px solid #747474;
          border-radius: 2px;

          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }

      }
    }
  }

  &.industry {
    min-width: 300px;
    max-width: 400px;
    max-height: 250px;

    @media (max-width: 1070px) {
      min-width: 200px;
      max-width: 300px;
    }

    @include darkMode {
      background-color: $brandedcolor7;
    }

    ul {
      @media (max-width: 1070px) {
        width: 100%;
      }
    }
  }
}



//Dropdown Preferences Styles
.uk-selector__menu {
  border: 1px solid;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-color: #d9d9d9;
  box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  min-width: 230px;
  min-height: 350px;
  width: auto;
  overflow: hidden;
  position: absolute;
  left: 10px;
  top: 33px;
  z-index: 7;

  @include darkMode {
    border-color: #757575;
  }
}

//Folio Dropdown
.dd__collection-trigger {
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  flex-grow: 1;
  align-items: center;
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 37px;
  transition: border-color 0.2s ease 0s;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  width: 100%;
  background-size: 20px 20px;
  background-image: url(/images/dropdown-arrow-gray.svg);
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  outline: 0px !important;

  @include darkMode {
    background-image: url(/images/dropdown-arrow-default-darkmode.svg);
    border: 1px solid #757575;
  }

  &:hover {
    color: 'inherit'
  }
}
.catalog-box .dd__collection-trigger {
  @include darkMode {
    background-image: url(/images/dropdown-arrow-blue-default-darkmode.svg);
  }
}


.collection-dropdown-input {
  width: 100%;
  box-sizing: content-box;
  background: 0px center;
  border: 0px;
  opacity: 1;
  outline: 0px;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: 14px;
  cursor: pointer;
  font-family: $primary_font_stack;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 35px;
  text-indent: 15px;

  &::placeholder {
    color: rgb(163, 163, 163);
    max-width: calc(100% - 35px);
  }

  @include darkMode {
    &:focus-visible {
      outline: none;
    }
  }
}

.collection-menu {
  top: 100%;
  width: 100%;
  background-color: white;
  border-radius: 4px 4px 2px 2px;
  box-shadow: none;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  margin: auto;
  margin-top: 0px;
  border: 1px solid #d9d9d9;
  z-index: 3;

  @include darkMode {
    border: 1px solid #757575;
    background-color: #212121;
  }

  &.article-version-menu{
    top:42px !important;
    padding:3px 0px !important;
    border-radius: 0px 0px 4px 4px;
  }
}

.collection-menu-list {
    max-height: 225px;
    overflow-y: auto;
    position: relative;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 8px;
        background: #ffffff;

        @include darkMode {
            background: #212121;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
        border-radius: 4px;

        @include darkMode {
            background: rgba(255, 255, 255, 0.54);
        }
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;

        @include darkMode {
            background: #212121;
        }
    }

    .collection-menu-option-focus-style {
        background: #f5f5f5 !important;
        color: #000000 !important;

        @include darkMode {
            background: rgba(255, 255, 255, 0.87) !important;
            color: rgba(0, 0, 0, 0.87) !important;
        }
    }

    .collection-menu-option {
        font-size: 14px;
        padding: 8px 16px !important;
        color: inherit;
        transition: all 0.2s ease;
        cursor: pointer !important;
        background: white;

        &.no-options {
            cursor: not-allowed;
            color: rgb(163, 163, 163);
            text-align: center;
        }

        &.article-version-option {
            margin: 3px 0px !important;
        }

        &.option-active {
            background-color: $lighter_neutral;
            color: $darkest_neutral;

            @include darkMode {
                background-color: $brandedcolor12;
            }
        }

        @include darkMode {
            background: #212121;
        }

        &:hover {
            background: #f5f5f5;
            color: #000000;

            @include darkMode {
                background: rgba(255, 255, 255, 0.87);
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }
}

.input-dropdown {
  width: 100%;
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

//Article Compare Version Dropdown
.language-selector-dd-container{
  width: 100%;
}
.language-selector-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  &:hover {
    background-color: #f5f5f5;

    @include darkMode {
      background-color: #000000;
    }
  }

  &.option-active {
    background-color: #e9e9e9;

    @include darkMode {
      background-color: #000000;
    }
  }
  .language-selector-dd-category-label{
    width: 100%;
      font-size: 13px;
      font-weight: 400;
      padding: 8px 16px;
      transition: background-color .2s;
      cursor: pointer;
  }
}

.language-selector-homepage{
    float:right;
}
