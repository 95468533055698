
.collection-item-container {

    border: 1px solid $brandedcolor1;
    border-radius: 7px;
    color: $darkest_neutral;
    font-size: 15px;
    text-decoration: none;
    transition: all 0.2s ease;
    margin-bottom: 11.25px;
    padding: 12px;
	@include darkMode {
		border: 1px solid $color13;
	}

    p {
        @include darkMode {
            color: $light_neutral;
        }
    }
}

.collection-item {
    border: none;
    margin-top: auto;
    margin-bottom: auto;
}

.collection-item-title {
    border: none !important;
     padding-left: 15px;
     padding-right: 15px;
}

.collection-item-expandable-title {
	padding: 7px 15px 0 10px;
	&:hover {
		text-decoration: underline;
		color: $brandedcolor2;
		@include darkMode {
			color: $brandedcolor8;
		}
	}
	&:active {
		text-decoration: underline;
		color: $brandedcolor4;
		@include darkMode {
			color: $brandedcolor9;
		}
	}
}

.collection-toc-icon-container {
    margin: 10px 0 18px 0 ;

}

.collection-toc-icon-main {
    width: 5%;
    float: left;
    margin-top: -2px;
}

.collection-toc-icon-item-name {
  width: 70%;
  padding-left:24px;
}

.collection-toc-icon-searchbadge {
     float: right;
     margin:-20px 0 20px 0 ;
}

.collection-item-expandable-section {
    margin: 2em 0 0 4em;
}

.collection-item-expandable-text-body {
    margin: 2em 4em 2em 0;
    font-size: 14px;
}

.collection-toc-sidebar-toc-icon {
    margin-top: -5px;
}

.icon-type-small {
    width: 30px;

    @include darkMode {
        filter: invert(1);
    }
}

.view-publication {
    position: relative;
    left: 0;
}

.arrow-right {
    background: url(/images/CaretRight.svg) no-repeat center center;
    background-size: 30px 30px;
    margin: 0 11px 0 0;
    width: 20px;
    cursor: pointer;
	@include darkMode {
		filter: invert(100%);
	}
}

.side-pannel-heading {
    border-bottom: 1px solid $brandedcolor1;
    display: block;
    margin-bottom: 0.75em;
    margin-bottom: 0px;
    padding: 8px 16px;
    font-weight: 500;

    @include darkMode {
        border-color: $color13;
    }
}

.side-pannel-heading-article-fixed-nav {
    border-bottom: none;
    border-right: 1px solid $brandedcolor1;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    box-sizing: border-box;

    @include darkMode {
        border-color: $color13;
    }
}

.side-pannel-small {
    width: 27%;
    display: flex;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 50px;
    // height: 485px;
    align-self: flex-start; /* <-- this is the fix */
}

.side-pannel-small-reading-mode {
    height:0 ;
}

.side-pannel-inner-container {
    border: 1px solid $brandedcolor1;
    border-radius: 7px;
    margin-bottom: auto;
    width: 100%;
    min-height: 481px;

    @include darkMode {
        border-color: $color13;
    }
}

.folio-sidepanel {
position: relative;
}

.side-pannel-body {
    margin-left: 0px;
    margin-right: 8px;
    padding-top: 8px;
    padding-bottom: 16px;
    margin-right: 0px;
    padding-top: 20px;
    box-sizing: border-box;
    overflow-y: auto;
}


.side-pannel-body::-webkit-scrollbar, .in-article-toc-reading-mode::-webkit-scrollbar {
	width: 8px;
}
.side-pannel-body:hover::-webkit-scrollbar-thumb, .in-article-toc-reading-mode:hover::-webkit-scrollbar-thumb {
	background-color: $brandedcolor10;
	border-radius: 4px;
	@include darkMode {
        background-color: $brandedcolor26;
    }
}

.toc-side-pannel-list {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 46px;
}

.toc-side-pannel-list-main-panel {
    margin-left: 0;

    li {
        padding-left: 0px !important;

        li {
            padding-left: 13px !important;
        }
    }
}
.side-pannel-collection-list {
    padding-left: 40px;
	li {
		margin: 0 0 .5em 0;
	}
}

.toc-side-pannel-all-on-off-toggle {
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 10px;
    color: #000000;
    text-decoration: underline;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
}

.toc-side-pannel-all-on-off-toggle:hover {
    background-color: rgba(0, 0, 0, 0.06);
    color: #012169;
    cursor: pointer;
}

.toc-side-panel-all-on-off-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: -4px;
    padding-bottom: 6px;
}

.collection-search-view-all {
    background: url(/images/pointer-default.svg) no-repeat calc(100% - 9px) center;
    background-size: 22px 22px;
  text-decoration : none !important;
    font-size: 12px;
    font-weight: 500;
    color: $brandedcolor5;
    text-transform: capitalize;
    padding: 10px 30px 10px 25px;
    bottom: 5px;
    cursor: pointer;
    @include darkMode {
        background: url(/images/pointer-darkmode-default.svg) no-repeat calc(100% - 9px) center;
        color: $lightest_neutral;
        border: 1px solid transparent;
        padding: 10px 30px 10px 22px;
        background-size: 22px 22px;
    }
    &:hover {
        background: url(/images/pointer-hover.svg) no-repeat calc(100% - 9px) center;
        background-size: 22px 22px;
        color: $brandedcolor2;
        @include darkMode {
            background: url(/images/blue-1_pointer.svg) no-repeat calc(100% - 11px) center;
            border: 1px solid $brandedcolor23;
            background-color: $brandedcolor25;
            border-radius: 2px;
            color: $brandedcolor23;
        }
    }
    &:active {
        background: url(/images/pointer-pressed.svg) no-repeat calc(100% - 9px) center;
        background-size: 22px 22px;
        color: $brandedcolor4;
        @include darkMode {
            background: url(/images/blue-1_pointer.svg) no-repeat calc(100% - 11px) center;
            background-color: $brandedcolor24;
            border-radius: 2px;
            border: 1px solid $brandedcolor23;
            color: $brandedcolor23;
        }
    }
}

.collection-search-view-all-disabled {
    background: url(/images/pointer-default.svg) no-repeat calc(100% - 9px) center;
    background-size: 22px 22px;
    font-size: 12px;
    font-weight: 500;
    color: $brandedcolor5;
    text-transform: capitalize;
    padding: 10px 30px 10px 25px;
    bottom: 5px;
    margin-top: -10px;
    cursor: not-allowed;
    @include darkMode {
        background: url(/images/pointer-darkmode-default.svg) no-repeat calc(100% - 9px) center;
        color: $lightest_neutral;
        border: 1px solid transparent;
        padding: 10px 30px 10px 22px;
    }
    &:hover {
        background: url(/images/pointer-hover.svg) no-repeat calc(100% - 9px) center;
        background-size: 22px 22px;
        color: $brandedcolor2;
        @include darkMode {
            background: url(/images/blue-1_pointer.svg) no-repeat calc(100% - 11px) center;
            border: 1px solid $brandedcolor23;
            background-color: $brandedcolor25;
            border-radius: 2px;
            color: $brandedcolor23;
        }
    }
    &:active {
        background: url(/images/pointer-pressed.svg) no-repeat calc(100% - 9px) center;
        background-size: 22px 22px;
        color: $brandedcolor4;
        @include darkMode {
            background: url(/images/blue-1_pointer.svg) no-repeat calc(100% - 11px) center;
            background-color: $brandedcolor24;
            border-radius: 2px;
            border: 1px solid $brandedcolor23;
            color: $brandedcolor23;
        }
    }
}

.collection-search-view-all-ra{
    text-decoration: none !important;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 16px;
    @include darkMode {
        color: #00A3E0 !important;
    }
}

.collection-searchbar-publication-title {
  //  background: url(/images/pointer-default.svg) no-repeat calc(100% - 9px) center;
    background-size: 22px 22px;
    font-size: 11px;
    font-weight: 500;
    //color: $brandedcolor5;
    text-transform: uppercase;
   // padding: 10px 30px 10px 10px;
    bottom: 5px;
    cursor: pointer;

    @include darkMode {
      //  background: url(/images/pointer-darkmode-default.svg) no-repeat calc(100% - 9px) center;
        color: $lightest_neutral;
        border: 1px solid transparent;
    }

    &:hover {
       // background: url(/images/pointer-hover.svg) no-repeat calc(100% - 9px) center;
        background-size: 22px 22px;
        color: $brandedcolor2;

        @include darkMode {
          //  background: url(/images/blue-1_pointer.svg) no-repeat calc(100% - 11px) center;
            border: 1px solid $brandedcolor23;
            background-color: $brandedcolor25;
            border-radius: 2px;
            color: $brandedcolor23;
        }
    }

    &:active {
        background: url(/images/pointer-pressed.svg) no-repeat calc(100% - 9px) center;
        background-size: 22px 22px;
        color: $brandedcolor4;

        @include darkMode {
            background: url(/images/blue-1_pointer.svg) no-repeat calc(100% - 11px) center;
            background-color: $brandedcolor24;
            border-radius: 2px;
            border: 1px solid $brandedcolor23;
            color: $brandedcolor23;
        }
    }
}

.collection-search-result-panel {
    box-shadow: 5px 5px 12px 0px #ccc;
    margin-right: -18.9%;
    max-height: 300px;
    background-color: white;
    width: 350px;
    overflow: auto;
    @include darkMode {
        background-color: #212121;
    }
}

.collection-search-result-list {
  padding: 5px 0 8px 7px;
}

.collection-search-result-section {
    width: 95% ;
     font-weight: bold;
      white-space: nowrap;
       text-overflow: ellipsis ;
        overflow: hidden ;
         font-size: 11px ;
}
.catalog-search-result-panel {
    box-shadow: 5px 5px 12px 0px #ccc;
    margin-right: -18.9%;
    max-height: 300px;
    background-color: white;
    width: 350px;
    display: block;
    overflow: auto;

    @include darkMode {
        background-color: #212121;
        padding-top:8px;
    }
}

.catalog-search-result-panel-search-active {
    box-shadow: 5px 5px 12px 0px #ccc;
    margin-right: -18.9%;
    max-height: 300px;
    background-color: white;
    width: 350px;
    display: block;
    overflow-y: auto;


    @include darkMode {
        background-color: #212121;
        padding-top:8px;
    }
}

.catalog-search-result-panel-warapperShimmer {
  margin-top: 5px;

}

.catalog-search-result-panel-titleShimmer {
  height: 20px;
  width: 95%;
  margin: 0 7px 8px 0;

  }

  .catalog-search-result-panel-article-content-containerShimmer {
    padding: 0px 0 0 20px ;

  }

  .catalog-search-result-panel-shimmerLine {
     height:15%;
     width: 98%;
  }

  .catalog-search-result-panel-shimmerEnd {
    height: 15px;
  }

.catalog-search-no-results {
    padding-top: 8px;
    padding-left: 18px;
    padding-bottom: 8px;
}

  .catalog-search-result-container {
     padding: 4px 0px 4px 0;
     width : 88%;
  }

  .catalog-search-result-container-title {
    padding-left: 7px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    width: 50%;
 }

 .catalog-search-result-container-title-link {
    float: right;
    margin-left: 15px;
    font-size: 11px;
    margin-left: auto;
 }

 .catalog-search-result-container-title-link-disabled {
    float: right;
    margin-left: 15px;
    font-size: 11px;
 }

.catalog-search-result-panel::-webkit-scrollbar{
  width: 14px;
  height: 6px;
}

.catalog-search-result-panel::-webkit-scrollbar-thumb {
  background-color: $brandedcolor10;
  border-radius: 4px;
  scroll-padding-right: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;

  @include darkMode {
    background-color: $brandedcolor26;
  }
}
.collections-container {
    width: 100%;
}

.collection-searchbar-fixed {
    position: fixed;
    right: 26px;
    top: 5px;
    z-index: 10;
}

.collection-searchbar {
    margin-top: -50px;
    position: absolute;
    right: 0px;
    z-index: 4;
}

.catalog-search-bar {
    width: 288.5px;
    margin-top: -32px;
    margin-right: -18.9% ;
 }


.searchbar-publication-collection {
    padding: 0;
	margin: 0;
    width: 288.5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 45px;
}

.viewAll-results {
    font-size: 15px ;
}
