.date-picker {
  width: 350px;
  border-radius: 5px;

  .date-picker-single-date {
    padding: 15px 18px 0px 15px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .date-picker-range-date {
    padding: 8px 15px 18px 15px;
    display: flex;

    .date-picker-start-date {
      display: flex;
      align-items: center;
    }

    .date-picker-end-date {
      display: flex;
      align-items: center;
      margin-top: 8px;
    }

    p {
      margin: 0;
    }
  }
}

.calendarIcon {
    width: 15px;
    cursor: pointer;
}

.date-picker-calendar {
  width: 100%;

  @include darkMode {
    color: #f0f0f0;
    background-color: #212121;
  }
}

.range-date-picker-calendar {
  margin-left: 14px;
}
.calendar-margin {
  margin-left: 8px;
}

.radio-date-selector {
  font-size: 13px;
  margin-left: 10px;
}

.checkmarks-container-row-date-selector {
  margin-bottom: 0px !important;
}

.checkmarks-container-row-date-selector-range {
  margin-bottom: 0px !important;
  margin-top: 4px !important;
}

.react-daterange-picker__calendar{
  width: 350px;
  max-width: 100vw;
  z-index: 7 !important;;
  margin-left: 106px;
  margin-top: -68px;


}

.checkmark-date-selector {
  position: absolute; //re
  top: 3px;
  left: 9px;
  height: 14px;
  width: 14px;
  background-color: $lightest_neutral;
  border-radius: 50%;
  border: 1px solid $midlight_neutral;
  display: none;
}

.checkmarks-container-row:hover input ~ .checkmark-date-selector {
  border-color: #0076a7;
}

.checkmarks-container-row input:checked ~ .checkmark-date-selector {
  border-color: #0076a7;
}

.checkmark-date-selector:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmarks-container-row input:checked ~ .checkmark-date-selector:after {
  display: block;
}

.checkmarks-container-row .checkmark-date-selector:after {
  top: 1px;
  left: 1px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #0076a7;
}

.react-calendar {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-left: 140px;
    margin-top: -48px;
    position: fixed;
    right: 10%;
    top: 35%;
}

.react-date-picker__wrapper {
  border-radius: 5px;
  border: 2px solid #dbdbdb !important;
 
  @include darkMode {
    color: #f0f0f0;
    background-color: #212121;
  }
}

.react-daterange-picker__wrapper {
  border-radius: 5px;
  border: 2px solid #dbdbdb !important;
  margin-left: -5px;
}

.range-calender-padding{
  padding-left: 26px;
}

.react-date-picker__inputGroup {
  font-size: 12px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-radius: 5px !important;

  @include darkMode {
    color: #f0f0f0;
    background-color: #212121;
  }
}

.react-daterange-picker__clear-button{
  display: none;
}

.react-daterange-picker__inputGroup {
  font-size: 12px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-radius: 5px !important;
  height: 55% !important;

  @include darkMode {
    color: #f0f0f0 ;
    background-color: #212121;
  }

}
 
.dd__container {
  z-index: 7 !important;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
  width: 100%;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;

  @include darkMode {
    color: #f0f0f0;
    background-color: #212121;
  }
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
  @include darkMode {
    color: #f0f0f0;
    background-color: #212121;
  }
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;

  @include darkMode {
    color: #f0f0f0;
    background-color: #212121;
  }

}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  z-index: 7 !important;
}
.react-date-picker__clear-button {
  display: none !important;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

/* Date range picker */

.react-date-picker {
  display: inline-flex;
  position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  @include darkMode {
    color: #f0f0f0 ;
    background-color: #212121;
  }
}
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  @include darkMode {
    color: #f0f0f0 ;
    background-color: #212121;
  }
}
.an-dd-dashboard-filter-active {
  color: #000000 !important;
  @include darkMode {
    color: $brandedcolor3 !important;
  }
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;

  @include darkMode {
    color: #f0f0f0;
    background-color: #212121;
  }
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;

  @include darkMode {
    color: #f0f0f0;
    background-color: #212121;
  }
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;

  @include darkMode {
    color: #f0f0f0;
    background-color: #212121;
  }
}
.react-daterange-picker__inputGroup__day,.react-daterange-picker__inputGroup__month,
.react-daterange-picker__inputGroup__year {
  @include darkMode {
    color: #f0f0f0 !important;
    background-color: #212121 !important;
  }
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  z-index: 3 !important;;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

/* Calendar */

.react-calendar {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-left: 140px;
    margin-top: -48px;
    position: fixed;
    right: 30%;
    top: 35%;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 66px;
  margin-bottom: 0.4em;
  background: #000000;
  color: white;
  border-radius: 7px 7px 0 0;
  margin-left: -1px;
  margin-top: -1px;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  background-color: #000;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile--hover {
  background: #c2e9f8 !important;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  color: black;
  flex: none !important;
  width: 46px !important;

  &:hover {
    background-color: #c2e9f8 !important;
  }
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background: #0077ad !important;
  color: white !important;
  border-radius: 100px !important;
  flex: none !important;
  width: 41px !important;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
  background: #0077ad !important;
  color: white !important;
  border-radius: 20px 0 0 20px !important;
  flex: none !important;
  width: 46px !important;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
  background: #0077ad !important;
  color: white !important;
  border-radius: 0 20px 20px 0 !important;
  flex: none !important;
  width: 46px !important;
}

.react-calendar__tile--active {
  background: #c2e9f8 !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #0077ad;
  color: white !important;
  flex: none !important;
  width: 46px;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation > button:nth-child(3) {
  text-align: initial;
}

.react-calendar__navigation > button {
  &:hover {
    background: #2c2828 !important;
  }
}

.react-calendar__navigation__label__labelText--from {
  color: white !important;
  font-size: 1.2em;
  order: 1;
  margin-left: 10px;
}

.react-calendar__navigation__next-button {
  color: white !important;
  font-size: 1.5em;
  order: 4;
}

.react-calendar__navigation__next2-button {
  color: white !important;
  order: 5;
  display: none !important;
}

.react-calendar__navigation__prev-button {
  color: white !important;
  font-size: 1.5em;
  order: 3;
}

.react-calendar__navigation__prev2-button {
  color: white !important;
  order: 2;
  display: none !important;
}

.react-calendar__viewContainer {
  padding: 10px;
}

abbr {
  text-decoration: none !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #cecece !important;
}