.annotation-panel-wrapper {
    padding: 0 0 0em 0;
    width: 23%;
    background: var(--theme-bg);
    transition: height 0.01s ease;
    min-height: 240px;
    height: calc(100vh - 90px);
    display: flex;
    position: fixed;
    top: 88px;
    flex-direction: column;
    z-index: 5;
    border-top: 1px solid $brandedcolor1;
    border-left: 1px solid $brandedcolor1;
    border-right: 1px solid $brandedcolor1;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-color: $lightest_neutral;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 7px;
    right: 0%;

    @include darkMode {
        background-color: $brandedcolor7;
        border-top: 1px solid $brandedcolor18;
        border-left: 1px solid $brandedcolor18;
        border-right: 1px solid $brandedcolor18;
    }


    .annotation-panel {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
    }


    .annotation-panel-header {
        border-bottom: 1px solid $brandedcolor1;
        background-color: $brandedcolor16;
        box-sizing: border-box;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
        padding: 0.5em 0.5em 0.5em 1em;

        @include darkMode {
            background-color: $brandedcolor19;
            border-bottom: 1px solid $brandedcolor18;
        }

        .an_annot-warning {
            background-image: url(/images/warning_badge.svg);
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            margin-top: 4px;
            padding-right: 5px;
        }

        .an_annot-panel-warning {
            background-image: url(/images/warning_badge.svg);
            background-repeat: no-repeat;
            height: 15px;
            width: 15px;
            margin-top: 4px;
            padding-right: 5px;
        }

        .an_annot-deleted {
            background-image: url(/images/alert-diamond.svg);
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            margin-top: 4px;
            padding-right: 5px;
        }

        p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 1.17em;
            margin: 0;
            overflow-wrap: break-word;
        }

        .annotation-panel-annotate {
            background: transparent url(/images/annotation-dashboard.svg) no-repeat center center;
            position: absolute;
            border: none;
            right: 3.5em;
            top: 0.4em;
            background-size: 24px 24px;
            height: 30px;
            width: 30px;
            display: block;

            @include darkMode {
                background: transparent url(/images/annotation-dashboard-darkmode.svg) no-repeat center center;
            }
        }


    }

    .annotation-panel-heading-body {
        padding: 0.5em 0.5em 0.5em 0.5em;
        border-bottom: 1px solid #D0D0CE;

        p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px !important;
            margin: 0;
            color: $brandedcolor17;

            @include darkMode {
                color: #D0D0CE;
            }
        }

        @include darkMode {
            background-color: $brandedcolor19;
            border-bottom: 1px solid $color13;
            color: #D0D0CE;
        }
    }

    .annotation-panel-dropdown-categories {
        margin-top: 15px;
        padding: 0.5em 1em 0.5em 1em;
    }

    .annotation-panel-body {
        overflow-y: auto;
        width: 89%;
        display: block;
        border-top: 1px solid $brandedcolor1;
        height: calc(100vh - 393px);
        float: left;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0.5em 1em 0.5em 1em;

        @include darkMode {
            background-color: $brandedcolor19;
        }

        @media(max-height: 550px) {
            height: 20vh;
        }
    }

    .height {
        height: calc(100vh - 360px);
    }
}

.annotation-panel-fixed {
  width: 23%;
  background: var(--theme-bg);
  height: calc(100% - 53px);
  min-height: 240px;
  position: fixed;
  right: 0;
  transition: height 0.01s ease;
  top: 51px;
}


.annotation-panel-body::-webkit-scrollbar,
.an-dd-panel-dropdown::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

.annotation-panel-body:hover::-webkit-scrollbar-thumb,
.an-dd-panel-dropdown::-webkit-scrollbar-thumb {
  background-color: $brandedcolor10;
  border-radius: 4px;

  @include darkMode {
    background-color: $brandedcolor26;
  }
}

.annotation-panel-footer {
  padding: 0 4px 0 4px;
  background: $lightest_neutral;
  border-top: 1px solid $brandedcolor1;
  border-bottom: 1px solid $brandedcolor1;
  box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: space-between;
  align-items: center;
  height: 11%;
  position: absolute;
  width: 100%;
  bottom: 0;

  @include darkMode {
    background-color: $darkest_neutral;
    border-top: 1px solid $brandedcolor18;
    border-bottom: 1px solid $brandedcolor18;
    color: $lightest_neutral;
  }
}

.annotation-article-count {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: $darkest_neutral;
  margin-top: 5px;
  display: block;
  text-align: center;
  padding-left: 2;

  @include darkMode {
    color: $lightest_neutral;
  }

}

.annotation-my-dashboard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center; 
  transition: all 0.2s ease;
  float: right;
  text-align: center;
  margin-top: 5px;
  background: $lightest_neutral;
  border: 1px solid $brandedcolor5;
  border-radius: 2px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 11.5px;
  line-height: 16px;
  text-transform: uppercase;
  color: $brandedcolor5;

  @include darkMode {
    border: 1px solid $brandedcolor8;
    background: $brandedcolor8;
    color: $brandedcolor4;

    &:hover {
      background: $brandedcolor25;
      color: $brandedcolor23;
    }

    &:active,
    &.active {
      background: $brandedcolor24;
      border: 1px solid $brandedcolor23;
      color: $brandedcolor23;
    }
  }

}

.an-dd-scroll-area-sidepanel-edit {
  overflow-y: auto;
}

.dd__scroll-area::-webkit-scrollbar,
.an-dd-scroll-area-sidepanel-edit::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.dd__scroll-area::-webkit-scrollbar:hover::-webkit-scrollbar-thumb,
.an-dd-scroll-area-sidepanel-edit:hover::-webkit-scrollbar-thumb {
  background-color: $brandedcolor10;
  border-radius: 4px;

  @include darkMode {
    background-color: $brandedcolor26;
  }
}

.annotation-panel-close {
  background: var(--icon-close) no-repeat center center;
  background-size: 20px 20px;
  display: block;
  height: 20px;
  position: absolute;
  right: 1em;
  top: 0.75em;
  text-indent: -9999px;
  width: 20px;
  transition: transform 0.2s ease;
  border: none;

  &:hover {
    transform: rotate(90deg);
    transition: transform 0.2s ease;
  }
}

.an {
    box-sizing: border-box;
    width: 100%;
    background-color: $lightest_neutral;
    border: 1px solid $brandedcolor1;
    border-radius: 2px;
    margin: 16px 0 0 0;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    opacity: 0.5;
    max-height: 148px;
    min-height: 78px;
    position: relative;
    padding: 0 4px 0 0;

    @include darkMode {
        background-color: $brandedcolor19;
        border: 1px solid $brandedcolor18;
        color: $lightest_neutral;
    }


    .an_header {
        display: flex;
        align-items: center;
        padding: 8px 2px 4px 0;
    }

    .an_annot-warning {
        background-image: url(/images/warning_badge.svg);
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        padding-right: 5px;
    }

    .an_annot-deleted {
        background-image: url(/images/alert-diamond.svg);
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        padding-right: 5px;
    }

    .an__title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        margin-right: 8px;
        transition: color .3s;
        font-weight: 700;
        overflow-wrap: anywhere;
    }


    .an__actions {
        display: flex;
        right: calc(2% + 17px);
        margin-left: auto;
        flex-direction: row;

        .an-dd-category-edit {
            background-image: url(/images/edit_default.svg);
            background-repeat: no-repeat;
            border: none;
            cursor: pointer;

            &:hover {
                background-image: url(/images/edit_hover.svg);
            }

            @include darkMode {
                background-image: url(/images/edit_default.svg);

                &:hover {
                    background-image: url(/images/edit_hover.svg);
                }
            }
        }

        .an-dd-category-delete {
            appearance: none;
            border: none;
            background: transparent;
            background-image: url(/images/delete-default.svg);
            background-position: center;
            background-size: 12px 12px;
            background-repeat: no-repeat;
            margin-left: 0%;

            &:hover {
                background-image: url(/images/delete-hover.svg);
            }

            @include darkMode {
                background-image: url(/images/delete-default.svg);

                &:hover {
                    background-image: url(/images/delete-hover.svg);
                }
            }

            &.hide {
                display: none;
            }
        }
    }

    .an__content {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: $darkest_neutral;

        @include darkMode {
            background-color: $brandedcolor19;
            color: $lightest_neutral;
        }
    }

    .an_content-comment {
        margin: 10px 4px 10px 0;
        word-break: break-all;
    }

    .an_content-only {
        height: 20%;
    }

    .an__comment {
        background: $lightest_neutral;
        border: 2px solid $brandedcolor1;
        border-radius: 2px;
        height: 38%;
        margin: 10px 4px 10px 4px;

        @include darkMode {
            background-color: $brandedcolor19;
            border: 1px solid $brandedcolor18;
        }
    }
}

.an__comment-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: $darkest_neutral;
  margin: 10px 4px 10px 8px;
  word-wrap: break-word;

  @include darkMode {
    color: $lightest_neutral;
  }
}

.an-comment {
    min-height: 20%;
    padding-bottom: 10px;
}

.an-selected {
  border: 2px solid $brandedcolor1;
  opacity: 1;
  box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  max-height: unset;
}

.an-hovered {
  opacity: 1
}

.category-color {
  margin-left: 2px;
  height: 100%;
  float: left;
  position: absolute;
  top: 0;
  height: -webkit-fill-available;
}

.annotation-category {
  margin-left: 11px;
}

.update-annotation-text{
  background: transparent url(/images/annotation-dashboard.svg) no-repeat center center;
}


.annotation-panel-close {
  background: var(--icon-close) no-repeat center center;
  background-size: 20px 20px;
  display: block;
  height: 20px;
  position: absolute;
  right: 1em;
  top: 0.75em;
  text-indent: -9999px;
  width: 20px;
  transition: transform 0.2s ease;
  border: none;

  &:hover {
    transform: rotate(90deg);
    transition: transform 0.2s ease;
  }
}

.annotation-panel-annotate {
  background: transparent url(/images/annotation-dashboard.svg) no-repeat center center;
  position: absolute;
  border: none;
  right: 3.6em;
  top: 0.75em;
  background-size: 24px 24px;
  height: 30px;
  width: 30px;
}

.ann-sidemenu-radio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin: 10px 20px 10px 2px;
}

.ann-sidemenu-radio-container-dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  margin-top: -8px;
  gap: 1.25vw;
}

.an-dd-dropdown {
  width: 100%;
  border-radius: 2px;
}

.an-dd-cat-dropdown {
  width: auto;
  border-radius: 2px;
  font-size: 14px;
}

.an-dd-date-dropdown {
  width: auto;
  border-radius: 2px;
  margin-left: 10px;
  // top:5px;
}

.an-dd-cat-dropdown-dashboard {
  width: 210px;
  border-radius: 2px;
  margin-left:14px;
}

.an-dd-container-dropdown {
  width: 100%;
  left: 0;
  top: 0%;
  box-sizing: border-box;
  border-width: 1px;
  border: 1px solid $brandedcolor1;
  box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15),
    0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 2px;
  z-index: 2;
  margin-top: 32px;


  @include darkMode {
    color: $brandedcolor3;
    background-color: $brandedcolor7;
  }
}

.an-dd-container-cat-dropdown {
  min-width: 170px;
  z-index: 2 !important;
}

.an-dd-container-date-dropdown {
  width: 352px;
  left: 0;
  top: 0%;
  box-sizing: border-box;
  border-width: 1px;
  border: 1px solid $brandedcolor1;
  box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15),
    0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 2px;
  z-index: 2 !important;
  margin-top: 32px;

  @include darkMode {
    color: $brandedcolor3;
    background-color: $brandedcolor7;
  }
}

.an-dd-panel-dropdown {
  width: 100%;
  overflow-y: scroll;

}

.an-dd-panel-dropdown-resize {
  width: 170px;
}

.an-dd-scroll-area-dropdown {
  border-bottom: 1px solid $midlight_neutral;

}

.an-dd-category-trigger-dropdown,
.an-dd-category-item-dropdown {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px;
  padding: 0.35rem 0.75rem;
  width: 100%;
  max-width: 100%;
  height: 36px;
  background-color: $lightest_neutral;

  &.ad-dd-category-item-dropdown--selected {
    background-color: $brandedcolor30;
  }

  @include darkMode {
    color: $brandedcolor3;
    background-color: $brandedcolor7;
  }
}

.an-dd-category-trigger-dropdown {
  background-image: url(/images/chevron-down-bk.svg);
  background-position: calc(100% - 10px) center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  border: 1px solid $brandedcolor27;
  background-color: $lightest_neutral;

  @include darkMode {
    border: 1px solid $brandedcolor8;
    background-image: url(/images/chevron-down-bl.svg);
    color: $lightest_neutral;
    background-color: $brandedcolor7;
    background-size: 20px 20px;
  }
}

.an-dd-category-item-dropdown:hover {
  background-color: #efefef;
}

.an-dd-category-chip-dropdown {
  width: 8px;
  height: 8px;
  border-radius: 55rem;
  margin-left: 6px;
  flex-shrink: 0;
}

.an-categ-container {
width:100%;
}

.an-dd-category-label-dropdown {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: -2px;
  margin-top: 1px;
  align-items: center;
}

.an-dd-label-dropdown {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: 6px;
  margin-top: 1px;
}

.all {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.side-panel-edit {
  padding: 0.8rem 0.7rem 0.5rem;
  z-index: 1;
  position: absolute;
  width: 85%;
  height: 85%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $lightest_neutral;
  border: 1px solid $midlight_neutral;
  border-radius: 7px;
  box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15),
    0 0 1px rgba(9, 30, 66, 0.31);

  @include darkMode {
    background-color: $brandedcolor7;
  }
}


.an-dd-category-edit {
  appearance: none;
  border: none;
  background: transparent;
  width: 20px;
  height: 20px;
  background-image: url(/images/edit_default.svg);
  background-position: center;
  background-size: 12px 12px;
  background-repeat: no-repeat;

  &:hover {
    background-image: url(/images/edit_hover.svg);
  }

  @include darkMode {
    background-image: url(/images/edit_default.svg);

    &:hover {
      background-image: url(/images/edit_hover.svg);
    }
  }
}

.an-color-picker-header-sidepanel,
.an-color-picker-footer-sidepanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.an-color-picker-header-sidepanel {
  margin-bottom: 0.5rem;
}

.an-color-picker-input-sidepanel {
  margin-right: 0.5rem;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  resize: none;
  font-family: "Roboto";
  padding: 8px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  background-color: $lightest_neutral;
  border: 1px solid $brandedcolor1;
  border-radius: 2px;
  width: 219px;
  height: 25px;
}

.an-color-picker-closer-sidepanel {
  appearance: none;
  border: none;
  background-color: transparent;
  background: var(--icon-close) no-repeat center center;
  background-size: 14px 14px;
  display: block;
  height: 14px;
  transition: transform 0.2s ease;
  border: none;

  &:hover {
    transform: rotate(90deg);
    transition: transform 0.2s ease;
  }
}

.an-color-picker-content-sidepanel {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;

  .react-colorful {
    width: 120px;
    height: 90px;
  }
}

.an-color-picker-label-sidepanel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: $darkest_neutral;
}

.an-color-picker-label-name-sidepanel {
  margin-bottom: 0.3rem;
  color: $darkest_neutral;

  @include darkMode {
    color: $brandedcolor3;
  }
}

.an-color-picker-user-input-sidepanel {
  background: $lightest_neutral;
  border: 1px solid $brandedcolor1;
  border-radius: 2px;
  width: 75px;
  height: 25px;
  padding-left: 10px;
}

.an-color-picker-add {
  appearance: none;
  font-size: 12px;
  border: none;
  background-color: $brandedcolor5;
  color: $lightest_neutral;
  text-transform: uppercase;
  height: 36.14px;
  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
  margin-top: 0.8rem;

  &:hover,
  &:focus {
    background-color: $brandedcolor2;
  }

  &:active,
  &.active {
    background-color: $brandedcolor4;
  }
  &:disabled,
  &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
  &:hover,
  &:focus {
    background-color: $brandedcolor5;
  }
  }

  @include darkMode {
    border: 1px solid $brandedcolor8;
    background: $brandedcolor8;
    color: $brandedcolor4;

    &:hover {
      background: $brandedcolor25;
      color: $brandedcolor23;
    }

    &:active,
    &.active {
      background: $brandedcolor24;
      border: 1px solid $brandedcolor23;
      color: $brandedcolor23;
    }
        &:disabled,
        &.disabled {
          opacity: 0.6;
          cursor: not-allowed;
    
          &:hover,
          &:focus {
            background-color: $brandedcolor8;
          }
        }
  }
}

.checkmark-dropdown-panel {
  position: absolute;
  transform: scale(0.75);
  border: none;
  position: absolute;
  height: 148%;
  width: 148%;
  transform: scale(0.75);
  background: $brandedcolor5;
  border: none;
  border-radius: 4px;
  margin-left: -4px;
  margin-top: -4px;
}


.category-dropdown-close {
  background-image: url(/images/chevron-blue-up.svg);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  border: 1px solid $brandedcolor5;
  background-color: $lightest_neutral;
  border: none;
  float: right;
  width: 20px;
  height: 20px;
  margin-right: 6px;
 
  &:hover {
    color: $brandedcolor5;
  }

  @include darkMode {
    border: 1px solid $brandedcolor8;
    background-image: url(/images/chevron-blue-up.svg);
    color: $lightest_neutral;
    background-color: $brandedcolor7;
    background-size: 20px 20px;
  }
}

.checkmarks-container-row {
  margin-bottom: 10px;
  font-size: 13px;
  padding: 0 0 0 10px;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.radio-categories {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding-left: 4px;
}

.radio-categories-dashboard {
  font-family: 'Roboto';
  font-style: normal;
  line-height: 12px;
  padding-left: 4px;
}

.radio-categories-dashboard-checked{
  font-weight: 500;
}

.filter-by-label {
  font-weight: 500;
}

.checkmarks-container-row input {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: initial;
  display: inline-block;
  background:url("/images/radio-btn.svg") 0 0 no-repeat;

}

.checkmarks-container-row input:checked {
  background:url("/images/selected-radio-btn.svg") 0 0 no-repeat;
}


.box-container {
  box-sizing: border-box;
  width: 100%;
  background-color: $lightest_neutral;
  border: 1px solid $brandedcolor1;
  border-radius: 2px;
  margin: 0 4px 10px 14px;
  box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  z-index: 2;
  position: absolute;
  top: 0;
  left: -14px;

  @include darkMode {
    background-color: $darkest_neutral;
    border: 1px solid $brandedcolor18;
  }
}

.an-modal-content-sidepanel-edit {
  margin: 10px 10px 10px 10px;

  @include darkMode {
    background-color: $darkest_neutral;
  }
}

.an-dd-category-trigger-sidepanel-edit {

  background-image: url(/images/chevron-down-bk.svg);
  background-position: calc(100% - 10px) center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  border: 1px solid #D0D0CE;
  border-radius: 2px;
  background-color: $lightest_neutral;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px;
  padding: 0.35rem 0.75rem;
  width: 100%;
  height: 25px;

  @include darkMode {
    background-image: url(/images/chevron-down-bl.svg);
    background-color: $brandedcolor19;
    border: 1px solid $brandedcolor18;
    color: $lightest_neutral;
  }
}

.an-dd-category-label-sidepanel-edit {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-right: 8px;
  transition: color .3s;
  font-weight: 700;
}

.an-modal-comment-sidepanel-edit {
  background: $lightest_neutral;
  border: 2px solid $brandedcolor1;
  border-radius: 2px;
  height: 38%;
  box-sizing: border-box;
  width: 100%;
  height: 78px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @include darkMode {
    background-color: $brandedcolor19;
    border: 2px solid $brandedcolor18;
    color: $lightest_neutral;
  }
}

.an-modal-comment-input-sidepanel-edit {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: $darkest_neutral;
  appearance: none;
  resize: none;
  padding: 10px 10px 0;
  border: none;
  width: 100%;

  @include darkMode {
    background-color: $brandedcolor19;
    outline: none;
    color: $lightest_neutral;

    .an-modal-comment-input-sidepanel-edit:focus-visible {
      outline: none;
    }
  }

}

.an-modal-comment-input-sidepanel-edit:focus-visible {
  outline: none;
}

.an-modal-comment-count-sidepanel-edit {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  margin-top: 10px;
}

.an-modal-date-count-sidepanel-edit {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 9px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: $middark_neutral;

  @include darkMode {
    color: $middark_neutral;
  }

}

.an-modal-footer-sidepanel-edit {
  text-align: right;
}

.an-modal-save-sidepanel-edit {
  appearance: none;
  font-size: 12px;
  border: none;
  font-weight: 500;
  background-color: $brandedcolor5;
  color: $lightest_neutral;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 55px;
  height: 36.14px;
  border-radius: 2px;

  &:hover,
  &:focus {
    background-color: $brandedcolor2;
  }

  &:active,
  &.active {
    background-color: $brandedcolor4;
  }
    &:disabled,
    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
  
      &:hover,
      &:focus {
        background-color: $brandedcolor5;
      }
  }

  @include darkMode {
    border: 1px solid $brandedcolor8;
    background: $brandedcolor8;
    color: $brandedcolor4;

    &:hover {
      background: $brandedcolor25;
      color: $brandedcolor23;
    }

    &:active,
    &.active {
      background: $brandedcolor24;
      border: 1px solid $brandedcolor23;
      color: $brandedcolor23;
    }
        &:disabled,
        &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
        &:hover,
        &:focus {
          background-color: $brandedcolor8;
        }
        }
  }
}

.an-modal-cancel-sidepanel-edit {
  appearance: none;
  font-size: 10px;
  border: none;
  font-weight: 500;
  line-height: 12px;
  background-color: $lightest_neutral;
  align-items: center;
  width: 55px;
  height: 36.14px;
  text-decoration: underline;
  color: $middark_neutral;

  @include darkMode {
    background-color: $darkest_neutral;
    color: $mid_neutral;
  }
}

.an-dd-container-sidepanel-edit {
  top: 0;
  width: 100%;
  background: $lightest_neutral;
  border: 1px solid $brandedcolor1;
  border-radius: 2px;

  @include darkMode {
    background-color: $brandedcolor7;
    border: 1px solid $brandedcolor18;
    color: $lightest_neutral;
    z-index: 55555;
  }
}


.category-dropdown-sidepanel-edit-close {
  background-image: url(/images/chevron-blue-up.svg);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  border: 1px solid $brandedcolor5;
  border: none;
  float: right;
  width: 20px;
  height: 20px;
  background-color: transparent;
  position: absolute;
  left: 86%;

  &:hover {
    color: $brandedcolor5;
  }

  &.show {
    display: none;
  }

  @include darkMode {
    background-image: url(/images/chevron-blue-up.svg);
    color: $lightest_neutral;
    background-color: $brandedcolor7;
    background-size: 20px 20px;
  }

}

.an-dd-cat-selection {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 92%;
  margin-right: 22px;
}

.div-wrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.an-dd-cat-single-selection {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 91%;
}

.an-dd-dashboard-category-label-dropdown{
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: -2px;
  margin-top: 1px;
  align-items: center;
  margin-right: 20px;
}

.an-dd-category-trigger-list {
  width: 100%;
}

.an-dd-dashboard-filter-active{
  color: $brandedcolor8;
}

.an-dd-category-trigger-dropdown {
  box-sizing: border-box;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px;
  padding: 0.35rem 0.75rem;
  width: 100%;
  min-width: 160px;
  max-width: 100%;
  height: 32px;
  white-space: nowrap;

  &.ad-dd-category-item--selected {
    background-color: $brandedcolor30;
  }

  @include darkMode {
    color: $brandedcolor3;
    background-color: $brandedcolor7;
  }
}

.an-dd-category-trigger-dropdown-dashboard {
  width: 230px;
}

.an-dd-dashboard-filter-active-all-cat {
  padding-right: 30px;
}

.an-dd-category-trigger-dropdown-cat {
  min-width: 210px;
}

.an-dd-category-trigger-dropdown-date {
  min-width: 85px;
}

.an-dd-category-item-sidepanel-edit {
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px;
  padding: 0.35rem 0.75rem;
  width: 100%;
  max-width: 100%;
  height: 32px;
  white-space: nowrap;
  &.ad-dd-category-item--selected {
    background-color: $brandedcolor30;
  }
  @include darkMode {
    color: $brandedcolor3;
    background-color: $brandedcolor7;
  }
}

.an-dd-category-item-sidepanel-edit-highlighted {
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px;
  padding: 0.35rem 0.7rem;
  width: 100%;
  max-width: 100%;
  height: 32px;

  &.ad-dd-category-item--selected {
    background-color: $brandedcolor30;
  }

  @include darkMode {
    color: $brandedcolor3;
    background-color: $brandedcolor7;
  }

}


.an-dd-category-label-sidepanel-edit {
  width: 60%;
  display: flex;
  flex-direction: row;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $darkest_neutral;

  @include darkMode {
    color: $lightest_neutral;
  }
}

.an-dd-category-add-sidepanel-edit {
  width: 100%;
  font-weight: 700;
  margin: 0.2rem 0rem 0.2rem 0rem;
  appearance: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  border: none;
  position: relative;
  border-top: 1px solid #D0D0CE;
}

.an-dd-category-add-image-sidepanel-edit {
  background-image: url(/images/pointer_default-right.svg);
  background-position: calc(100% - 7px) center;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  margin: 2px 2px;
}

.an-dd-category-add-label-sidepanel-edit {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 8.5px;
  left: 19px;
  color: $brandedcolor5;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

}

.an-dd-category-edit-sidepanel-edit {
  appearance: none;
  border: none;
  background: transparent;
  width: 20px;
  height: 20px;
  background-image: url(/images/edit_default.svg);
  background-position: center;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  position: absolute;
  left: 62%;

  &:hover {
    background-image: url(/images/edit_hover.svg);
  }

  @include darkMode {
    background-image: url(/images/edit_default.svg);

    &:hover {
      background-image: url(/images/edit_hover.svg);
    }
  }
}

.an-dd-category-delete-sidepanel-edit {
  appearance: none;
  border: none;
  background: transparent;
  width: 20px;
  height: 20px;
  background-image: url(/images/delete-default.svg);
  background-position: center;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  position: absolute;
  left: 72%;
  top: 6px;

  &:hover {
    background-image: url(/images/delete-hover.svg);
  }

  @include darkMode {
    background-image: url(/images/delete-default.svg);

    &:hover {
      background-image: url(/images/delete-hover.svg);
    }
  }
}
.bookmark-svg {
  background-size: 38px 38px;
  margin-bottom: 13.6px;
  border: none;
  height: 38px;
  width: 38px;
  display: block;
  cursor: pointer;
}

.slide-in {
  animation-duration: .3s;
  animation-name: slidein;
}

.full-screen-overlay-sidepanel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 2;
  padding: 0;
  margin: 0;
  opacity: 0.7;
}

@keyframes slidein {
  from {
    margin-right: -300px;
  }

  to {
    margin-right: 0px;
  }
}
