/*------------------------------------*\
    NOTIFICATIONS
\*------------------------------------*/
.wormhole-redirect-message {
	display: block;
	color: $darkest_neutral;
	background-color: $color14;
	padding: 0 18px;
	margin: 0 0 20px 0;
	height: 40px;
	width: 92%;
	p {
		display: inline-block;
		font-size: 11px;
	}
	.warning {
		background: url("/images/warning_badge.svg") no-repeat center center;
		background-size: 18px 18px;
		display: inline-block;
		width: 18px;
		margin: 0 10px 0 0;
		position: relative;
		bottom: -1px;
	}
	.close-warning {
		background: url("/images/icon-close-bk.svg") no-repeat center center;
		background-size: 24px 24px;
		border: none;
		display: inline-block;
		width: 40px;
		height: 40px;
		float: right;
		position: relative;
		right: -13px;
	}

	.catalog-warmhole-notification {
		margin:7px 35px 0 0 ;
	}
}

.annotation-article-update-message {
	width: 100%;
	display: block;
	color: $darkest_neutral;
	background-color: $color14;
	padding: 0 18px;
	margin: 0 0 20px 0;
	//height: 40px;
	p {
		display: inline-block;
		font-size: 12px;
	}
	.warning {
		background: url("/images/warning_badge.svg") no-repeat center center;
		background-size: 18px 18px;
		display: inline-block;
		width: 18px;
		margin: 0 10px 0 0;
		position: relative;
		bottom: -1px;
	}
	.close-warning {
		background: url("/images/icon-close-bk.svg") no-repeat center center;
		background-size: 24px 24px;
		border: none;
		display: inline-block;
		width: 40px;
		height: 40px;
		float: right;
		position: relative;
		right: -13px;
	}
}

.delete-notification {
	display: flex;
	justify-content: space-between;
	padding: 0;

	span {
		padding-left: 18px;
	}

	.close-warning {
		padding-right: 18px;
		float: none;
		position: inherit;
		right: 0;
	}
}

.notification {
	width: 100%;
	display: flex;
	color: $darkest_neutral;
	background-color: $color14;
	padding: 0 18px;
	margin: 10px 0;
	min-height: 40px;

}

.an-notification {
	display: flex;
	color: $darkest_neutral;
	background-color: $color14;
	padding: 0 18px;
	margin: 10px 0;
	min-height: 40px;
	align-items: center;
	.notification-label {
		@include darkMode {
			color: #000000;
		}
	}

}

.notification-label {
	display: inline-block;
	font-size: 12px;
}

.notification-link {
	color: #0076a8;
	text-decoration: underline;
	text-underline-offset: 2px;
	text-decoration-color: #0076a8;
}

.notification-warning {
	background: url("/images/warning_badge.svg") no-repeat center center;
	background-size: 18px 18px;
	display: inline-block;
	min-width: 18px;
	margin: 0 10px 0 0;
	position: relative;
	bottom: -1px;
}
.content-error-message {
	display: block;
	color: #000000;
	background-color: #fbf0ef;
	padding: 0 18px;
	margin: 0px 0px 16px 0;
	height: 40px;
	width: 92%;

	p {
		display: inline-block;
		font-size: 12px;
	}

	.alert {
		background: url("/images/alert-diamond.svg") no-repeat center center;
		background-size: 18px 18px;
		display: inline-block;
		width: 18px;
		margin: 0 9px 0 0;
		position: relative;
		bottom: -1px;
	}

	.close-warning {
		background: url("/images/icon-close-bk.svg") no-repeat center center;
		background-size: 24px 24px;
		border: none;
		display: inline-block;
		width: 40px;
		height: 40px;
		float: right;
		position: relative;
		right: -13px;
	}
}

.collection-alert-message {
	display: flex;
	color: #000000;
	background-color: $color14;
	padding: 0 18px;
	align-items: center;
	width: fit-content;

	p {
		display: inline-block;
		font-size: 12px;

		@include darkMode {
			color: black;
		}
	}

	.alert {
		background: url("/images/warning_badge.svg") no-repeat center center;
		background-size: 18px 18px;
		display: inline-block;
		width: 18px;
		margin: 0 9px 0 0;
		position: relative;
		bottom: -1px;
	}

	.close-warning {
		background: url("/images/icon-close-bk.svg") no-repeat center center;
		background-size: 24px 24px;
		border: none;
		display: inline-block;
		width: 40px;
		height: 40px;
		float: right;
		position: relative;
		right: -13px;
	}
}

.message-notification-error {
	background-color: $color15;

	.alert {
		background: url("/images/alert-diamond.svg") no-repeat center center;
	}
}

.message-notification-warning {
	background-color: $color14;

	.alert {
		background: url("/images/warning_badge.svg") no-repeat center center;
	}
}

.message-notification-info {
	background-color: $color13;

	.alert {
		background: url("/images/warning_badge.svg") no-repeat center center;
	}
}

	.message-notification-error, .message-notification-warning, .message-notification-info {
		display: block;
		color: #000000;
		padding: 0 18px;
		margin: 0px 0px 16px 0;
		height: 40px;
		width: 92%;

		p {
			display: inline-block;
			font-size: 12px;
		}

		.alert {
			background-size: 18px 18px;
			display: inline-block;
			width: 18px;
			margin: 0 9px 0 0;
			position: relative;
			bottom: -1px;
		}

		.close {
			background: url("/images/icon-close-bk.svg") no-repeat center center;
			background-size: 24px 24px;
			border: none;
			display: inline-block;
			width: 40px;
			height: 40px;
			float: right;
			position: relative;
			right: -13px;
		}
	}