// in-house dropdown (Dropdown.js)
.dd {
    display: inline-block;
    overflow: visible;
    position: relative;
}

.dd__trigger {
    position: relative;
}

.dd__trigger-button {
    appearance: none;
    display: flex;
    background-color: white;
    border: 1px solid var(--main-border-color);
    border-radius: 4px;
    height: 28px;
    padding: 4px 30px 4px 8px;
    margin: 0;
    align-items: center;
    background-image: url(/images/dropdown-arrow-gray.svg);
    background-position: calc(100% - 10px) center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    width: 122px;
    margin: 5px 10px 0 10px;
    @include darkMode {
        background-color: transparent;
		background-image: url(/images/dropdown-arrow-default-darkmode.svg);
    }
}

.dd__trigger-label {
    display: inline-block;
    padding: 4px 4px;
    font-size: 12px;
    &.version-label{
        font-size: 14px;
        padding:4px 12px;
    }
}

.dd__trigger--active .dd__trigger-button {
    background-image: url(/images/dropdown-arrow-gray.svg);
    background-color: $lightest_neutral;
    border-color: $brandedcolor1;
    color: $darkest_neutral;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include darkMode {
        background-color: $brandedcolor7;
		background-image: url(/images/dropdown-arrow-default-darkmode.svg);
		color: $lightest_neutral;
		border-color: $color13;
    }
}
#category-dropdown .dd__trigger button {
    @include darkMode {
		color: #e8e8e8;
        background-color: #212121;
        background-image: url(/images/dropdown-arrow-default-darkmode.svg);
    }
}
.header-container #category-dropdown .dd__trigger button {
    @include darkMode {
        background-image: url(/images/dropdown-arrow-blue-default-darkmode.svg);
    }
}

.dd__container {
    position: absolute;
    //left: 10px;
    top: 33px;
    background-color: var(--theme-bg);
    z-index: 4000;
	border: 1px solid $brandedcolor1;
    box-shadow: 0 4px 4px #b8b8b885;
    &.uk-selector__menu {
        z-index: 11 !important;
    }
	@include darkMode {
		border-color: $color13;
        box-shadow: 0 4px 4px rgba(7, 7, 7, 0.52);
	}
}

.dd__scroll-area {
    overflow: none;

    &::-webkit-scrollbar {
        display: none;
        width: 8px;
		border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
        background-color: $lightest_neutral;
		@include darkMode {
			background-color: $brandedcolor7;
		}
    }
    &::-webkit-scrollbar-thumb {
        background-color: $brandedcolor10;
		border-radius: 4px;
		@include darkMode {
			background-color: $brandedcolor26;
		}
    }
    &::-webkit-resizer {
        display: none;
    }
}

/**
MEMBER FIRM DOPDOWN
**/

.mfdd__container {
    left: 10px;
    background-color: transparent;
    .btn {
		max-height: 52px;
	}
}

.mfdd__panel {
    // border: 1px solid $brandedcolor1;
    // border-bottom-left-radius: 7px;
    // border-bottom-right-radius: 7px;
    overflow: hidden;
    background-color: var(--theme-bg);
	@include darkMode {
		border: 1px solid $color13;
	}
}

.mfdd__panel-overflowY {
    overflow-y: scroll;
}

.mfdd__card {
    width: 275px;
    font-size: 14px;
}
.mfdd__card:last-of-type {
    border-bottom: none;
}

.mfdd__card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    padding: 14px 10px;
    //margin-left: 5px;

    span {
        margin-left: 7px;
    }

    div {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
        &.highlighted {
            background-color: #f0f9fd;

            @include darkMode {
                background-color: #d9f1f7;
                color: #000000;
            }
        }
    }
.mfdd__card-closer {
    appearance: none;
    border: none;
    background: transparent;
    width: 20px;
    height: 20px;
    background-image: url(/images/icon-close-bk.svg);
    background-position: center;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
    @include darkMode {
        background-image: url(/images/icon-close-wh.svg);
    }
}

.mfdd__card:first-of-type .mfdd__card-closer {
    display: none;
}

.mfdd__option-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    column-gap: 16px;
    row-gap: 16px;
}

.mfdd__option {
    appearance: none;
    background-color: transparent;
    border: 1px solid $brandedcolor1;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    transition: border-color 200ms linear;
    cursor: pointer;
	@include darkMode {
		border: 1px solid $color13;
	}

    &.active,
    &:hover {
        background-color: #2697a8;
        border-color: $brandedcolor1;
        color: white;
        transition: border-color 200ms linear;
    }
    &.active {
        cursor: default;
    }
}

.mfdd__add-button {
    appearance: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color:$amf_default_bg;
    color: $brandedcolor5;
    border: none;
    border-top: 1px solid var(--main-border-color);
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    min-height: 20px;
    margin: 0;
    padding: 8px 16px 8px 35px;
    text-transform: uppercase;
    background-image: url(/images/add-default.svg);
    background-position: 12px 7px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    transition: background-color 0.2s;

    &:hover {
        color:$brandedcolor2;
        background-color: $brandedcolor3;
        background-image: url(/images/add-hover.svg);
        transition: background-color 0.2s;
    }

    &.active{
        color: $brandedcolor4;
        background-color: $amf_active_bg;
        background-image: url(/images/add-active.svg);
        transition: background-color 0.2s;
    }

    &.disabled {
        pointer-events: none;
        cursor: default;
        color: #ccc;

        @include darkMode {
            color: #444;
        }
    }

    @include darkMode {
        background-color: #00000012;
        color:$amf_dark_default;
        background-image: url(/images/add-default-dark.svg);

        &:hover{
            background-color: #00000024;
            color: $amf_dark_hover;
            background-image: url(/images/add-hover-dark.svg);
            transition: background-color 0.2s;
        }

        &.active{
            background-color: $amf_active_bg;
            color: $amf_dark_active;
            background-image: url(/images/add-active-dark.svg);
            transition: background-color 0.2s;
        }
    }
}

.mfdd__list-container {
    position: absolute;
    top: calc(100% - 35px);
    left: 100%;
    width: 250px;
    background-color: var(--theme-bg);
    border: 1.5px solid $brandedcolor1;
    box-shadow: 0 4px 4px #b8b8b885;
    overflow-y: scroll;

    @include darkMode {
        box-shadow: 0 4px 4px rgba(7, 7, 7, 0.52);
    }
}

.mfdd__list-item {
    font-size: 14px;
    font-weight: 700;
    padding: 8px 16px;
    transition: background-color 200ms;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
        @include darkMode {
            background-color: #444;
        }
    }

    &:last-of-type {
        border: none;
    }

    &.disabled {
        pointer-events: none;
        cursor: default;
        color: #ccc;

        @include darkMode {
            color: #444;
        }
    }
}

.mfdd__list-item.no-options {
    cursor: not-allowed;
    color: #a3a3a3;
    text-align: center;
}

.article-version-trigger{
  background-color: white;
  width: 100%;
  margin: 8px 0 0 0;
}

/**
HOME PAGE MEMBER FIRM SELECTOR
**/

.home-mf {
    width: 100%;
}

.home-mf-collapsible {
    width: 275px !important;
    z-index: 11 !important;
}

#category-dropdown > div > button {
    height: 35px;
    display: flex;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 5px 30px 5px 13px;
    justify-content: space-between;
    align-items: center;
    background-image: url(/images/dropdown-arrow-gray.svg);
    background-position: calc(100% - 8px) 50%;
    background-size: 25px 25px;
    background-repeat: no-repeat;
    width: 100%;
    margin: 0 10px 0 0;
}

#category-dropdown > div.dd__container.home-mf__container > div > div.dd__scroll-area {
    overflow-y: auto;
}

.home-mf__trigger {
    width: 100%;
}

.home-mf__trigger-button {
    display: flex;
    background-color: white;
    border: 1px solid $brandedcolor1;
    border-radius: 4px;
    padding: 5px 30px 5px 13px;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    background-image: url(/images/dropdown-arrow-gray.svg);
    background-position: calc(100% - 8px) center;
    background-size: 25px 25px;
    background-repeat: no-repeat;
    width: 100%;
    margin-right: 10px;
}

.home-mf__trigger-label {
    display: inline-block;
    padding: 4px 4px;
    font-size: 12px;
    font-weight: 400;
}

.dd__trigger--active .home-mf__trigger-button {
    color: $darkest_neutral;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.home-mf__container {
    width: calc(100% - 2px);
	&.dd__container {
		left: 0;
	}
}

.home-mf__item {
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    padding: 8px 16px;
    transition: background-color 200ms;
    cursor: pointer;
}

.home-mf__item-container:first-of-type .home-mfdd__card-closer{
    display: none;
}

.home-mf__item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        background-color: #f5f5f5;
    }

    &:hover
    .home-mfdd__card-closer{
        opacity: 1;
    }

    &.active,
    &.active:hover {
        pointer-events: none;
        cursor: default;
        color: $darkest_neutral;
		background-color: #f5f5f5;
    }
}

.home-mf__list-label {
    font-size: 12px;
    padding: 4px 16px;
    font-weight: 700;
}

.home-mfdd__card-closer {
    appearance: none;
    border: none;
    background: transparent;
    padding-right: 15px;
    width: 40px;
    height: 20px;
    background-image: url(/images/remove.svg);
    background-position:  center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    opacity: 0;

    @include darkMode {
        background-image: url(/images/remove-dark.svg);
    }

    &.active {
        display: none;
    }
}

.member-firm-option {
    padding: 10px 40px;
    cursor: pointer;
    color: $darkest_neutral;

    &:hover {
        background: #f5f5f5;
        font-weight:bold;
    }

    @include darkMode {
        background-color: $brandedcolor19;
       // border: 1px solid $brandedcolor18;
        color: white;
        &:hover {
            // color: black;
             background:$middarker_neutral;
             font-weight:bold;
         }
    }
}

.active-member-firm-option {
    color: $darkest_neutral;
    background: #f0f0f0;
}
