
.settings-page-outer-container {
    margin-top: 30px;
}

.settings-page-container {
    column-gap: 30px;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 0 20px;
}

.settings-col-container {
    width: 50%;
    border: 1px solid var(--catalog-box-border-color);
    box-sizing: border-box;
    padding: 10px 40px 0 30px;
    border-radius: 5px;
    flex-direction: column;
    display: flex;

    h1 {
        font-size: 22px;
        margin: 0;
    }
}

.settings-page-dropdown {
    margin: 15px 0;
    max-width: 350px;
}

.btn-settings-page-save-changes {
    background: var(--theme-bg);
    border: 1px solid $color1;
    border-radius: 25px;
    padding: 8px 16px;
    margin: 30px 0 ;

    &:disabled {
        border-color: var(--catalog-box-border-color);
        cursor: default;

        @include darkMode {
            color: #808080;
        }
    }
}

.global-settings-divider {
    padding-top: 5px;
    border-bottom: 1px solid var(--catalog-box-border-color);
}

.preferred-catalogs-heading {
    font-size: 14px;
    padding: 10px 0 5px 0;
}

.toggle-container-dark-mode-settings-page {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    padding: 12px;
    border: 1px solid var(--main-border-color);
    border-radius: 5px;
    background-color: transparent;
}

.text-toggle-dark-mode-settings-page {
    margin-right: 20px;
    font-size: 13px;
    color: $middarkest_neutral;
    @include darkMode {
        color: $light_neutral;
    }
}

.toggle-dark-mode-page-settings-on,
.toggle-dark-mode-page-settings-off {
    height: 14px;
}

