.conversation-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .conversation-box {
        width: 85%;
        padding: 0 5% 0 0;
        box-sizing: border-box;
        height: calc(100vh - 185px);
        overflow-y: auto;
        margin-left: 10%;

        //Scroll Bar------------------------------------

        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #9e9e9e;
            border-radius: 10px;
        }

        .convo-box {
            margin-bottom: 15px;
            &:first-child {
                margin-top: 20px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        .conversation-ra-query {
            width: 100%;
            font-weight: 500;
            display: flex;
            justify-content: flex-end;

            font-size: 14px;
			line-height: 1.6;
			margin: 0.5em 0;
			font-family: "Roboto", Verdana, sans-serif;


            .query-box {
                background: #D7D7D7;
                border-radius: 0 12px 12px 12px;
                width: 30%;
                padding: 10px 20px 10px 20px;
                .query-label {
                    word-wrap: break-word;
                    white-space: pre-wrap;
                }

                @include darkMode {
                    background-color: #333333;
                    //color: #D7D7D7;
                    color: #f5f5f5;
                    border: 1px solid #444444;
                    
                }
            
            }
        
        }

        .conversation-ra-response {
            width: 100%;
            display: flex;
            justify-content: flex-start;

            .ra-response {
                background: #F8FCF7;
                width: 85%;
                border-radius: 0 12px 12px 12px;
                padding: 10px;

                
                @include darkMode {
                    background-color: #212121;
                    //color: #D7D7D7;
                    color: #f5f5f5;
                    border: 1px solid green;
                }
            }
        }
    }
    .conversation-box.hidden-footer {
        height: calc(100vh - 200px);
    }
    .conversation-box.conversationbox-with-error {
        height: calc(100vh - 241px);
    }
 
    .chatbot-introduction {
        // display: flex;
        align-items: center;
       
    }
    .hidden-footer.chatbot-introduction{
        height: calc(100vh - 170px);
    }
    
}