/*------------------------------------*\
    ARTICLE
\*------------------------------------*/
.article-container {
    width: 69%;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    padding-left: 20px;
    min-height: calc(91vh + 20px);

    p, ul, ol, .p {
        font-size: 14px;
        line-height: 1.5em;
    }

    ul.ul > li.li > div.p {
        margin-bottom: 10px;
    }

    ol.ol > li.li > div.p {
        margin-bottom: 10px;
    }

    ul.ul > li.li > div.p:only-child {
        margin-bottom: 0;
    }

    ol.ol > li.li > div.p:only-child {
        margin-bottom: 0;
    }

    .ol.decimal {
        list-style-type: decimal;
    }

    .ol {
        list-style-type: lower-alpha;
    }

    .ol.lower-alpha {
        list-style-type: lower-alpha !important;
    }

    .ol.upper-alpha {
        list-style-type: upper-alpha !important;
    }

    ol.article-jump-links-sub li {
        margin: 0;
        padding: 0;
    }

    ol.article-jump-links-sub {
        margin: 0;
    }

    .lower-roman {
        list-style-type: lower-roman;
    }

    p, .p {
        margin: 0.5em 0;
        color: #000;

        @include darkMode {
            color: white;
        }
    }

    .article-content-container table tr td .note_note {
        margin: 0px;
        font-size: 13px !important;
    }

    .article-content-container table tr td .note_ {
        margin: 0px;
        font-size: 13px !important;
    }

    .note_note, .note_ {
        margin: 0px;
        font-size: 14px !important;
    }

    [data-outputclass="indent"], .indent {
        margin: 1em 0 1em 4em;
    }

    .indent>ul.ul {
        margin: 10px 0;
    }

    [outputclass="color-red"], .color-red {
        color: #eb0000;
    }

    [outputclass="color-green"], .color-green {
        color: #008a09;
    }

    [outputclass="color-orange"], .color-orange {
        color: #d47200;
    }

    [outputclass="color-blue"], .color-blue {
        color: #0040ff;
    }
    [outputclass="shade"], .shade {
        background-color: #e3e3e3;
        color: inherit;
        padding: .5em;
    }
    ins, del {
        display: block;

        p, .p {
            display: flow-root;
        }

        img {
            max-width: calc(100% - 6px);
        }
    }

    ins {
        background-color: rgba(22, 226, 1, 0.3);
        text-decoration: none;

        img {
            border: 3px solid rgba(22, 226, 1, 0.3);
        }
    }

    del {
        background-color: rgba(226, 1, 17, 0.3);

        img {
            border: 3px solid rgba(226, 1, 17, 0.3);
        }
    }

    a {

        ins, del {
            display: unset;
        }
    }

    li {

        ins, del {
            display: unset;
        }
    }

    ol, ul {
        li {
            .p {
                margin: 0;
            }
        }
    }

    .title {
        color: $darkest_neutral;

        @include darkMode {
            color: inherit;
        }
    }

    table {
        thead {
            tr:nth-child(1) {
                th {
                    background-color: black;

                    .p {
                        color: #ffffff;
                        font-size: 15px;
                        font-weight: bold;
                    }

                    .lp-pom-text ul,
                    li {
                        color: #ffffff;
                    }
                }
            }

            tr:nth-child(2) {
                th {
                    background-color: gray;

                    .p {
                        color: #ffffff;
                        font-size: 15px;
                        font-weight: bold;
                    }

                    .lp-pom-text ul,
                    li {
                        color: #ffffff;
                    }
                }
            }

            tr:nth-child(3) {
                th {
                    background-color: white;

                    .p {
                        color: #000;
                        font-size: 15px;
                        font-weight: bold;
                    }

                    .lp-pom-text ul,
                    li {
                        color: #000;
                    }
                }
            }

            tr:nth-child(n+4) {
                th {
                    background-color: white;

                    .p {
                        color: #000;
                        font-size: 13px;
                        font-weight: bold;
                    }

                    .lp-pom-text ul,
                    li {
                        color: #000;
                    }
                }
            }
        }
    }
}

.main-expanded {
    .article-container {
        .article-header.fixed {
            width: 77.9%;
        }
    }
}

.article-container-landing {

    h1 {
        width: 80%;
        margin: 2em auto 1.5em auto;
        font-size: 1.5em;
        text-align: center;
    }

    .search {
        padding: 1em 0;
        margin: 0px 0px 50px 0px;
        text-align: center;

        input {
            width: 50%;
            background-position: 0;
            padding: 1em 3em 1em 3em;
            font-size: 0.95em;
            background-size: 18.89px 20px;
            border: 1px solid $midlight_neutral;
            border-radius: 5px;
            background: transparent url("/images/icon-search-gr.svg") no-repeat calc(0% + 15px) center;

            &:focus {
                transition: background 0.2s ease;
                background: transparent url("/images/icon-search.svg") no-repeat calc(0% + 15px) center;
                border: 2px solid $middarkest_neutral;
            }
        }
    }

    .article-preface {
        margin: 1em 25px;
    }

    .article-copyright {
        font-size: 10px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 10px;
        position: absolute;
        color: $mid_neutral;
    }
}

.linklist-section {
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    margin: 0 15px;
    justify-content: space-between;

    .linklist-block {
        flex: 0 0 47%;
        margin: 0 0 30px 0;
        border: 1px solid $color6;
        background-color: $color7;
        box-shadow: 0 0 5px $color6;

        p {
            border-bottom: 1px solid $color6;
            padding: 0.5em 1em;
            margin: 0;
            background-color: $lightest_neutral;
            font-size: 1em;
        }

        ul {
            margin: 0;
            padding: 1em 1em 1em 40px;

            li {
                padding: 0 0 .5em 0;
            }
        }
    }
}

.article-container-list {
    margin: 2em 1em;

    h1 {
        font-size: 2em;
        margin: 0 0 1em 0;
    }

    .publication-section {
        margin: 2em 0 3.5em 0;

        h2 {
            border-bottom: 1px dotted $midlight_neutral;
            font-weight: 700;
            font-size: 0.85em;
            margin: 0 0 2em 0;
            padding: 0 0 0.5em 0;
            text-transform: uppercase;
        }

        ul {
            padding: 0 1em 0 40px;

            li {
                padding: 0 0 0.5em 0;
            }
        }
    }
}

.article-container {
    .sticky-bar.fixed {
        position: fixed;
        top: 0;
    }

    .article-header.fixed {
        background-color: $lightest_neutral;
        margin: 0 0 0 -31px;
        width: 61.9%;
        z-index: 2;
        padding: 12px 18px 8px 18px;
        box-shadow: 0 0 5px $midlight_neutral;
        height: 40px;

        h1 {
            font-size: 1em;
            margin: 10px 0;
            top: 0;
        }

        .article-toolbar-panel {
            top: 51px;
        }
    }

    .fixed-pushdown {
        margin-top: 74px !important;
    }

    .article-header {
        margin: 10px 0 22px 0;
        border-bottom: 1px solid $light_neutral;
        width: calc(100% + 4em - 36px);
        margin: 0 0 0 -32px;
        padding: 17px 18px;

        .article-list {
            display: inline-block;
            margin: 0 0.5em 0 0;
        }

        h1 {
            width: calc(100% - 200px);
            display: inline-block;
            font-size: 1.25em;
            line-height: 1.25em;
            font-weight: 300;
            margin: 0;
            position: relative;
            top: 3px;
        }

        .main-button-panel {
            float: right;
            position: relative;
            top: -2px;

            button {
                height: 40px;
                width: 30px;
                border: none;
                background-color: unset;
            }

            .print {
                background-image: url("/images/icon-print-tp-gr.svg");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 24px 24px;
                transition: background-image 0.2s ease;

                &:hover {
                    background-image: url("/images/icon-print-tp-bl.svg");
                    background-size: 24px 24px;
                    transition: background-image 0.2s ease;
                }
            }

            .download {
                background-image: url("/images/icon-word-gr.svg");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 24px 24px;
                transition: background-image 0.2s ease;

                &:hover {
                    background-image: url("/images/icon-word-bl.svg");
                    background-size: 24px 24px;
                    transition: background-image 0.2s ease;
                }
            }

            .bookmark {
                background-image: url("/images/icon-bookmark-gr.svg");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 24px 24px;
                transition: background-image 0.2s ease;

                &:hover {
                    background-image: url("/images/icon-bookmark-bl.svg");
                    background-size: 24px 24px;
                    transition: background-image 0.2s ease;
                }
            }
        }
    }

    a[target="_blank"]:not(.technical-updates):not(.aalerts-financial):not(.aalerts-practice):not(.finditfast):not(.questions-comments):not(.technology-issues):not(.compare-versions-link) {
        position: relative;
        margin: 0 1.3em 0 0;

        &:not(.related-link):after {
            background: url("/images/icon-external.svg") no-repeat;
            background-size: 11px 11px;
            width: 11px;
            height: 11px;
            content: "";
            position: absolute;
            margin: 0 0 0 4px;
        }
    }

    hr {
        background: $midlight_neutral;
        border: none;
        margin: 2.15em auto 2em auto;
        width: 10%;
        height: 1px;
    }

    img {
        margin: 15px auto;
    }

    img, figure {
        display: block;
    }

    figure {
        margin: 0;

        figcaption {
            font-size: .85em;
            font-weight: bold;
            text-align: left;
        }
    }

    .lq {
        padding: 1em;
        border-left: 6px solid $light_neutral;
        margin: 1em 40px;
    }

    blockquote.lq {
        font-size: .85em;
    }

    .note-generic {
        border-radius: 5px;
        border: 1px solid #666;
        margin: 24px auto;
        padding: 8px 16px;
        font-size: 13.6px;

        & > p {
            font-size: inherit;
        }
    }

    .underline {
        text-decoration: underline;
    }

    nav.related-links a,
    .localref {
        &:before {
            content: "[";
            margin: 0 2px 0 0;
        }

        &:after {
            content: "]";
            margin: 0 0 0 2px;
        }
    }

    .annotation {
        border-radius: 0.3em;
        font-size: 13px;
        margin: 0 0 0 0.25em;

        .annotation-icon {
            background: url("/images/icon-info-gr.svg") no-repeat left bottom;
            background-size: 14px 20px;
            cursor: pointer;
            width: 14px;
            margin: 0 0.25em 0 0;
            display: inline-block;
            border: 0;
            transition: background 0.2s ease;
            padding: 1px 6px;

            &:hover {
                background: url("/annotation-hover.svg") no-repeat left bottom;
                background-size: 14px 20px;
                transition: background 0.2s ease;
            }
        }

        .annotation-text {
            color: $color4;
            display: none;

            @include darkMode {
                color: $light_neutral;
            }

            a {
                font-style: normal;
            }
        }

        &.open {
            .annotation-icon {
                background: url("/images/icon-info-close.svg") no-repeat left bottom;
                background-size: 14px 20px;
            }

            .annotation-text {
                display: inline-block;
            }
        }
    }

    .expandable-article {
        margin: 2em 0;
        padding: 0 23px;

        button {
            border: none;
            background-color: unset;
            font-size: .8em;
            margin: 0 0 0.5em 0;
            padding: 0 0 0 23px;
            color: $darkest_neutral;
            text-align: left;
            background: url("/images/chevron-green-right.svg") no-repeat 8px center;
            background-size: 5px 10px;

            &:hover {
                color: $color5;
            }

            h2 {
                margin: 0;
            }
        }

        .expandable-article-body {
            line-height: 1.25em;
            padding: 1em 2.5em 1em 1.25em;
            width: 90%;
            display: none;

            .title {
                font-size: 0.95em;
                font-weight: 700;
                margin: 0 0 0.5em 0;
            }
        }

        &.open {
            & > button {
                background: url("/images/chevron-gray-down.svg") no-repeat 5px center;
                background-size: 10px 10px;

                &:hover {
                    background: url("/images/chevron-green-down.svg") no-repeat 5px center;
                    background-size: 10px 10px;
                    color: $darkest_neutral;
                }
            }

            .expandable-article-body {
                display: block;
            }
        }

        .expandable-article-subsection {
            &:first-child {
                margin: 10px 0 0 0;
            }

            .expandable-article-subsection-body {
                display: none;
                margin: 5px 0 32px 22px;
                border-top: 1px solid $midlight_neutral;
                padding: 5px 0 0 0;

                .title {
                    &:first-child {
                        margin: 13px 0 0 0;
                    }
                }
            }

            button {
                padding: 5px 0 0 23px;

                h4 {
                    font-weight: 400;
                    font-size: 14px;
                    margin: 0 0 5px 0;
                }
            }

            &.open {
                & > button {
                    background: url("/images/chevron-gray-down.svg") no-repeat 5px center;
                    background-size: 10px 10px;

                    &:hover {
                        background: url("/images/chevron-green-down.svg") no-repeat 5px center;
                        background-size: 10px 10px;
                        color: $darkest_neutral;
                    }
                }

                .expandable-article-subsection-body {
                    display: block;
                }
            }
        }
    }

    .expandable-article-panel {
        border: 1px solid $color6;
        border-left: 3px solid $middark_cool;
        margin: 2em 0;
        padding: 15px 15px 12px 15px;
        width: calc(100% - 34px);

        .expandable-article-body {
            width: calc(100% - 52px);
            padding: .5em 40px 0 12px;
        }

        .expandable-article-block {
            width: 96%;
            padding: 15px 0 10px 0;

            .expandable-article-block-body {
                display: none;
            }

            &.open {
                .expandable-article-block-body {
                    display: block;
                }

                & > button {
                    background: url("/images/chevron-gray-down.svg") no-repeat 5px center;
                    background-size: 10px 10px;

                    &:hover {
                        background: url("/images/chevron-green-down.svg") no-repeat 5px center;
                        background-size: 10px 10px;
                        color: $darkest_neutral;
                    }
                }
            }

            button {
                h3 {
                    font-size: 1.25em;
                    margin: 0;
                }
            }

            .expandable-article-subsection {
                width: 96%;
                padding: 15px 0 10px 0;

                &.open {

                    .expandable-article-subsection-body {
                        display: block;
                    }

                    & > button {
                        background: url("/images/chevron-gray-down.svg") no-repeat 5px center;
                        background-size: 10px 10px;

                        &:hover {
                            background: url("/images/chevron-green-down.svg") no-repeat 5px center;
                            background-size: 10px 10px;
                            color: $darkest_neutral;
                        }
                    }
                }

                .expandable-article-subsection-body {
                    margin: 5px 0 32px 15px;
                }
            }
        }

        .expandable-article-block-body {
            display: none;
            margin: 0 0 0 25px;
        }

        .more-info {
            font-size: 12px;
        }
    }

    .footnote {
        a {
            border-bottom: none;
            font-weight: 700;
            font-size: 90%;
            padding: 0 0.15em 0 0.35em;
            color: $brandedcolor5;
        }
    }

    .footnote-click a {
        padding: 0 0.15em 0 0;
    }

    .endnote-container {
        border-top: 1px solid var(--main-border-color);
        padding: 1em 2em;
        margin: 0 -2em;

        .endnote-table-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        p {
            font-size: 11px;
            font-style: italic;
            margin: 10px 0;
            position: relative;
            flex: 1 1 auto;
            width: 100%;
            display: flex;
            flex-direction: row;
        }

        sup {
            font-weight: 700;
            flex: 0 0 auto;
            max-width: 120px;
            overflow-wrap: break-word;
            display: inherit;
        }

        .endnote-text {
            flex: auto;
            margin-left: 18px;
            font-size: 11px;

            p, .p, ol, ul {
                margin: 0 0 1em 0;
                font-size: 11px;
                display: block;
            }

            ol, ul {
                display: block;

                li {
                    display: revert;
                }
            }

            ol {
                list-style-type: decimal;
            }

            ul {
                list-style-type: disc;
            }
        }

        .endnote-title {
            display: block;
            font-size: 1em;
            margin: 0 0 2em 0;
            padding: 0;
            font-style: normal;
        }
    }

    .footnotes {
        display: flex;
        margin: 10px 0;

        a {
            font-size: 11px;
            border-bottom: none;
        }
    }

    .copyright {
        font-size: 10px;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        padding-top: 20px;

        p, .p {
            text-align: center;
            font-size: 10px;
        }
    }

    .structured-article {
        padding: 1px 0;

        & > button {
            font-size: 1em;
            background: url("/images/chevron-green-right.svg") no-repeat 11px 15px;
            background-size: 5px 10px;
            border: 1px solid var(--article-section-border-color);
            padding-bottom: 0px;
            margin-left: -25px;

            &:hover {
                border-color: $middark_cool;
            }
        }

        & > .structured-article-title-button {
            background: url("/images/chevron-green-right.svg") no-repeat 11px 20px;
            background-size: 5px 10px;
        }

        &.open {
            & > button {
                background: unset;
                border: 1px solid transparent;

                &:hover {
                    background: url("/images/chevron-gray-down.svg") no-repeat 6px 14px;
                    background-size: 10px 10px;
                    border-color: transparent;
                }
            }

            & > .structured-article-title-button {
                &:hover {
                    background: url("/images/chevron-gray-down.svg") no-repeat 6px 20px;
                    background-size: 10px 10px;
                }
            }

            .structured-article-panel {
                display: block;
                padding: 0;
            }
        }

        .structured-article-section {
            // The outermost .structured-article-section needs to be pushed to the left to compensate for the offset created from the border
            position: relative;

            .structured-article-section {
                margin-left: 0;
            }



            &.open {
                & > button {
                    border: 1px solid transparent;
                    background: unset;

                    &:hover {
                        background: url("/images/chevron-gray-down.svg") no-repeat 6px 12px;
                        background-size: 10px 13px;
                        border-color: transparent;
                    }
                }

                .structured-article-subpanel {
                    display: block;
                }

                .comment-container {
                    display: block;
                }
            }

            & > button {
                font-size: 1em;
                padding: 8px 25px;
                margin: 0;
                border: 1px solid var(--article-section-border-color);
                background: url("/images/chevron-green-right.svg") no-repeat 10px 14px;
                background-size: 5px 10px;
                border-radius: 5px;


                &:hover {
                    border-color: $middark_cool;
                }
            }

            .structured-article-section {
                padding: 0;

                .structured-article-subpanel:not(.information-topics) {
                    display: none;
                    margin: 0 0 0.5em 0;
                    padding: 0;
                    padding-left: 25px;
                }

                .structured-article-subpanel {
                    margin: 0 0 0.5em 0;
                    padding: 0;
                    padding-left: 25px;
                }

                &.open {
                    & > .structured-article-subpanel {
                        display: block;
                    }
                }
            }

            .comment-container {
                display: none;
            }
        }

        .structured-article-panel {
            display: none;
            margin: 0 0 0.5em 0;
        }

        .structured-article-subpanel:not(.information-topics) {
            display: none;
            padding: 0 0 0 1px;
        }
        button {
            margin: 0 0 0 -25px;
            width: 100%;
            text-align: left;
            padding: 8px 25px;
            border-radius: 5px;

            button {
                margin-left: 0;
            }



            h2, h3, h4, h5, h6 {
                display: inline-block;
                margin: 0;
            }

            h2 {
                font-size: 1em;
            }

            .structured-article-section {
                h2 {
                    font-size: 1em;
                }
            }

            h3 {
                font-size: 1em;
            }

            h4 {
                font-size: 1em;
            }

            h5, h6 {
                font-size: .85em;
            }
        }

        .structured-article-title-button {
            margin-left: -25px;
            width: calc(100% + 25px);

            h2 {
                font-size: 24px;
            }
        }
    }

    .applicability-tag-table {
        display: none;
    }

    .applicability-tag {
        margin-bottom: 15px;

        .applicability-tag-span {
            border: 1px solid  #0076A8;
            border-radius: 10px;
            color: #0076A8;
            display: inline-block;
            padding: 4px 5px;
            font-weight: 500;
            margin: 5px;
            font-size: 12px;
            line-height: 14px;

            @include darkMode {
                background-color: $middark_neutral;
                color: white;
            }
        }
    }

    .variation-beta {
        .applicability-tag {
            span {
                background-color: #e8f6ff;
                border-color: $color9;

                @include darkMode {
                    background-color: $color9;
                }
            }
        }
    }

    .variation-gamma {
        .applicability-tag {
            span {
                border: 1px solid $color10;
                background-color: $color11;
                color: $darkest_neutral;

                @include darkMode {
                    background-color: $color10;
                }
            }
        }
    }


    .related-links {
        margin: 0px 0 0 -4px;
        padding-top: 2px;


        ul {
            list-style-type: none;
            display: inline-block;
            margin: 0;
            padding: 0;
            width: calc(100% - 38px);

            li {
                display: inline-block;
                margin: 0 .75em .5em 0;
                padding: 0;

                a {
                    font-size: 13px;
                }
            }
        }

        .related-links-icon {
            background: url("/images/icon-related.svg") no-repeat 5px 5px;
            background-size: 11px 11px;
            opacity: .3;
            border: 1px solid $middarkest_neutral;
            display: inline-block;
            border-radius: 1em;
            height: 20px;
            width: 20px;
            font-size: 13px;
            margin: 0 .85em 0 0;
        }
    }

    .policy {
        .related-links {
            ul {
                li {
                    a {
                        font-weight: normal !important;
                    }
                }
            }
        }
    }

    .associated-content {
        width: 90%;
        margin: 2em auto 2em 23px;
        background-color: var(--associated-content-bg);
        border-left: 1px dotted $midlight_neutral;
        padding: 1em 0.5em;

        .associated-content-title {
            font-size: 0.85em;
            margin: 0 0 .5em 0;
            padding: 0 .5em .5em 1em;
        }

        ul {
            padding: 0 0 0 15px;
            font-size: 13px;

            li {
                list-style: none;
                margin: 0 0 0.75em 0;
                font-size: 13px;
                padding: 0;

                &:before {
                    color: $color1;
                    content: "■";
                    margin: 0 0.75em 0 0;
                }
            }
        }
    }

    .comment-container {
        margin: 0 0 0 25px;
    }
    /*START: OLD TOOLBAR - NEEDS REMOVED*/
    .toolbar-container {
        position: absolute;
        top: 0;
        right: -40px;
        z-index: 1;
        background-color: $lightest_neutral;

        button {
            height: 26px;
            margin: 3px 3px 0 3px;
            width: 26px;
            padding: 0;
        }

        .toolbar-btn {
            background: transparent url("/images/tools-control-off.svg") no-repeat center center;
            background-size: 30px 30px;
            border: none;
            transition: background 0.2s ease;

            &:hover {
                background: transparent url("/images/tools-control-hover.svg") no-repeat center center;
                background-size: 30px 30px;
                transition: background 0.2s ease;
            }
        }

        .toolbar-panel {
            display: none;

            button {
                border: 1px solid $light_neutral;
                border-radius: 4px;
                transition: all 0.2s ease;
                background-color: $lightest_neutral;

                &:hover {
                    transition: all 0.2s ease;
                    background-color: $lighter_neutral;
                }
            }

            button[disabled] {
                filter: contrast(70%) opacity(30%);
                cursor: not-allowed;
            }
        }

        .copy-toolbar-btn {
            background-image: url("/images/icon-merge-bk.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 14px 14px;
        }

        .selhighlight-toolbar-btn {
            background-image: url("/images/icon-highlight.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 14px 14px;
        }

        .comment-toolbar-btn {
            background-image: url("/images/icon-comment.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 14px 14px;
        }

        .link-toolbar-btn {
            background-image: url("/images/icon-related.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 14px 14px;
        }

        .print-toolbar-btn {
            background-image: url("/images/icon-print-tool.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 14px 14px;
        }

        &.open {
            border: 1px solid $light_neutral;
            border-radius: 21.5px;
            box-shadow: 0 0 5px $color6;
            padding: 5px 5px 8px 15px;
            top: -6px;
            right: -46px;

            .toolbar-panel {
                display: inline-block;
            }

            .toolbar-btn {
                background: transparent url("/images/tools-control-on.svg") no-repeat center center;
                background-size: 30px 30px;
                transition: all 0.2s ease;

                &:hover {
                    background: transparent url("/images/tools-control-hover.svg") no-repeat center center;
                    background-size: 30px 30px;
                    transition: all 0.2s ease;
                }
            }
        }
    }
    /*END: OLD TOOLBAR - NEEDS REMOVED*/
    /*Start: Old endnote. Need to remove or update, depending on what Design defines here*/
    .endnote-overlay {
        position: absolute;
        width: 150px;
        border: 1px solid $light_neutral;
        padding: .5em 1em 1em 1em;
        box-shadow: 0 0 5px $light_neutral;
        background-color: $lightest_neutral;
        border-radius: 0.25em;
        font-size: 75%;

        .endnote {
            margin: 0 0 1em 0;
            padding: 0 0 0 6px;

            sup {
                color: $color1;
                font-weight: 700;
                display: block;
                position: relative;
                right: 6px;
                font-size: 1em;
            }
        }

        .endnote-arrow {
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid $light_neutral;
            position: absolute;
            top: -15px;

            &:after {
                content: "";
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid $lightest_neutral;
                position: absolute;
                top: 2px;
                right: -15px;
            }
        }

        .endnote-close {
            float: right;
            color: $brandedcolor5;
            position: relative;
            left: 10px;
            border-bottom: none;
            font-weight: 700;
        }
    }
    /*End: Old endnote. Need to remove or update, depending on what Design defines here*/
}

.article-list {
    position: relative;

    .article-list-btn {
        background-color: $mid_cool;
        background-image: url("/images/icon-article-toc-wh.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 15px 13.13px;
        border: 2px solid $mid_cool;
        border-radius: 0.25em;
        height: 34px;
        width: 34px;

        &:hover {
            border: 2px solid $middark_cool;
            background-color: $middark_cool;
        }

        &.open {
            background-image: url("/images/icon-article-toc.svg");
            border: 2px solid $mid_cool;
            background-color: $lightest_neutral;
        }
    }

    .article-list-panel {
        background-color: $lightest_neutral;
        padding: 15px;
        width: 350px;
        position: absolute;
        box-shadow: 0 0 5px $mid_neutral;
        left: -18px;
        top: 41px;
        z-index: 1;

        ol {
            padding: 0 10px;
            margin: 0;

            li {
                margin: 0 0 0.5em 0;
                padding: 0;
                font-size: 0.85em;
            }

            a {
                font-size: 1em;
            }
        }

        .article-list-panel-arrow {
            background-image: url("/images/article-arrow-top.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 30px 15px;
            height: 15px;
            width: 30px;
            position: absolute;
            top: -14px;
            left: 20px;
        }

        .article-list-close {
            background: $darkest_neutral url("/images/icon-close.svg") no-repeat center center;
            background-size: 8px 8px;
            height: 18px;
            width: 18px;
            border-radius: 0.25em;
            transition: all 0.2s ease;
            border: none;
            position: absolute;
            top: 8px;
            right: 8px;

            &:hover {
                transform: rotate(180deg);
                transition: all 0.2s ease;
            }
        }
    }
}

/*Start: Interactive Feature Overlays*/
.interactive-overlay {
    position: fixed;
    left: calc(50% - 243px);
    width: 486px;
    top: 15%;
    background-color: $color7;
    box-shadow: 5px 5px 15px $midlight_neutral;
    border-radius: 5px;
    z-index: 1;

    .overlay-header {
        background-color: $lightest_neutral;
        border-bottom: 1px solid $color6;
        padding: 1em;
        font-weight: 700;
        border-radius: 5px 5px 0 0;

        p {
            margin: 0;
            font-size: 1em;
        }

        .overlay-close {
            float: right;
            background-color: unset;
            color: $middarkest_neutral;
            border: none;
            padding: 5px 7px 5px 10px;
            position: relative;
            bottom: 5px;
            right: -7px;
            font-size: 1em;
            text-align: right;
            width: auto;
            margin: 0;
        }
    }

    .overlay-body {
        margin: 1em;
    }

    .overlay-btnbar {
        text-align: right;
    }

    .overlay-btn {
        border: none;
        border-radius: 5px;
        font-size: .85em;
        cursor: pointer;
        background-color: unset;
        margin: 15px 5px 10px 5px;
    }

    .primary-btn {
        background-color: $mid_cool;
        color: $lightest_neutral;
        transition: background-color 0.2s ease;
        padding: 10px;

        &:hover {
            background-color: $middark_cool;
            transition: background-color 0.2s ease;
        }
    }

    .secondary-btn {
        border: 1px solid $color1;
        transition: border 0.2s ease;
        padding: 9px;

        &:hover {
            border: 1px solid $middark_cool;
            transition: border 0.2s ease;
        }
    }

    .tertiary-btn {
        color: $darkest_neutral;
        text-decoration: none;
        border-bottom: 1px solid $color1;
        padding: 0;
        border-radius: 0;
        transition: border-bottom .2s ease;

        &:hover {
            border-bottom: 1px solid $color2;
            transition: border-bottom .2s ease;
        }
    }
}

.comment-overlay {
    form {
        textarea {
            width: calc(100% - 26px);
            height: 70px;
            resize: vertical;
            max-height: 400px;
            min-height: 100px;
            font-family: $primary_font_stack;
            padding: 10px;
        }
    }
}

.comment-block {
    border: 1px solid $color6;
    background-color: $color7;
    box-shadow: 0 0 5px $color6;
    border-radius: 5px;
    margin: 10px 0;
}

.comment-block-head {
    border-radius: 5px 5px 0 0;
    background-color: $lightest_neutral;
    border-bottom: 1px solid #e6e6e6;
    padding: .5em 1em;

    .timedate, .user {
        display: inline-block;
        margin: 0;
    }

    .user {
        &:after {
            content: " | ";
        }
    }
}

.comment-block-body {
    padding: .5em 1em;

    p {
        margin: 10px 0 15px 0;
    }
}

/*End: Interactive Feature Overlays*/
/*Start: Preset Highlight Colors*/
.selection-preset-colors {
    display: flex;
    flex-flow: row wrap;

    button {
        background-color: unset;
        border: none;
        flex: 0 0 33%;
        text-align: left;
        margin: 5px 0;
    }

    .circle-outer {
        background-color: $lightest_neutral;
        border: 2px solid $color4;
        height: 28px;
        width: 28px;
        border-radius: 28px;
        display: inline-block;
        margin: 0 5px 0 0;
    }

    .circle-inner {
        height: 20px;
        width: 20px;
        border-radius: 20px;
        display: inline-block;
        position: relative;
        top: 4px;
        left: 4px;
    }

    .button-label {
        position: relative;
        top: 5px;
    }
}

/*None*/
.highlight-color-0 {
    background-color: unset;

    .circle-inner {
        height: 2.13em;
        width: 2px;
        background-color: red;
        transform: rotate(35deg);
        left: 13px;
        top: 0;
    }
}

/*Teal*/
.highlight-color-1 {
    background-color: #bfffd8;

    .circle-inner {
        background-color: #bfffd8;
    }
}

/*Light Purple*/
.highlight-color-2 {
    background-color: #dedaff;

    .circle-inner {
        background-color: #dedaff;
    }
}

/*Light Yellow*/
.highlight-color-3 {
    background-color: #ffe473;

    .circle-inner {
        background-color: #ffe473;
    }
}

/*Light Green*/
.highlight-color-4 {
    background-color: #e3e48d;

    .circle-inner {
        background-color: #e3e48d;
    }
}

/*Pink*/
.highlight-color-5 {
    background-color: #f4bfbb;

    .circle-inner {
        background-color: #f4bfbb;
    }
}

/*Yellow*/
.highlight-color-6 {
    background-color: #ffcd00;

    .circle-inner {
        background-color: #ffcd00;
    }
}

/*Green*/
.highlight-color-7 {
    background-color: #c4d600;

    .circle-inner {
        background-color: #c4d600;
    }
}

/*Light Coral*/
.highlight-color-8 {
    background-color: #e35f55;

    .circle-inner {
        background-color: #e35f55;
    }
}

.highlight-hidden {
    background-color: unset;
}

/*Electric Blue for Search*/
.highlight-search {
    background-color: rgba(227, 228, 141, 0.6);

    &.current {
        background-color: rgba(111, 194, 180, 0.6);
    }

    @include darkMode {
        color: #ffffff;
    }

    .react-pdf__Page__textContent & {
        color: transparent;

        @include darkMode {
            color: transparent;
        }
    }
}

.react-pdf__Page__textContent {

    .highlight-search {
        background-color: rgba(227, 228, 141, 0.6);
        border-bottom: 1px solid #e3e48d;

        &.current {
            background-color: rgba(111, 194, 180, 0.6);
            border-bottom: 1px solid #6fc2b4;
        }
    }
}



/*End: Preset Highlight Colors*/
/*Start: Article Toolbar*/
.article-toolbar {
    float: right;
    border-left: 1px solid $midlight_neutral;
    padding: 0 0 0 9px;
    margin: 0 0 0 15px;

    .article-tools {
        background: url("/images/icon-gear.svg") no-repeat center center;
        background-size: 20px 20px;
        transition: background 0.2s ease, transform 0.2s ease;

        &:hover {
            background: url("/images/icon-gear-bl.svg") no-repeat center center;
            background-size: 20px 20px;
            transform: rotate(90deg);
            transition: background 0.2s ease, transform 0.2s ease;
        }
    }

    .article-toolbar-panel {
        display: none;
        background-color: $middarkest_neutral;
        font-size: 0.75em;
        position: absolute;
        right: -18px;
        top: 60px;
        width: 200px;
        z-index: 2;

        a[target="_blank"] {
            &:after {
                background: unset;
            }
        }
    }

    .article-toolbar-row {
        border-top: 1px solid $middarker_neutral;
        color: $lightest_neutral;
        padding: 0 0 0.25em 0;

        .article-language-switch {
            margin: 1em;

            label {
                float: left;
            }

            select {
                float: right;
                width: 75px;
            }
        }

        .btn {
            background-color: $color1;
            border-radius: 0.25em;
            color: white;
            display: block;
            padding: 0.5em;
            margin: 1em;
            text-align: center;
            text-decoration: none;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: $middark_cool;
                transition: background-color 0.2s ease;
            }
        }
    }

    .article-toolbar-sliders {
        a {
            background-image: url("/images/icon-switch-on.svg");
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) center;
            background-size: 35px 20px;
            background-color: rgba(0, 0, 0, 0.3);
            border-bottom: 1px solid $middarkest_neutral;
            color: $midlight_neutral;
            display: block;
            padding: 1em;
            text-decoration: none;

            &.off {
                background-image: url("/images/icon-switch-off.svg");
            }
        }
    }

    .article-toolbar-tabs {
        padding: 1em;

        p {
            font-size: 0.65em;
            font-weight: 700;
            text-transform: uppercase;
            margin: 0 0 .5em 0;
        }

        .tab-collapse {
            border-top-left-radius: 0.25em;
            border-bottom-left-radius: 0.25em;
        }

        .tab-expand {
            border-top-right-radius: 0.25em;
            border-bottom-right-radius: 0.25em;
        }

        .tab {
            background-color: $color1;
            border: 1px solid $color1;
            padding: 0.5em;
            color: $lightest_neutral;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            width: 74px;
            float: left;
            transition: border 0.2s ease, background-color 0.2s ease;

            &.off {
                background-color: transparent;
                border: 1px solid $mid_neutral;
                transition: border 0.2s ease, background-color 0.2s ease;

                &:hover {
                    background-color: $dark_neutral;
                    border: 1px solid $middark_cool;
                    transition: border 0.2s ease, background-color 0.2s ease;
                }
            }
        }
    }

    &.open {
        .article-tools {
            background: url("/images/icon-close-bk.svg") no-repeat center center;
            background-size: 16px 16px;
            transform: rotate(0);
        }

        .article-toolbar-panel {
            display: block;
        }
    }
}

/*End: Article Toolbar*/
/*Start: Additional Article Components*/
.figure-block {
    border: 1px solid $darkest_neutral;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px 25px;
    margin-top: 16px;
    margin-bottom: 25px;

    .figure-block-head {
        display: block;
        padding: 0.5em 0;
        border-top-left-radius: 0.25em;
        border-top-right-radius: 0.25em;
        font-size: 16px;
        font-weight: 700;

        p, .p {
            margin: 0;
            font-weight: 700;
            font-size: 1em;
        }
    }

    .figure-block-body {
        padding: 0.5em 0;
        font-size: 13px;

        p, .p {
            margin: 0 0 13px 0;

            &:last-child {
                margin: 0;
            }
        }
    }

    &.rm_asc_snippet {
        padding: 0;
        border: 1px solid $dark_neutral;

        .figure-block-head {
            background: $middarker_neutral;
            color: $lightest_neutral;
            padding: 0.5em 1em;
        }

        .figure-block-body {
            padding: 0.75em;
            font-size: 13px;
        }

        p, .p {
            font-size: inherit;
        }
    }

    &.figure-block-bar {
        .figure-block-head {
            padding: .5em 0;
            background-color: $darkest_neutral;
            color: $lightest_neutral;
        }
    }

    &.variation-alpha, &.deloitte-interpretation, &.box-emphasis {
        border: 1px solid $midlight_cool;

        .figure-block-head {
            background-color: unset;
            color: inherit;
        }
    }

    &.variation-beta, &.qa, &.rm_example, &.deloitte-background, &.rm_illustrative {
        border: 1px solid $color9;

        .figure-block-head {
            background-color: unset;
        }
    }

    &.variation-gamma {
        border: 1px solid $color10;

        .figure-block-head {
            background-color: unset;
        }
    }

    &.variation-delta {
        border: 1px solid $light_neutral;

        .figure-block-head {
            background-color: unset;
            color: $darkest_neutral;
        }
    }

    &.example {
        border-color: $dark_neutral;
    }
    /*when nested*/
    .figure-block {
        .figure-block-head {
            background-color: $lighter_neutral;
            color: $darkest_neutral;
            border-bottom: 1px solid $darkest_neutral;
        }
    }
}

.gaap-flag {
    border: 1px solid $darkest_neutral;
    background-color: $darkest_neutral;
    color: $lightest_neutral;
    border-radius: 0.25em;
    margin: 0 25px;
    padding: 2px 4px;
    text-align: center;
    display: inline-block;

    p {
        font-size: .5em;
        margin: 0;
    }
}

.variation-alpha, .deloitte-interpretation {
    .gaap-flag {
        border: 1px solid $midlight_cool;
        background-color: $color8;
        color: $darkest_neutral;

        @include darkMode {
            background-color: $midlight_cool;
            color: white;
        }
    }
}

.variation-beta, .deloitte-background {
    .gaap-flag {
        border: 1px solid $color9;
        background-color: $color12;
        color: $darkest_neutral;

        @include darkMode {
            background-color: $color9;
            color: white;
        }
    }
}

.variation-gamma {
    .gaap-flag {
        border: 1px solid $color10;
        background-color: $color11;
        color: $darkest_neutral;

        @include darkMode {
            background-color: $color10;
            color: white;
        }
    }
}

.variation-delta {
    .gaap-flag {
        border: 1px solid $light_neutral;
        background-color: $lighter_neutral;
        color: $darkest_neutral;

        @include darkMode {
            background-color: $light_neutral;
            color: white;
        }
    }
}

.sidebar-block {
    border-left: 1px solid $darkest_neutral;
    margin: 1em 0 32px 0;

    .sidebar-block-head {
        display: block;
        padding: 0.5em 25px;
        font-size: 16px;
        font-weight: 700;

        p, .p {
            margin: 0;
            font-weight: 700;
            font-size: 1em;
        }
    }

    .sidebar-block-body {
        padding: 0.5em 25px;
        font-size: 13px;

        p, .p {
            margin: 0 0 13px 0;
            font-size: inherit;
        }
    }

    &.variation-alpha, &.gaap, &.lanes, &.dots, &.sec, &.deloitte-standards, &.deloitte-uk {
        border-color: $midlight_cool;
    }

    &.variation-beta, &.construction, &.driving, &.note, &.key, &.thinking, &.deloitte-background, &.deloitte-interpretation {
        border-color: $color9;
    }

    &.variation-gamma {
        border-color: $color10;
    }

    &.variation-delta {
        border-color: $light_neutral;
    }

    &.deloitte-ifrs {
        border-color: $middarker_neutral;
    }
}

.contacts {
    display: flex;
    flex-flow: row wrap;
    margin: 1em 0;

    .contact-block {
        flex: 0 0 43%;
        margin: 1em;

        img {
            display: inline-block;
            margin: 0 1em 0 0;
        }

        p,
        .p {
            margin: 0;
        }
    }

    .contact-info {
        display: inline-block;
        vertical-align: top;

        .contact-name {
            font-size: 1em;
        }

        .contact-title {
            border-bottom: 1px solid $light_neutral;
            font-size: 0.65em;
            font-weight: 300;
            margin: 0 0 .5em 0;
            padding: 0 0 .25em 0;
            text-transform: uppercase;
        }
    }
}

.file-container {
    border: 1px solid var(--main-border-color);
    border-radius: 5px;
    padding: 16px;
    margin: 32px;
}

.file-container-indent {
    margin: 0 32px 32px calc(50px + 0.5em);
}

.file-title {
    margin: 0 auto;
}

.file-block-description {
    color: $middark_neutral;
    font-size: 12px;
    margin: 0.25em auto 1.5em auto;

    @include darkMode {
        color: $light_neutral;
    }
}

.file-date-container {
    font-size: 11px;
    font-weight: 700;
    font-style: normal;
    display: inline-block;
    margin: 0.25em auto 0.5em auto;
    margin-right: 1em;
}

.file-date-formatted {
    font-style: italic;
    font-weight: 400;
}

.icon-file-component {
    margin: 0px !important;
    height: 25px;
    width: 30px;
    max-width: 100%;
    margin-top: -2px !important;
}

.file-container-title-with-extension {
    font-size: 13px;
    padding-left: 0px;
}

.binary-block {
    .file-info {
        list-style-type: none;
        margin: 10px 0;
        padding: 0;
        color: $darkest_neutral;
        font-size: 15px;

        li {
            display: inline-block;
            padding: 0;
            line-height: inherit;

            &:before {
                content: "";
                padding: 0;
            }

            &:after {
                content: "|";
                padding: 0 5px 0 8px;
                color: $mid_neutral;
            }

            &:last-child {
                &:after {
                    content: "";
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .far {
            padding: 0 5px;
        }

        .fa-file-word {
            color: #005890;
        }

        .fa-file-excel {
            color: #01723a;
        }
    }

    a {
        &.normal-link-in-reading-mode {
            color: $lightest_neutral;
            background-color: #00B500;
            padding: 7.5px 15px;
            display: inline-block;
            border-radius: 5px;
            margin: auto;
            margin-top: 30px;
            font-size: 14px;
            transition: background-color .2s ease;
            text-decoration: none;
            user-select: none;

            &:hover {
                color: $lightest_neutral;
                cursor:pointer;
            }
        }

        &:hover {
            transition: background-color .2s ease;
        }
    }
}

.toolbar {
    background: $lightest_neutral;
    border-radius: 5px;
    box-shadow: 0 0 20px var(--modal-box-shadow-color);
    margin: -50px 0 0 -108px;
    position: absolute;
    height: 34px;
    //width: 85px;

    @include darkMode {
        background-color: $brandedcolor7;
    }

    &:after {
        bottom: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $lightest_neutral;
        content: "";
        right: 4px;
        position: absolute;
        pointer-events: none;

        @include darkMode {
            border-top-color: $dark_neutral;
        }
    }

    .ift-btn {
        color: $darkest_neutral;
        display: inline-block;
        font-size: 11px;
        margin: 0 .5em 0 0;
        padding: 0 13px 0 5px;
        transition: border 0.2s ease;
        line-height: 20px;

        @include darkMode {
            color: $lightest_neutral;
        }

        &:hover {
            border: 1px solid $middark_cool;
            transition: border 0.2s ease;
        }
    }

    .copy-btn {
        background: url("/images/copy-paste-defualt-bl.svg") no-repeat 100% center;
        background-size: 22px 22px;
        border: none;
        padding-left: 18px;
        height: 35px;
        margin-top: -10px;

        @include darkMode {
            background-image: url("/images/rev-copy-paste-defualt.svg");
            border: none;

            &:hover {
                background-image: url("/images/rev-copy-paste-hover.svg");
            }

            &:active {
                background-image: url("/images/rev-copy-paste-pressed.svg");
            }
        }

        &:hover {
            background-image: url("/images/copy-paste-hover.svg");
        }

        &:active {
            background-image: url("/images/copy-paste-pressed.svg");
        }
    }

    .share-btn {
        background: url("/images/share-default-bl.svg") no-repeat calc(100% - 5px) center;
        background-size: 22px 22px;
        border: none;
        padding-left: 25px;
        height: 35px;

        @include darkMode {
            background-image: url("/images/rev-share-icon-default.svg");
            border: none;

            &:hover {
                background-image: url("/images/rev-share-icon-hover.svg");
            }

            &:active {
                background-image: url("/images/rev-share-icon-pressed.svg");
            }
        }

        &:hover {
            background-image: url("/images/share-hover.svg");
        }

        &:active {
            background-image: url("/images/share-pressed.svg");
        }
    }

    .annotation-btn {
        background: url("/images/annotation.svg") no-repeat calc(100% - 5px) center;
        background-size: 22px 22px;
        border: none;
        padding-left: 25px;
        height: 35px;

        @include darkMode {
            background-image: url(/images/annotation_darkmode.svg);
            border: none;

            &:hover {
                background-image: url(/images/annotation_hover_darkmode.svg);
            }

            &:active {
                background-image: url(/images/annotation_pressed_darkmode.svg);
            }
        }

        &:hover {
            background-image: url("/images/annotation-hover.svg");
        }

        &:active {
            background-image: url("/images/annotation-pressed.svg");
        }
    }
    .annotation-btn-greyout {
        background: url("/images/annotation.svg") no-repeat calc(100% - 5px) center;
        background-size: 22px 22px;
        border: none;
        padding-left: 25px;
        height: 35px;
        opacity: 0.5;
        //pointer-events: none;
        @include darkMode {
            background-image: url(/images/annotation_darkmode.svg);
            border: none;

            &:hover {
                background-image: url(/images/annotation_hover_darkmode.svg);
            }

            &:active {
                background-image: url(/images/annotation_pressed_darkmode.svg);
            }
        }

        &:hover {
            background-image: url("/images/annotation-hover.svg");
        }

        &:active {
            background-image: url("/images/annotation-pressed.svg");
        }
    }
    .toolbar-close {
        background: var(--icon-close) no-repeat center center;
        background-size: 18px 18px;
        border: none;
        padding: 12px;
        text-indent: -9999px;
        margin-right: 0;
        width: 13px;
        height: 34px;
        position: relative;
        bottom: 0;

        &:hover {
            transform: rotate(90deg);
            transition: transform 0.2s ease;
        }

        @include darkMode {
            background-image: url("/images/rev-close-default.svg");
            border: none;

            &:hover {
                background-image: url("/images/rev-close-hover.svg");
            }

            &:active {
                background-image: url("/images/rev-close-pressed.svg");
            }
        }
    }
}

.modal {
    padding: 0 0 1em 0;
    width: 20%;
    background: $lightest_neutral;
    box-shadow: 0 0 30px var(--modal-box-shadow-color);
    border-radius: 5px;
    height: auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;

    @include darkMode {
        background: $brandedcolor7;
    }

    .modal-header {
        background: $color7;
        border-bottom: 1px solid var(--main-border-color);
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: .5em .5em .5em 1em;
        font-weight: 300;

        p {
            font-size: 1.17em;
            margin: 0;
        }

        @include darkMode {
            background: $darkest_neutral;
        }
    }

    .modal-close {
        background: var(--icon-close) no-repeat center center;
        background-size: 20px 20px;
        display: block;
        height: 20px;
        position: absolute;
        right: 1em;
        top: 0.75em;
        text-indent: -9999px;
        width: 20px;
        transition: transform 0.2s ease;
        border: none;

        &:hover {
            transform: rotate(90deg);
            transition: transform 0.2s ease;
        }
    }

    .modal-body {
        padding: 1em;
        margin: auto;
        width: 80%;
    }
}

.share-modal {
    padding: 0 0 1em 0;
    width: 50%;
    background: $lightest_neutral;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 7px;
    /*height: auto;*/
    /*min-height: 240px;*/
    max-height:550px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;

    @include darkMode {
        background-color: $brandedcolor7;
    }

    .share-modal-header {
        background: $brandedcolor20;
        border-bottom: 1px solid $brandedcolor1;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
        padding: .5em .5em .5em 1em;

        @include darkMode {
            background-color: $brandedcolor19;
            border-bottom: 1px solid $brandedcolor18;
        }

        p {
            font-size: 1.17em;
            margin: 0;
            overflow-wrap: break-word;
        }
    }

    .share-modal-close {
        background: var(--icon-close) no-repeat center center;
        background-size: 20px 20px;
        display: block;
        height: 20px;
        position: absolute;
        right: 1em;
        top: 0.75em;
        text-indent: -9999px;
        width: 20px;
        transition: transform 0.2s ease;
        border: none;

        &:hover {
            transform: rotate(90deg);
            transition: transform 0.2s ease;
        }
    }

    .share-modal-body {
        margin: 40px 20px;
        min-width: 100%;

        .share-modal-block {
            display: block;
            background: $brandedcolor20;
            border: 1px solid $brandedcolor1;
            border-radius: 5px;
            padding: 0.5em 1em;
            max-height:400px;
            overflow-y:scroll;
            margin: 0 10px 0 5px;
            float: left;
            width: calc(100% - 210px);
            word-break: break-all;

            @include darkMode {
                background-color: $brandedcolor19;
                border: 1px solid $brandedcolor18;
            }

            p {
                margin: 0;

            }

            .share-modal-block-ra{
                p {
                    font-size: 14.66px;
                }
            }
        }

        .share-modal-buttons {
            display: block;
            height: 35px;
            border: 1px solid $color1;
            margin: 0 5px;
            border-radius: 5px;
            background: var(--theme-bg);
            cursor: pointer;
            padding: 0.5em;
            transition: border 0.2s ease, background-color 0.2s ease;
            float: left;
            overflow-x: auto;
            min-width: 50px;
            text-align: center;

            &:hover {
                background: $middark_cool;
                color: $lightest_neutral;
                transition: border 0.2s ease, background-color 0.2s ease;
            }
        }

        .share-modal-button-with-image {
            background: var(--theme-bg) url("/images/icon-mail-bk.svg") no-repeat center center;
            display: block;
            width: 50px;
            height: 35px;
            border: 1px solid #00B500;
            margin: 0px 5px;
            border-radius: 5px;
            cursor: pointer;
            padding: 0.5em 1em;
            transition: all 0.2s ease;
            float: left;
            overflow-x: auto;

            @include darkMode {
                background-image: url("/images/icon-mail-wh.svg");
            }

            &:hover {
                background: #0097A9 url("/images/icon-mail-wh.svg") no-repeat center center;
            }
        }
    }

    .share-modal-footer {
        width: 100%;

        .share-modal-success {
            display: flex;
            color: $lightest_neutral;
            margin: 20px 25px 10px 25px;
            height: 25px;
            float: left;
            background: black url("/images/icon-check-circle-gn.svg") no-repeat calc(100% - 10px) center;
            padding: 0.25em 1em;
            border-radius: 5px;
            width: calc(100% - 80px);

            @include darkMode {
                color: $light_neutral;
            }

            p {
                margin: auto 0px;
                color: $lightest_neutral;
            }
        }
    }
}
.articlepub-modal {
    //padding: 0 0 1em 0;
    width: 50%;
    background: $lightest_neutral;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 7px;
    height: auto;
    min-height: 240px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;

    @include darkMode {
        background-color: $brandedcolor7;
    }

    .articlepub-modal-header {
        background: $brandedcolor20;
        border-bottom: 1px solid $brandedcolor1;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
        padding: .5em .5em .5em 1em;

        @include darkMode {
            background-color: $brandedcolor19;
            border-bottom: 1px solid $brandedcolor18;
        }

        p {
            font-size: 1.17em;
            margin: 0;
            overflow-wrap: break-word;
        }
    }

    .articlepub-modal-close {
        background: var(--icon-close) no-repeat center center;
        background-size: 20px 20px;
        display: block;
        height: 20px;
        position: absolute;
        right: 1em;
        top: 0.75em;
        text-indent: -9999px;
        width: 20px;
        transition: transform 0.2s ease;
        border: none;

        &:hover {
            transform: rotate(90deg);
            transition: transform 0.2s ease;
        }
    }

    .articlepub-modal-body {
        margin: 40px 20px;
        min-width: 100%;

        .articlepub-modal-block {
            display: block;
            //background: $brandedcolor20;
            border: 1px solid $brandedcolor1;
            border-radius: 5px;
            padding: 0.5em 1em;
            height: auto;
            margin: 0 10px 25px 5px;
            float: left;
            width: calc(100% - 80px);
            word-break: break-all;

            @include darkMode {
                background-color: $brandedcolor19;
                border: 1px solid $brandedcolor18;
            }

            p {
                margin: 0;
            }
        } 
         
    }    
}
.warning {
    background: url("/images/warning_badge.svg") no-repeat center center;
    background-size: 18px 18px;
    display: inline-block;
    width: 18px;
    margin: 0 10px 0 0;
    position: relative;
    bottom: -1px;
}

.redirect-modal {
    padding: 0 0 1em 0;
    width: 28%;
    background: $lightest_neutral;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 7px;
    height: auto;
    min-height: 150px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;

    @include darkMode {
        background-color: $brandedcolor7;
    }

    .btn-redirect-no {
        margin-right: 10px;
    }

    .btn-redirect-yes {
        margin-right: 15px;
    }

    .btn-delete-confirm {
        border-radius: 5px;
        text-transform: unset;
    }

    .redirect-modal-header {
        background: $brandedcolor20;
        border-bottom: 1px solid $brandedcolor1;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
        padding: .5em .5em .5em 1em;

        @include darkMode {
            background-color: $brandedcolor19;
            border-bottom: 1px solid $brandedcolor18;
        }

        p {
            font-size: 1.17em;
            margin: 0;
            overflow-wrap: break-word;
        }
    }

    .redirect-modal-close {
        background: var(--icon-close) no-repeat center center;
        background-size: 20px 20px;
        display: block;
        height: 20px;
        position: absolute;
        right: 1em;
        top: 0.75em;
        text-indent: -9999px;
        width: 20px;
        transition: transform 0.2s ease;
        border: none;

        &:hover {
            transform: rotate(90deg);
            transition: transform 0.2s ease;
        }
    }

    .redirect-modal-body {
        //margin: 40px 20px;
        // min-width: 100%;
        padding: 10px;

        .redirect-modal-block {
            display: block;
            background: $brandedcolor20;
            border: 1px solid $brandedcolor1;
            border-radius: 5px;
            padding: 0.5em 1em;
            height: auto;
            margin: 0 10px 0 5px;
            float: left;
            width: calc(100% - 210px);
            word-break: break-all;

            @include darkMode {
                background-color: $brandedcolor19;
                border: 1px solid $brandedcolor18;
            }

            p {
                margin: 0;
            }
        }

        .align-button {
            margin-right: 0;
        }

        .redirect-modal-buttons {
            display: block;
            height: 35px;
            border: 1px solid $color1;
            margin: 0 5px;
            border-radius: 5px;
            background: var(--theme-bg);
            cursor: pointer;
            padding: 0.5em;
            transition: border 0.2s ease, background-color 0.2s ease;
            float: left;
            overflow-x: auto;
            min-width: 50px;
            text-align: center;

            &:hover {
                background: $middark_cool;
                color: $lightest_neutral;
                transition: border 0.2s ease, background-color 0.2s ease;
            }
        }

        .redirect-modal-button-with-image {
            background: var(--theme-bg) url("/images/icon-mail-bk.svg") no-repeat center center;
            display: block;
            width: 50px;
            height: 35px;
            border: 1px solid #00B500;
            margin: 0px 5px;
            border-radius: 5px;
            cursor: pointer;
            padding: 0.5em 1em;
            transition: all 0.2s ease;
            float: left;
            overflow-x: auto;

            @include darkMode {
                background-image: url("/images/icon-mail-wh.svg");
            }

            &:hover {
                background: #0097A9 url("/images/icon-mail-wh.svg") no-repeat center center;
            }
        }
    }

    .redirect-modal-footer {
        width: 100%;

        .redirect-modal-success {
            display: flex;
            color: $lightest_neutral;
            margin: 20px 25px 10px 25px;
            height: 25px;
            float: left;
            background: black url("/images/icon-check-circle-gn.svg") no-repeat calc(100% - 10px) center;
            padding: 0.25em 1em;
            border-radius: 5px;
            width: calc(100% - 80px);

            @include darkMode {
                color: $light_neutral;
            }

            p {
                margin: auto 0px;
            }
        }
    }
}

.delete-modal {
    padding: 0 0 1em 0;
    width: 34%;
    background: $lightest_neutral;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 7px;
    height: auto;
    min-height: 150px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;

    @include darkMode {
        background-color: $brandedcolor7;
    }

    .btn-delete-no {
        margin-right: 10px;
    }

    .btn-delete-yes {
        margin-right: 15px;
    }

    .btn-delete-confirm {
        border-radius: 5px;
        text-transform: unset;
    }

    .delete-modal-div {
        display: flex;
        gap: 5px;
    }

    .delete-icon {
        width: 16px;
        cursor: none;
    }

    .delete-modal-header {
        background: $brandedcolor20;
        border-bottom: 1px solid $brandedcolor1;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
        padding: .5em .5em .5em 1em;

        @include darkMode {
            background-color: $brandedcolor19;
            border-bottom: 1px solid $brandedcolor18;
        }

        p {
            font-size: 1.17em;
            margin: 0;
            overflow-wrap: break-word;
        }
    }

    .delete-container {
        margin-top:10px;
    }

    .close-message {
        margin-top: 10px;
    }

    .delete-cancel {
        cursor: pointer;
        text-decoration: underline;
    }

    .delete-close-container {
        margin-top: 8px;
    }

    .delete-close {
        padding-right: 18px;
        cursor: pointer;
    }

    .delete-modal-close {
        background: var(--icon-close) no-repeat center center;
        background-size: 20px 20px;
        display: block;
        height: 20px;
        position: absolute;
        right: 1em;
        top: 0.75em;
        text-indent: -9999px;
        width: 20px;
        transition: transform 0.2s ease;
        border: none;

        &:hover {
            transform: rotate(90deg);
            transition: transform 0.2s ease;
        }
    }

    .delete-modal-body {
        //margin: 40px 20px;
        // min-width: 100%;
        padding: 10px;

        .delete-modal-block {
            display: block;
            background: $brandedcolor20;
            border: 1px solid $brandedcolor1;
            border-radius: 5px;
            padding: 0.5em 1em;
            height: auto;
            margin: 0 10px 0 5px;
            float: left;
            width: calc(100% - 210px);
            word-break: break-all;

            @include darkMode {
                background-color: $brandedcolor19;
                border: 1px solid $brandedcolor18;
            }

            p {
                margin: 0;
            }
        }

        .align-button {
            margin-right: 0;
        }

        .delete-modal-buttons {
            display: block;
            height: 35px;
            border: 1px solid $color1;
            margin: 0 5px;
            border-radius: 5px;
            background: var(--theme-bg);
            cursor: pointer;
            padding: 0.5em;
            transition: border 0.2s ease, background-color 0.2s ease;
            float: left;
            overflow-x: auto;
            min-width: 50px;
            text-align: center;

            &:hover {
                background: $middark_cool;
                color: $lightest_neutral;
                transition: border 0.2s ease, background-color 0.2s ease;
            }
        }

        .delete-modal-button-with-image {
            background: var(--theme-bg) url("/images/icon-mail-bk.svg") no-repeat center center;
            display: block;
            width: 50px;
            height: 35px;
            border: 1px solid #00B500;
            margin: 0px 5px;
            border-radius: 5px;
            cursor: pointer;
            padding: 0.5em 1em;
            transition: all 0.2s ease;
            float: left;
            overflow-x: auto;

            @include darkMode {
                background-image: url("/images/icon-mail-wh.svg");
            }

            &:hover {
                background: #0097A9 url("/images/icon-mail-wh.svg") no-repeat center center;
            }
        }
    }

    .delete-modal-footer {
        width: 100%;

        .delete-modal-success {
            display: flex;
            color: $lightest_neutral;
            margin: 20px 25px 10px 25px;
            height: 25px;
            float: left;
            background: black url("/images/icon-check-circle-gn.svg") no-repeat calc(100% - 10px) center;
            padding: 0.25em 1em;
            border-radius: 5px;
            width: calc(100% - 80px);

            @include darkMode {
                color: $light_neutral;
            }

            p {
                margin: auto 0px;
            }
        }
    }
}

.badge-section-wrapper {
    margin-left: auto;
}

.search-badge {
    background: $color13 url(/images/icon-oval-wh.svg) no-repeat 2px 4px;
    background-size: 8px 8px;
    border-radius: 100px;
    border: 1px solid $color13;
    display: inline-block;
    font-weight: bold;
    font-size: 10px;
    right: 2.5em;
    text-align: right;
    padding: 2px 0.75em 2px 2px;
    width: 40px;
    min-width: 40px;
    transition: all 0.2s ease;
    margin: 3px 0 0 5px;
    height: 18px;
    color: $lightest_neutral;
}

.annotation-badge {
    border-radius: 100px;
    display: inline-block;
    font-weight: bold;
    font-size: 10px;
    right: 2.5em;
    text-align: center;
    padding: 2px 4px 2px 2px;
    min-width: 30px;
    transition: all 0.2s ease;
    margin: 3px 5px 0 5px;
    height: 18px;
    color: #212121;
    border: none;

    @include darkMode {
        color: #212121;
    }
}

.search-badge-active {
    background: $color1 url(/images/icon-oval-wh.svg) no-repeat calc(100% - 2px) 4px;
    background-size: 8px 8px;
    border-radius: 100px;
    border: 1px solid $color1;
    display: inline-block;
    font-weight: bold;
    font-size: 10px;
    right: 2.5em;
    text-align: left;
    padding: 2px 2px 2px 0.75em;
    width: 40px;
    min-width: 40px;
    transition: all 0.2s ease;
    margin: 3px 0 0 5px;
    height: 18px;
    color: $lightest_neutral;
}

.search-badge-article {
    background-color: $color1;
    color: white;
    font-size: 7px;
    font-weight: 700;
    line-height: 7px;
    border-radius: 1rem;
    height: 17px;
    min-width: 17px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 3px 0 0 5px;
}

.search-dot {
    background-color: $color1;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin: 7px 0 0 0;
    position: absolute;
    left: 99.5%;
}

.search-filters {
    background-color: var(--search-filters-bg-color);
    border: 1px solid var(--search-filters-border-color);
    border-radius: 5px;
    display: block;
    padding: 15px 1em 5px 1em;
    position: relative;
    margin-bottom: 15px;

    .search-filters-close {
        position: absolute;
        top: 14px;
        right: 16px;
    }
}

.search-filter-badge {
    background: var(--search-filter-badge-bg-color) var(--icon-close) no-repeat calc(100% - 6px) center;
    background-size: 14px 14px;
    border-radius: 5px;
    border: 1px solid #0097A9;
    display: inline-block;
    color: black;
    font-size: 11px;
    padding-right: 25px;
    text-decoration: none;
    transition: all 0.2s ease;
    margin: auto 5px 10px 5px;

    &:hover {
        border: 1px solid var(--search-filter-badge-border-color-hover);
        transition: all 0.2s ease;

        span {
            border-right: 1px solid var(--search-filter-badge-border-color-hover);
            transition: all 0.2s ease;
        }
    }

    span {
        border-right: 1px solid #0097A9;
        display: inline-block;
        padding: 0.25em 0.5em;
        height: 100%;
        transition: all 0.2s ease;
    }
}

.article-container-wide-mode {
    width: 100%;
    margin: 0 auto;

    .article-container-inner-for-pdf {
        width: 75%;
        padding: 0 0 0 90px;
        display: block;
    }
}
.wide-margin {
    margin-left: 120px;
}
.article-annotation {
    margin: 5px;
    width: 50%;
    }

.article-container-wide-annotation-on {
    width: 77.5%;
    margin: 0;
}

.article-container-inner-for-pdf {
    width: 100%;
}

.article-toc, .article-tag {
    background: white;
    max-width: 450px;
    width: 400px;
    display: block;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px #ccc;
    position: absolute;
    padding: 0;
    z-index: 7;
    margin-left: -1px;
    top: -6px;

    @include darkMode {
        background-color: $dark_mode_bg;
        box-shadow: 0px 0px 5px #111;
        border-color: $dark_mode_border;
    }
}

.article-tag {
    top: 32px;
    border-radius: 15px;
    border: 0;
    width: 350px;
    right: 0;
}

.article-toc a, .article-tag a,
.in-article-toc-reading-mode a {
    font-size: 14px;

    &:hover {
        text-decoration: underline;

        @include darkMode {
            color: $lightest_neutral;
        }
    }
}

.article-tag-content {
    padding: 10px 15px 5px;
    overflow-y: scroll;
    max-height: 350px;

    &::-webkit-scrollbar {
        width: 8px;
        background: #ffffff;

        @include darkMode {
            background: #212121;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
        border-radius: 4px;

        @include darkMode {
            background: rgba(255, 255, 255, 0.54);
        }
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;

        @include darkMode {
            background: #212121;
        }
    }

    h3 {
        font-size: 18px;
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 6px;
    }

    span {
        margin-right: 10px;
        padding: 5px 10px;
        border-radius: 12px;
        background-color: $brandedcolor30;
        font-size: 0.8rem;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;

        @include darkMode {
            color: $darkest_neutral;
        }
    }
}

.article-tag-content>div:not(:last-child) {
    padding-bottom: 5px
}

.article-jump-links {
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 20px;
    overflow-y: auto;
    list-style-type: none;
    max-height: 350px;
}

.article-jump-links-sub {
    margin: 0px !important;
    list-style: none;
}
.article-jump-links-sub > div > li {
    padding-bottom: 5px;
    padding-top: 5px;
}

.article-jump-links::-webkit-scrollbar,
.footnote-popup-text-container::-webkit-scrollbar {
    width: 8px;
}

.article-jump-links::-webkit-scrollbar-thumb,
.footnote-popup-text-container::-webkit-scrollbar-thumb {
    background: $brandedcolor10;
    border-radius: 4px;

    @include darkMode {
        background: $brandedcolor26;
    }
}

.footnote-popup-text-container a {
    color: #0076a8;
    text-decoration: underline;
}

.jumplink-container-main {
    padding-bottom: 0;
}

.article-jump-links-main {
    padding: 10px;
    padding-bottom: 0;
}

.article-jump-links-sub a {
    font-size: 12px;
}

.icon-article-toc, .icon-article-tag {
    background-color: transparent;
    background-image: var(--icon-in-article-toc);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 32px 32px;
    width: 32px;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 0;
    padding-top: 0px;
    padding-left: 15px;
    cursor: pointer;
    border: none;

    @include darkMode {
        background-image: url(/images/rev-toc-default.svg);
    }
}

.icon-article-toc:hover {
    background-image: url(/images/toc-hover.svg);
    background-repeat: no-repeat;
    background-position: center center;

    @include darkMode {
        background-image: url(/images/rev-toc-hover.svg);
    }
}

.icon-article-toc:active {
    background-image: url(/images/toc-pressed.svg);
    background-repeat: no-repeat;
    background-position: center center;

    @include darkMode {
        background-image: url(/images/rev-toc-pressed.svg);
    }
}

.icon-article-toc-open {
    background-image: url(/images/toc-hover.svg) !important;
    background-repeat: no-repeat;
    background-position: center center;
    border: none;


    &:active {
        background-image: url(/images/toc-pressed.svg) !important;
        border: none;
    }

    @include darkMode {
        background-image: url(/images/rev-toc-default.svg) !important;
        border: none;

        &:hover {
            background-image: url(/images/rev-toc-hover.svg) !important;
            border: none;
        }

        &:active {
            background-image: url(/images/rev-toc-pressed.svg) !important;
            border: none;
        }
    }
}

.article-title-div {
    display: inline;
}


.article-tag-fixed {
    display: inline;
    margin-left: 15px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.inArticle-tag-fixed {
    margin-right: 30px;
    margin-top: auto;
    margin-bottom: auto;
}

.icon-article-tag {
    background-image: url(/images/tag-default.svg);
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center center;

    @include darkMode {
        background-image: url(/images/tag-default.svg);
    }

    &:hover {
        background-image: url(/images/tag-hover.svg);

        @include darkMode {
            background-image: url(/images/tag-hover.svg);
        }
    }

    &:active {
        background-image: url(/images/tag-hover.svg);

        @include darkMode {
            background-image: url(/images/tag-hover.svg);
        }
    }
}

.icon-article-tag-open {
    background-image: url(/images/tag-hover.svg);

    @include darkMode {
        background-image: url(/images/tag-hover.svg);
    }
}

.btn-compare {
    border-radius: 5px;
    border: 1px solid #ccc;
    color: black;
    font-size: 12px;
    background-color: transparent;
    font-size: 12px;
    padding: 2px 10px;
    text-decoration: none;
    transition: border 0.2s linear;
    align-self: flex-start;
    margin-top: 4px;
    background-color: transparent;
    background-image: url(/images/icon-compare-bk.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 24px 24px;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: black;
    font-size: 12px;
    padding: 0.5em;
    padding-right: 40px;
    padding-left: 10px;
    right: 60px;
    top: 15px;
    text-decoration: none;
    transition: border 0.2s linear;
}

.btn-compare:hover {
    border-color: $middark_cool;
}

.next-prev-counter {
    padding: 0 .5rem;
    border-right: 1px solid $brandedcolor1;
    color: $darkest_neutral;

    @include darkMode {
        color: $lightest_neutral;
        border-right: 1px solid $color13;
    }
}

.next-prev-btns {
    flex-grow: 0;
    padding: 0;
    border-right: 1px solid $brandedcolor1;
    max-height: 38px;

    @include darkMode {
        border-right: 1px solid $color13;
    }

    button {
        flex-basis: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background-color: transparent;
        border: 0 none;
        cursor: pointer;
        text-decoration: none;

        &:disabled {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
        }
    }

    .next-prev-down {
        text-decoration: none;

        img {
            transform: rotate(90deg);
        }
    }

    .next-prev-up {
        text-decoration: none;

        img {
            transform: rotate(-90deg);
        }
    }
}

.next-prev-more {
    font-weight: bold;
    font-size: .8rem;
}

.allresults {
    background-color: var(--allresults);
}

.article-search-container {
    position: absolute;
    z-index: 1;
    margin-left: auto;
    padding: 2px;
    overflow: hidden;
    border-radius: 2px;
    width: 40px;
    top: 155px;
    right: calc(1% - 6px);
    transition: width 0s linear;
}

.article-search-container-expanded {
    width: 425px;
    transition: width 0.2s linear;

    & .article-search-border {
        border: 1px solid #00a3e0;
    }

    &:focus-within .article-search-border {
        border: 1px solid $brandedcolor5;
        border-radius: 2px;

        @include darkMode {
            border: 1px solid $brandedcolor8;
        }
    }
}

.article-search-container-fixed {
    position: fixed;
    z-index: 10;

    .allresults {
        background-color: var(--allresults-fixed);
    }
}
.icon-close-x {
    margin: auto 5px;
}

.searcbar-container-on-article-page {
    top: 10px;
}

.dashboard-search {
    top: 0;
    right: calc(2% + -25.5px);
}

.annotation-on {
    display: none;
}

.annot-main {
    display: flex;
    margin-left: -95px;
}

.btn-search-icon-article {
    margin: 0;
    font-size: 14px;
    border-radius: 2px;
    transition: border 0.2s ease;
    color: $darkest_neutral;
    background-color: $lightest_neutral;
    border-radius: 50px;

    .search-click {
        border: none;
        background: unset;
    }

    .btn-search-click {
        height: 38px;
    }

    @include darkMode {
        background-color: $brandedcolor7;
    }

    .article-search-container-expanded & {
        border-radius: 0;

        @include darkMode {
            background-color: $brandedcolor7;
            border: 1px solid hsl(0, 0%, 80%);
        }
    }
}

.search-click img[alt="Search"] {
    //scaling to match 38*38 ration
    transform: scale(1.35);
}

.image-search-icon {
    margin: auto 5px auto 0;
}

.search-click img[alt="Search"]:hover {
    content: url("/images/search-hover.svg");

    @include darkMode {
        content: url("/images/dm-search-hover.svg")
    }
}

.search-click img[alt="Search"]:active {
    content: url("/images/search-pressed.svg");

    @include darkMode {
        content: url("/images/dm-search-pressed.svg")
    }
}

.article-search-container-expanded div div div .search-click img[alt="Search"], .article-search-container-expanded div div div .search-click img[alt="Search"]:active, .article-search-container-expanded div div div .search-click img[alt="Search"]:hover {
    content: url("/images/icon-search-bl.svg");
    transform: scale(1);
}

.input-search-article {
    border: none;
    margin: 0;
    color: $darkest_neutral;
    height: 34px;

    @include darkMode {
        background-color: inherit;
        color: $lightest_neutral;
    }

    &::placeholder {
        color: $brandedcolor22;
    }

    &:focus-visible {
        outline: none !important;
    }
}

.reading-mode-container {
    font-size: 10.4px;
    margin: auto 10px;
    color: #262626;
}


.reading-mode-btn {
    background-color: transparent;
    display: flex;
    top: 245px;
    right: calc(2% + 17px);
    height: 40px;
    padding: 0;
    margin: 0;
    margin-bottom: 13.6px;
    z-index: 2;
}

.reading-mode-toggle {
    background: url(/images/icon-toggle-off.svg) no-repeat center center;
    background-size: 24px 24px;
    display: block;
    text-indent: -9999px;
    width: 40px;
    border: none !important;
}

.reading-mode-toggle:hover {
    background: url(/images/icon-toggle-off-bl.svg) no-repeat center center;
    background-size: 24px 24px;
}

.reading-mode-toggle-on {
    background: url(/images/icon-toggle-on.svg) no-repeat center center !important;
    background-size: 24px 24px;

    @include darkMode {
        background: url(/images/icon-toggle-off-bl.svg) no-repeat center center;
    }
}

.scrollbar-horizontal-container {
    width: 100vw;
    height: 8px;
    position: fixed;
    box-sizing: border-box;
    z-index: 4;
    left: 0;
    background-color: $brandedcolor18;

    &.fixed{
        z-index: 6;
    }
}

.scrollbar-horizontal {
    height: 100%;
    width: 100%;
    background: $brandedcolor8;
    transform-origin: left;
}

.tools-container {
    height: 70vh;
    justify-content: center;
    align-items: center;
    order: 2;
    display: flex;
    position: sticky;
    top: 50px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
    gap: 13.6px;
    width: 38px;
}

.icon-article {
    border-radius: 5px;
    border: 1px solid black;
    border-radius: 50px;
    transition: all 0.2s ease;
    height: 30px;
    width: 30px;
    padding: 3.4px;
    cursor: pointer;

    @include darkMode {
        border-color: white;
    }
}

.icon-compare-versions {
    background: url(/images/compare_article-default.svg) no-repeat center center;
    background-size: 38px 38px;
    border: none;
    height: 38px;
    width: 38px;

    &:hover {
        background: url(/images/compare_article-hover.svg) no-repeat center center;
        background-size: 38px 38px;
    }

    &:active {
        background: url(/images/compare_article-pressed.svg) no-repeat center center;
        background-size: 38px 38px;
    }

    @include darkMode {
        background-image: url(/images/rev-compare_article-default.svg);
        background-size: 38px 38px;

        &:hover {
            background: url(/images/rev-compare_article-hover.svg) no-repeat center center;
            background-size: 38px 38px;
        }

        &:active {
            background: url(/images/rev-compare_article-pressed.svg) no-repeat center center;
            background-size: 38px 38px;
        }
    }
}

.icon-article:hover {
    border-color: $middark_cool;
}

.icon-download {
    background: url(/images/download-default.svg) no-repeat 0 center;
    background-size: 38px 38px;
    margin-bottom: 13.6px;
    border: none;
    height: 38px;
    width: 38px;
    display: block;

    &:hover {
        background: url(/images/download-hover.svg) no-repeat 0 center;
        background-size: 38px 38px;
    }

    &:active {
        background: url(/images/download-pressed.svg) no-repeat 0 center;
        background-size: 38px 38px;
    }

    @include darkMode {
        background: url(/images/rev-download-icon-default.svg) no-repeat 0 center;
        background-size: 38px 38px;

        &:hover {
            background: url(/images/rev-download-icon-hover.svg) no-repeat 0 center;
            background-size: 38px 38px;
        }

        &:active {
            background: url(/images/rev-download-icon-pressed.svg) no-repeat 0 center;
            background-size: 38px 38px;
        }
    }
}

.icon-bookmark {
    background: url(/images/bookmark.svg) no-repeat 0 center;
    background-size: 38px 38px;
    margin-bottom: 13.6px;
    border: none;
    height: 38px;
    width: 38px;
    display: block;

    &:hover {
        background: url(/images/bookmark_hover.svg) no-repeat 0 center;
        background-size: 38px 38px;
    }

    &:active {
        background: url(/images/bookmark_hover.svg) no-repeat 0 center;
        background-size: 38px 38px;
    }

    @include darkMode {
        background: url(/images/bookmark_dkmode.svg) no-repeat 0 center;
        background-size: 38px 38px;


        &:hover {
            background: url(/images/bookmark_dkmode_hover.svg) no-repeat 0 center;
            background-size: 38px 38px;
        }

        &:active {
            background: url(/images/bookmark_dkmode_hover.svg) no-repeat 0 center;
            background-size: 38px 38px;
        }
    }
}

.icon-share {
    background: transparent url(/images/share-default-bl.svg) no-repeat center center;
    background-size: 38px 38px;
    border: none;
    height: 38px;
    width: 38px;
    display: block;
    margin-bottom: 13.6px;

    &:hover {
        background: url(/images/share-hover.svg) no-repeat center center;
        background-size: 38px 38px;
    }

    &:active {
        background: url(/images/share-pressed.svg) no-repeat center center;
        background-size: 38px 38px;
    }

    @include darkMode {
        background-image: url(/images/rev-share-icon-default.svg);
        background-size: 38px 38px;

        &:hover {
            background: url(/images/rev-share-icon-hover.svg) no-repeat center center;
            background-size: 38px 38px;
        }

        &:active {
            background: url(/images/rev-share-icon-pressed.svg) no-repeat center center;
            background-size: 38px 38px;
        }
    }
}


.icon-annotation {
    background: transparent url(/images/annotation.svg) no-repeat center center;
    background-size: 38px 38px;
    border: none;
    height: 38px;
    width: 38px;

    &:hover {
        background: url(/images/annotation-hover.svg) no-repeat center center;
        background-size: 38px 38px;
    }

    &:active {
        background: url(/images/annotation-pressed.svg) no-repeat center center;
        background-size: 38px 38px;
    }

    @include darkMode {
        background-image: url(/images/annotation_darkmode.svg);
        background-size: 38px 38px;

        &:hover {
            background: url(/images/annotation_hover_darkmode.svg) no-repeat center center;
            background-size: 38px 38px;
        }

        &:active {
            background: url(/images/annotation_pressed_darkmode.svg) no-repeat center center;
            background-size: 38px 38px;
        }
    }
}

.selected {
    background: transparent url(/images/annotation-pressed.svg) no-repeat center center;
    background-size: 38px 38px;
    border: none;
    height: 38px;
    width: 38px;
    margin-top: 10px;

    @include darkMode {
        background: url(/images/annotation-pressed-darkmode.svg) no-repeat center center;
        background-size: 38px 38px;
    }
}

.fixed-tools-container-bottom {
    position: fixed;
    align-items: center;
    top: calc(100% - 50px);
    transform: translateY(-50%);
    margin-left: auto;
    z-index: 3;
}

.sticky-tools {
    right: calc(1% + 15px);
    position: fixed;
}

.icon-scroll-to-top {
    height: 58px;
    width: 58px;
    top: 90%;
    background: transparent url(/images/top_of_article-default.svg) no-repeat center center;
    border: none;

    @include darkMode {
        background: transparent url(/images/top_of_article-default-dark.svg) no-repeat center center;
    }
}

.icon-scroll-to-top:hover {
    width: 58px;
    height: 58px;
    background: transparent url(/images/top_of_article-hover.svg) no-repeat center center;
    cursor: pointer;

    @include darkMode {
        background: transparent url(/images/top_of_article-hover-dark.svg) no-repeat center center;
    }
}

.icon-scroll-to-top:active {
    width: 58px;
    height: 58px;
    background: transparent url(/images/top_of_article-pressed.svg) no-repeat center center;
    cursor: pointer;

    @include darkMode {
        background: transparent url(/images/top_of_article-pressed-dark.svg) no-repeat center center;
    }
}

.icon-scroll-top-label {
    font-size: 0.53em;
    font-weight: 600;
    color: #757575;
    padding-left: 2px;

    @include darkMode {
        color: #A3A3A3;
    }
}

.fixed-nav-article {
    position: fixed;
    width: 100%;
    background: $lightest_neutral;
    border-bottom: 1px solid $brandedcolor1;
    z-index: 5;
    padding-top: 8px;
    left: 0;

    @include darkMode {
        background: black;
        border: none !important;
    }

    .fixed-nav-article-main {
        width: 96%;
    }

    .fixed-nav-article-main-wrapper {
        width: 26.8%;
    }


}

.article-text-and-title-container {
    width: 69%;
    margin-left: auto;
    display: flex;
    position: relative;
    padding-top: 6px;
    padding-bottom: 5px;
}

.article-text-and-title-container-wide-mode {
    width: 80%;
    margin: auto;
}

.article-content-container {
    padding: 10px 0 0 42px;
    box-sizing: border-box;

    a {
        color: $brandedcolor5;
        text-decoration: underline;

        @include darkMode {
            color: $brandedcolor8;
        }

        &:hover {
            color: $brandedcolor2;

            @include darkMode {
                color: $brandedcolor9;
            }
        }

        &:active {
            color: $brandedcolor4;

            @include darkMode {
                color: $brandedcolor15;
            }
        }
    }
    .remove-link-style{
        color: #000 !important;
        text-decoration: none !important;
        @include darkMode {
            color: $brandedcolor8;
        }

        &:hover {
            color: #000 !important;
            @include darkMode {
                color: $brandedcolor9;
            }
        }
    }
}

.article-content-container-pdf {
    padding: 0;
}
.article-content-container-annotation {
    padding: 20px 40px 0 70px;
}

.article-title {
    border: none;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 27px;
    padding: 0 24px 0 10px;
    flex-grow: 1;
    text-align: left;
}

.article-title-fixed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
}

.article-title-fixed span {
    display: block;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0;
    width: 100%;
}

.article-toc-header-container, .article-tag-header-container {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid $brandedcolor1;
    display: flex;
    background: $brandedcolor16;
    padding-top: 5px;
    padding-bottom: 5px;

    @include darkMode {
        background-color: $brandedcolor7;
    }
}

.article-toc-header-text, .article-tag-header-text {
    font-size: 20px;
    font-weight: 400;
    padding-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    flex-grow: 1;
}

.article-tag-header-text {
    font-weight: 500;
    padding: 2px 0px 0px 10px;
}

.title-indexed-component {
    font-size: 13px;
    display: inline-block;
    margin-right: 8px;
}

.article-copyright {
    @include darkMode {
        color: $middark_neutral;
    }
}

.prev-next-article-links-container {
    justify-content: space-between;
    margin: 70px 0em 35px 0em;
    font-size: 14px;
    user-select: none;

    .prev-next-text {
        font-size: 14px;
        font-weight: 500;
        color: $brandedcolor5;
        text-transform: uppercase;
        text-decoration: none;
        border-radius: 2px;
        border: 1px solid transparent;

        @include darkMode {
            color: $lightest_neutral;
        }

        &:hover {
            color: $brandedcolor2;
            background-color: $brandedcolor3;

            @include darkMode {
                border: 1px solid $brandedcolor23;
                background-color: $brandedcolor25;
                color: $brandedcolor23;
            }
        }

        &:active {
            color: $brandedcolor4;
            background-color: $brandedcolor10;

            @include darkMode {
                background-color: $brandedcolor24;
            }
        }
    }

    .next-text {
        background-image: url(/images/pointer_default-right.svg);
        background-position: calc(100% - 10px) center;
        background-repeat: no-repeat;
        background-size: 15px 15px;
        padding: 10px 30px 10px 10px;

        @include darkMode {
            background-image: url(/images/dm_default-pointer_right.svg);
        }

        &:hover {
            background-image: url(/images/pointer_hover-right.svg);

            @include darkMode {
                background-image: url(/images/hover_pressed-pointer_right.svg);
            }
        }

        &:active {
            background-image: url(/images/pointer_pressed-right.svg);

            @include darkMode {
                background-image: url(/images/hover_pressed-pointer_right.svg);
            }
        }
    }

    .prev-text {
        background-image: url(/images/pointer_default-left.svg);
        background-position: 10px center;
        background-repeat: no-repeat;
        background-size: 15px 15px;
        padding: 10px 10px 10px 30px;

        @include darkMode {
            background-image: url(/images/dm_default-pointer_left.svg);
        }

        &:hover {
            background-image: url(/images/pointer_hover-left.svg);

            @include darkMode {
                background-image: url(/images/hover_pressed-pointer_left.svg);
            }
        }

        &:active {
            background-image: url(/images/pointer_pressed-left.svg);

            @include darkMode {
                background-image: url(/images/hover_pressed-pointer_left.svg);
            }
        }
    }


    .article-container-inner-for-pdf & {
        margin-left: 0;
        margin-right: 0;
    }
}

.prev-next-article-links-container-reading-mode-pdf {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: calc(100% - 80px);
}

.auto-translate-notice {
    color: $darkest_neutral;
    background-color: var(--auto-translate-notice-bg-color);
    border-radius: 5px;
    font-size: 13px;
    padding: 3px 5px;
    display: inline-block;
    margin-bottom: 10px;
}


.footnote-click {
    position: relative;
    display: inline-block;
}

#modal-footnote-popup {
    box-shadow: 0 0 20px #c7c7c7;
    border-radius: 5px;
    min-height: 60px;
    background: var(--theme-bg);
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 2;
    position: absolute;

    @include darkMode {
        box-shadow: 0 0 30px var(--modal-box-shadow-color);
    }
}

.footnote-popup-text-container {
    font-size: 13px;
    overflow: auto;
    flex-grow: 1;
    margin: 10px;
    padding: 0 40px 0 15px;
}

.footnote-popup-superscript {
    font-size: 11px;
    margin: 2px 5px 0px 0px;
    font-weight: bold;
    cursor: pointer;
    float: left;
}

.footnote-popup-inner-html .p {
    display: inline !important;
}

.download-panel {
    padding: 0 0 1em 0;
    width: 420px;
    background: var(--theme-bg);
    border: 1px solid var(--main-border-color);
    transition: height 0.01s ease;
    box-shadow: 0 8px 6px -6px var(--modal-box-shadow-color);
    min-height: 240px;
    position: fixed;
    right: 0%;
    z-index: 10;
    top: 87.5px;
    height: calc(100vh - 105px);

    .download-panel-wrapper {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .download-panel-header {
        border-bottom: 1px solid $brandedcolor1;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: .5em .5em .5em 1em;

        @include darkMode {
            border-bottom: 1px solid $color13;
        }

        p {
            font-size: 1.17em;
            margin: 0;
            overflow-wrap: break-word;
        }
    }

    .download-panel-close {
        background: var(--icon-close) no-repeat center center;
        background-size: 20px 20px;
        display: block;
        height: 20px;
        position: absolute;
        right: 1em;
        top: 0.75em;
        text-indent: -9999px;
        width: 20px;
        transition: transform 0.2s ease;
        border: none;

        &:hover {
            transform: rotate(90deg);
            transition: transform 0.2s ease;
        }
    }

    .download-panel-body {
        margin: 18px 12px 5px 10px;
        height: 100%;

        .checkmark-container-row {
            padding-top: 10px;
            margin-bottom: 8px;
            font-size: 13px;
            padding: 0 0 0 25px;
            width: calc(100% - 23px);
            position: relative;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        /* Hide the browser's default radio button */
        .checkmark-container-row input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 7px;
            left: 9px;
            height: 12px;
            width: 12px;
            background-color: $lightest_neutral;
            border-radius: 50%;
            border: 1px solid $midlight_neutral;
        }

        .checkmark-container-row:hover input ~ .checkmark {
            border-color: $brandedcolor5;
        }

        .checkmark-container-row input:checked ~ .checkmark {
            border-color: $brandedcolor5;
        }
        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        /* Show the indicator (dot/circle) when checked */
        .checkmark-container-row input:checked ~ .checkmark:after {
            display: block;
        }
        /* Style the indicator (dot/circle) */
        .checkmark-container-row .checkmark:after {
            top: 1px;
            left: 1px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $brandedcolor5;
        }

        .download-panel-scrollbox {
            display: block;
            color: $midlight_neutral;
            border-top: 1px solid $brandedcolor1;
            padding: 0.5em 0em 0em 0.5em;
            margin: 0px 0px 0px 25px;
            float: left;
            width: 82%;
            word-break: break-all;
            overflow-y: scroll;
            overflow-x: hidden;

            @include darkMode {
                border-top: 1px solid $color13;
            }

            @media(max-height: 550px) {
                height: 20vh;
            }

            .select-article-label {
                display: inline-block;
                margin-bottom: 10px;
            }

            .select-article-label-child {
                display: inline-block;
                margin-bottom: 10px;
                padding-left: 20px;
            }

            .select-article-label-grandchild {
                display: inline-block;
                margin-bottom: 10px;
                padding-left: 40px;
            }
        }

        .download-panel-scrollbox-annotation-include {
            min-height: calc(87vh - 365px);
            max-height: calc(87vh - 365px);
        }

        .download-panel-scrollbox-annatation-selectdocument {
            min-height: calc(67vh - 330px);
            max-height: calc(67vh - 320px);
        }

        .download-panel-scrollbox-selectdocument {
            min-height: calc(100vh - 500px);
            max-height: calc(100vh - 450px);
        }

        .download-panel-scrollbox-active {
            color: black;

            .checkbox-label {
                color: var(--checkbox-label);
            }
        }

        .download-panel-scrollbox-small {
            min-height: calc(43vh - 330px);
            max-height: 80px;
            margin-bottom: 10px;
            /*border-top: none;*/
            /*padding: 0.5em 1em 0.5em 1em;*/
        }


        .download-panel-scrollbox::-webkit-scrollbar {
            width: 6px;
        }

        .download-panel-scrollbox:hover::-webkit-scrollbar-thumb,
        .in-article-toc-reading-mode:hover::-webkit-scrollbar-thumb {
            background: $brandedcolor3;
            border-radius: 4px;

            @include darkMode {
                background: $brandedcolor26;
            }
        }

        .annotation-panel-dropdown-categories-download {
            //margin-top: 15px;
            padding: 0.2em 1em 0.2em 1.2em;
        }

        .include-annotation {
            display: block;
            color: #cccccc;
            padding: 0.2em 1em;
            margin: 0 15px 0 5px;
            float: left;
            width: 82%;
            word-break: break-all;
        }

        .include-annotation-active {
            color: black;

            .checkbox-label {
                color: var(--checkbox-label);
            }
        }
    }

    .download-panel-footer {
        padding-right: 2rem;
        border-top: 1px solid $brandedcolor1;

        @include darkMode {
            border-top: 1px solid $color13;
        }

        .download-panel-label {
            text-align: center;
        }

        .download-panel-button {
            display: block;
            // height: 22px;
            // border: 1px solid $brandedcolor5;
            border-radius: 4px;
            // background-color: $mid_cool;
            //margin: 0px 5px;
            background: $brandedcolor5;
            // color: $lightest_neutral;
            // cursor: pointer;
            // padding: 0.5em 0.5em;
            transition: all 0.2s ease;
            float: right;
            text-align: center;
            margin-top: 15px;
            /*position: fixed;*/
            // right: 100px;
            // bottom: 10px;
            // &:hover {
            //     background: $brandedcolor2;
            //     border: 1px solid $brandedcolor2;
            //     color: $lightest_neutral;
            // }
            // &:active {
            //     background: $brandedcolor4;
            //     border: 1px solid $brandedcolor4;
            //     color: $lightest_neutral;
            // }
            // @include darkMode {
            //     border: 1px solid $brandedcolor8;
            //     background: $brandedcolor8;
            //     color: $brandedcolor4;
            //     &:hover {
            //         background: $brandedcolor25;
            //         border: 1px solid $brandedcolor23;
            //         color: $brandedcolor23;
            //     }
            //     &:active {
            //         background: $brandedcolor24;
            //         border: 1px solid $brandedcolor23;
            //         color: $brandedcolor23;
            //     }
            // }
        }

        .download-panel-button-clicked {
            cursor: not-allowed;
            transition: all 0.2s ease;
            float: right;
            margin-top: 15px;
            color: $middark_neutral;
            background: $brandedcolor3;
            // right: 100px;
            // bottom: 10px;
            // @include darkMode {
            //     border: 1px solid $brandedcolor28;
            //     background: $brandedcolor28;
            //     color: $brandedcolor29;
            // }
        }

        .download-panel-loader {
            display: inline-block;
            border-radius: 5px;
            transition: all 0.2s ease;
            float: right;
            text-align: center;
            margin-right: 32px;
            margin-top: 20px;
        }
    }

    .article-level-select-document {
        padding-left: 26px;
        display: flex;
    }
}

.download-panel-adjust-height {
    top: -86.5px;
    height: calc(100vh - 105px);
}

.download-panel-fixed-adjust-height {
    top: 52px !important;
}

.nonprod-body-container .download-panel-fixed-adjust-height {
    top: 82px !important;
    height: calc(100% - 128px) !important;
}

.nonprod-body-container .download-panel-fixed {
  
    height: calc(100% - 128px) !important;
}

.annotations-panel-fixed-adjust-height {
    top: 51.5px !important;
}

.download-panel-fixed {
    width: 420px;
    background: var(--theme-bg);
    height: calc(100% - 115px);
    min-height: 240px;
    position: fixed;
    right: 0;
    transition: height 0.01s ease;
    z-index: 4;
    //top: 82px;

    .download-panel-fixed,
    .download-panel-scrollbox {
        min-height: calc(100vh - 330px);
        max-height: calc(100vh - 320px);
    }
}

.download-panel:before {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75);
}

.screen-darken-overlay {
    width: 152%;
    height: 210vh;
    right: -3%;
    right: 150px;
    top: -61px;
    z-index: 3;
    background-color: #000000;
    padding: 0;
    margin: 0;
    opacity: 0.7;
    position: absolute;
}

.screen-darken-overlay-adjust-height {
}

.screen-darken-overlay-fixed {
    width: 100%;
    height: 150%;
    transition: all 0.1s ease;
    position: fixed;
    right: 0;
    top: 0;
}

.full-screen-darken-overlay-fixed {
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 7;
    background-color: #000000;
    padding: 0;
    margin: 0;
    opacity: 0.7;
}

.full-screen-overlay-fixed {
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 7;
    padding: 0;
    margin: 0;
    opacity: 0.7;
}

.article-abstract,
.article-additional {
    padding-left: 12px;

    a {
        color: $brandedcolor5;
        text-decoration: underline;
    }
    p,
    ul,
    ol,
    .p {
        font-size: 13px;
        line-height: 1.5em;
        color: $middarkest_neutral;
        margin: 1em 0;

        @include darkMode {
            color: #e8e8e8;
        }
    }
}

.article-notices {
    text-align: center;
    font-size: 12px;
}

.reading-mode-screen-darken-overlay {
    height: 150vh;
    top: 50px;
    right: 0;
}

.reading-mode-download-panel {
    right: 0;
    top: 50px;
    z-index: 4;
    height: calc(99vh - 38px);

    .download-panel-scrollbox {
        min-height: calc(100vh - 280px);
    }
}

.reading-mode-download-panel-fixed {
    top: 47px;

    .download-panel-scrollbox {
        min-height: calc(100vh - 280px);
    }
}

.reading-mode-screen-darken-overlay-fixed {
    top: 47px;
}

a.footnote-link,
h2.article-title {
    box-sizing: border-box;

    a {
        color: $brandedcolor5;
        text-decoration: underline;

        @include darkMode {
            color: $brandedcolor8;
        }

        &:hover {
            color: $brandedcolor2;

            @include darkMode {
                color: $brandedcolor9;
            }
        }

        &:active {
            color: $brandedcolor4;

            @include darkMode {
                color: $brandedcolor15;
            }
        }
    }
}

.double-lines-spinner {
    border: transparent !important;
    background: transparent !important;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    margin-bottom:5px;
}

.double-lines-spinner::after,
.double-lines-spinner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.05);
}

.double-lines-spinner::before {
    border-right: 2px solid #0076A8;
    animation: spin 1s 1s linear infinite;

    @include darkMode {
        border-right: 2px solid #00A3E0;
        border: 2px solid #D9D9D9;
    }
}

.double-lines-spinner::after {
    border-bottom: 2px solid #0076A8;
    animation: spin 1s 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;

    @include darkMode {
        border-right: 2px solid #00A3E0;
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes grow {
    50% {
        width: 100%;
    }

    100% {
        width: 0;
        right: 0;
        left: unset;
    }
}


.article-title-button-container {
    position: relative;

    &:hover div p {
        visibility: visible;
        cursor: pointer;
    }

    div p.closed {
        visibility: visible;
    }

    .collapsed-title {
        border: 1px solid var(--article-section-border-color);
        border-radius: 5px;
        padding: 8px 0;
    }

    .collapsed-title:hover {
        border-color: #0097a9;
    }

    div {
        margin: 0 0 0 -14px;

        p.closed::before {
            content: "";
            display: inline-block;
            height: 50px;
            width: 50px;
            position: absolute;
            top: -4px;
            left: -28px;
            background-image: url(/images/chevron-green-right.svg);
            background-repeat: no-repeat;
            transform: scale(0.3);
            transition: all 0.2s ease;
        }

        p::before {
            content: "";
            display: inline-block;
            height: 50px;
            width: 50px;
            position: absolute;
            top: -6px;
            left: -34px;
            background-image: url(/images/chevron-gray-down.svg);
            background-repeat: no-repeat;
            transform: scale(0.3);
            transition: all 0.2s ease;
        }

        p {
            margin: 0;
            visibility: hidden;
        }
    }


    h2 {
        font-size: 24px;
        margin:21px 0 21px 15px;
    }
}

.article-sub-title-button-container {
    position: relative;
    margin: 0 0 0 0;

    &:hover div p {
        visibility: visible;
        cursor: pointer;
    }

    div p.closed {
        visibility: visible;
    }

    .collapsed-title {
        border: 1px solid var(--article-section-border-color);
        border-radius: 5px;
        padding: 8px 0;
        margin-bottom: 8px;
    }

    .collapsed-title:hover {
        border-color: #0097a9;
    }

    div {
        font-size: 16px;

        p.closed::before {
            content: "";
            display: inline-block;
            height: 50px;
            width: 50px;
            position: absolute;
            top: -5px;
            left: -7px;
            background-image: url(/images/chevron-green-right.svg);
            background-repeat: no-repeat;
            transform: scale(0.3);
            transition: all 0.2s ease;
        }

        p::before {
            content: "";
            display: inline-block;
            height: 50px;
            width: 50px;
            position: absolute;
            top: -10px;
            left: -11px;
            background-image: url(/images/chevron-gray-down.svg);
            background-repeat: no-repeat;
            transform: scale(0.3);
            transition: all 0.2s ease;
        }

        p {
            margin: 0;
            visibility: hidden;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0 0 15px 25px;
        }

        h2 {
            font-size: 20px;
        }

        h3 {
            font-size: 21px;
            margin:21px 0 21px 25px
        }

        h4 {
            font-size: 18px;
            margin:21px 0 21px 25px
        }

        h5,
        h6 {
            font-size: 15px;
            margin:21px 0 21px 25px
        }
    }
}

div.structured-article-section:not(.open) > .file-container {
    display: none;
}

div.structured-article:not(.open) > .file-container {
    display: none;
}

.file-container {
    display: block;
}

.deleted-article-alert {
    display: flex;
    flex-direction: column;
    width: 69%;
    margin-left: auto;
    height: 100%;
}

.subtopicheading {
    font-size: 1em;
    font-weight: 500;
}

details {
    margin-bottom: 8px;
    margin-left: -26px;

    .body {
        margin-left: 26px;
    }

    details {
        margin-left: 30px;
    }
}


details[open] > summary > .subtopicheading {
    position: relative;
    left: -10px;
    width: calc(100% - 40px);
}

details > summary {
    min-height: 37px;
    list-style-type: none;
    display: flex;
    align-items: center;
    margin: 21px 0px 21px 0px
}

details:not([open]) > summary {
    min-height: 37px;
    border: 1px solid var(--article-section-border-color);
    border-radius: 5px;

    &:hover {
        border: 1px solid #0097a9;
    }
}

details > summary::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 0px;
    left: 0px;
    height: 37px;
    width: 25px;
    background-image: url("/images/chevron-green-right.svg");
    background-repeat: no-repeat;
    transform: scale(0.3);
    transition: all 0.2s ease;
    cursor: pointer;
}

details[open] > summary::before {
    content: "";
    display: inline-block;
    visibility: hidden;
    position: relative;
    top: 4px;
    left: -5px;
    width: 35px;
    background-image: url("/images/chevron-gray-down.svg");
    background-repeat: no-repeat;
    transform: scale(0.3);
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
        visibility: visible;
        cursor: pointer;
    }
}

details[open] > summary:hover:before {
    visibility: visible;
    transition: all 0.2s ease;
}

details>summary .subtopicheading {
    font-size: 24px;
    width: calc(100% - 40px);
}

details>details>summary .subtopicheading {
    font-size: 21px;
    width: calc(100% - 40px);
}

details>details>details>summary .subtopicheading {
    font-size: 18px;
    width: calc(100% - 40px);
}
details>details>details>details>summary .subtopicheading {
    font-size: 15px;
    width: calc(100% - 40px);
}

.structured-article-panel .structured-article-subpanel details>summary .subtopicheading {
    font-size: 16px;
}

.structured-article-panel .structured-article-subpanel details>details>summary .subtopicheading {
    font-size: 14px;
}

.structured-article-panel .structured-article-subpanel details>details>details>summary .subtopicheading {
    font-size: 14px;
}
.article-container .structured-article .structured-article-section details {
    margin-left: 0px;
}
.article-container .structured-article .structured-article-section details > details {
    margin-left: 24px;
}
.article-container .structured-article .structured-article-section details > details > details {
    margin-left: 48px;
}

.guidance-phrase {
    font-weight: normal;
}

.category-bookmark {
    width: 30%;
    background: $lightest_neutral;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    position: fixed;
    left: 78%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;

    @include darkMode {
        background-color: $brandedcolor7;
    }
}



li > .indent {
    margin: 1em 0 1em 1em !important;
}

.status-deleted {
    text-decoration: line-through;
    background-color: #e3e3e3;
    color: inherit;
    padding: 0.5em;
}

.box-emphasis {
    border: 1px solid #86bc24;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px 25px;
    margin-top: 16px;
    margin-bottom: 25px;
}

.subtopicheading span {
    width: 100%;
}

.note__title {
    font-weight: bold;
}

.plaintext > .note__title {
    font-weight: normal;
}

.notification {
	p {
		@include darkMode {
			color: #000;
		}
	}
}

.letter-report {
    font-weight: bold;
    margin: 1em 0 1em 4em !important;
}
.article-container .structured-article .structured-article-section .structured-article-section>.article-sub-title-button-container {
    margin-left: 25px;
}
.connecting-dots {
    display: block;
    font-weight: 700;
    font-size: 20px !important;
    color: $mid_cool !important;
}
.connecting-dots-img {
    background: url(/images/connecting-the-dots.svg) no-repeat 0 center;
    background-size: 38px 38px;
    margin-bottom: 13.6px;
    border: none;
    height: 38px;
    width: 38px;
    display: block;
    margin: 0.5em 0;
}

.connecting-dots-main {
    display: flex;
    gap: 10px;
}
.annotation-panel-linkk {
    cursor: pointer;
}
.download-modal{
    width: 350px;
}
// For Technically Speaking
.ts-section .table {
    padding-left: 0px !important;
    display: table;
    margin: inherit;
    tbody tr td {
        vertical-align: middle;
        border-radius: 0px !important;
        border: none;
        padding: 0em;
        @include darkMode {
            background: #212121 !important;
        }
        img {
            margin: 0px !important;
        }
         p, h3, h2, h1
        {
            @include darkMode {
                color: $light_neutral !important;
            }
        }
    }
    .finditfast img, .technical-updates img{
        height: 29px !important;
    }
    .aalerts-practice,
    .aalerts-financial
    {
        img {
            height: 32px !important;
        }
    }
    .questions-comments,
    .technology-issues {
        img {
            height: 41px !important;
        }
    }
}
