body {
    background: $lightest_neutral;
}

.layout-header {
    width: 96%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.layout-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.layout-container-non-prod-env {
    min-height: calc(100vh - 31px);
}

.all-but-header-container {
    flex: 1;

}

.readmode-header-container {
    z-index: 11;
}

.header-inner-container {
    width: 100%;
    #category-dropdown .dd__trigger button,
    .mfdd__list-item,
    .mfdd__card-header span,
    .member-firm-option,
    .dropdown-wrapper,
    .dropdown-wrapper .dropdown__submenu .container,
    .clear-btn  {
        @include darkMode {
            color: #f5f5f5 !important;
        }
    }
}

.catalog-background {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    z-index: -1;
}

.page-title {
    font-size: 24px;
    flex-grow: 1;
    padding-right: 15px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
}

.page-description {
    font-size: 11px;
}

.searchbar {
    background: $search-icon-blue no-repeat calc(0% + 15px) center;
    background-size: 24px 24px;
    border-radius: 2px;
    border: 1px solid $brandedcolor1;
    font-size: 14px;
    margin: auto 0 auto auto;
    padding: 0.75em 0.75em 0.75em 3.5em;
    //min-width: 213.5px;
    color: $darkest_neutral;

    @include darkMode {
        border: 1px solid $color13;
    }

    &::placeholder {
        color: $brandedcolor22;
    }

    &:focus {
        border: 1px solid $brandedcolor5;

        @include darkMode {
            border: 1px solid $brandedcolor8;
        }
    }

    background-color: white;
}

.catalog-container {
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    width: 96%;
    margin: auto;
}

.col {
    width: 48%;
    display: flex;
    flex-direction: column;
}

.catalog-box {
    border: 1px solid var(--catalog-box-border-color);
    border-radius: 7px;
    flex-direction: column;
    display: flex;
    margin: 0 0 32px 0;
    padding: 32px 32px 0px 32px;
    position: relative;
    background-color: var(--catalog-box-bg-color);
    .list-item-link,
    .view-all,
    .collection-menu-option,
    .list-title {
        @include darkMode {
            color: #F5F5F5;
        }
    }
}

.catalog-box-folio {
    padding-bottom: 48px;
}

.catalog-box-top-row {
    margin-bottom: 24px;
}

.catalog-box-icon {
    margin-top: -5px;
    height: 48px;
    width: 48px;

    @include darkMode {
        filter: invert(1);
    }
}

.catalog-box-name {
    font-weight: 500;
    margin: 0;
    margin-bottom: -3px;
    font-size: 16px;
    @include darkMode {
        color: #F5F5F5;
    }
}

.catalog-box-name-big {
    font-size: 18px;
}

.catalog-box-name-big-blue {
    color: blue !important;
}

.catalog-box-description {
    font-weight: 400;
    font-size: 12px;
    margin: 5px 0 0 0;
    @include darkMode {
        color: #F5F5F5;
    }
}

.icon-container {
    display: flex;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
}

.fasb-dropdown-container {
    margin-bottom: 16px;
}

.list {
    margin-top: 1px;
}

.list-container {
    margin-top: 0;
    margin-bottom: 48px;
}

.list-title {
    font-size: 14px;
    margin-top: 28px;
    margin-bottom: 4px;
}

.list {
    padding-left: 25px;
}

.list-item {
    font-size: 16px;
    padding: 0 5px 0 0;
    margin: 0 0 5px 0;
}

.list-item-link {
    font-size: 14px;
    line-height: 16px;

    @include darkMode {
        color: $lightest_neutral;
    }

    &:hover {
        color: $brandedcolor2;
        text-decoration: underline;

        @include darkMode {
            color: $brandedcolor8;
        }
    }

    &:active {
        color: $brandedcolor4;

        @include darkMode {
            color: $brandedcolor9;
        }
    }
}

.catalog-title-link {
    background: url(/images/pointer-default.svg) no-repeat calc(100%) center;
    background-size: 25px 25px;
    padding-right: 25px;
    color: $brandedcolor5;

    @include darkMode {
        background: url(/images/pointer-darkmode-default.svg) no-repeat calc(100% - 4px) center;
        color: #F5F5F5;
        border: 1px solid transparent;
        padding-right: 32px;
    }

    &:hover {
        background: url(/images/pointer-hover.svg) no-repeat calc(100% - 4px) center;
        background-size: 25px 25px;
        color: $brandedcolor2;

        @include darkMode {
            background: url(/images/blue-1_pointer.svg) no-repeat calc(100% - 11px) center;
            color: $brandedcolor23;
        }
    }

    &:active {
        background: url(/images/pointer-pressed.svg) no-repeat calc(100% - 4px) center;
        background-size: 25px 25px;
        color: $brandedcolor4;

        @include darkMode {
            background: url(/images/blue-1_pointer.svg) no-repeat calc(100% - 11px) center;
            color: $brandedcolor23;
        }
    }
}

.view-all {
    background: url(/images/pointer-default.svg) no-repeat calc(100% - 4px) center;
    background-size: 25px 25px;
    font-size: 14px;
    font-weight: 500;
    color: $brandedcolor5;
    text-transform: uppercase;
    padding: 10px 30px 10px 10px;
    position: absolute;
    right: 2em;
    bottom: 5px;

    @include darkMode {
        background: url(/images/pointer-darkmode-default.svg) no-repeat calc(100% - 4px) center;
        color: $lightest_neutral;
        border: 1px solid transparent;
    }

    &:hover {
        background: url(/images/pointer-hover.svg) no-repeat calc(100% - 4px) center;
        background-size: 25px 25px;
        color: $brandedcolor2;

        @include darkMode {
            background: url(/images/blue-1_pointer.svg) no-repeat calc(100% - 11px) center;
            border: 1px solid $brandedcolor23;
            background-color: $brandedcolor25;
            border-radius: 2px;
            color: $brandedcolor23;
        }
    }

    &:active {
        background: url(/images/pointer-pressed.svg) no-repeat calc(100% - 4px) center;
        background-size: 25px 25px;
        color: $brandedcolor4;

        @include darkMode {
            background: url(/images/blue-1_pointer.svg) no-repeat calc(100% - 11px) center;
            background-color: $brandedcolor24;
            border-radius: 2px;
            border: 1px solid $brandedcolor23;
            color: $brandedcolor23;
        }
    }
}

.or-block {
    border-top: 1px solid $midlight_neutral;
    display: block;
    margin: 32px auto;
    position: relative;

    @include darkMode {
        border-color: $dark_mode_border;
    }
}

.or {
    border-radius: 5px;
    border: 1px solid $midlight_neutral;
    background: $lightest_neutral;
    color: $middark_neutral;
    display: block;
    font-size: 12px;
    font-style: normal;
    height: 30px;
    line-height: 2.5em;
    left: 50%;
    min-width: 30px;
    padding-left: 0.25em;
    padding-right: 0.25em;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    text-align: center;
    text-transform: uppercase;

    @include darkMode {
        background: $dark_mode_bg;
        color: $light_neutral;
        border: 1px solid $dark_mode_border;
    }
}

.arrow {
    background-image: $arrow-down-green;
    background-position: calc(100% - 10px) calc(100% - 10px);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid $midlight_neutral;
    box-shadow: 0 0 0 #999;
    color: #000;
    font-weight: 700;
    font-size: 0.85em;
    height: 41px;
    overflow: hidden;
    padding-left: 1em;
    line-height: 3.15em;
    text-indent: 0;
    text-decoration: none;
    width: 66px;
}

.btn-profile-outer {
    position: relative;
    height: 43px;
    width: 57px;
    margin: auto 0;
    z-index: 11;
}

.btn-profile {
    background-color: $lightest_neutral;
    border: 1px solid $brandedcolor1;
    border-radius: 20px;
    height: 25px;
    width: 25px;
    margin: 6px 0 0 0;
    cursor: pointer;
    color: $darkest_neutral;
    font-size: 10px;

    .user-initials {
        text-align: center;
        font-weight: 500;
        display: block;
        position: relative;
        top: 6px;
    }

    .default-profile {
        position: relative;
        bottom: 5px;
    }
}


.header-right-container {
    justify-content: flex-end;
    flex-grow: 1;
}

.crumbtrail {
    padding: 6px 25px 5px 25px;
    min-height: 36px;
    box-sizing: border-box;
    background-color: $brandedcolor27;
    //margin: 0 -2.1% 5px -2.1%;
    @include darkMode {
        background-color: $darker_neutral;
        color: $lighter_neutral;
    }

    z-index: 4;
    position: sticky;
}

.header-container {
    padding: 10px 25px;
    margin: 0 -2.1%;
    height: calc(60px - 20px);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));

    @include darkMode {
        background: $brandedcolor7;
    }

    &.catalog-header {
        background: $lightest_neutral;

        @include darkMode {
            background: $brandedcolor7;
        }
        .uk-selector__option,
        .dropdown__submenu,
        .dropdown__submenu ul li {
            @include darkMode {
                color: #F5F5F5;
            }
        }
    }
}

.breadcrumb-home {
    display: inline-block;
    margin: 4px 47px 0 30px;
    font-size: 14px;
    font-weight: 500;
    float: left;
    @include darkMode {
        color: $lighter_neutral;
    }
}


.breadcrumb {
    font-size: 14px;
    margin: 3px 30px 3px 0;
    height: 18px;
    word-break: break-word;
    color: $darkest_neutral;
    font-weight: 500;

    @include darkMode {
        color: #F5F5F5;
    }

    &:hover {
        text-decoration: underline;
    }
}

.crumbtrail-main > .breadcrumb ~ .breadcrumb {
        color: $brandedcolor17;
        margin: 0;

        &:before {
            content: " / ";
            color: $darkest_neutral;

            @include darkMode {
                color: #F5F5F5;
            }
        }

        @include darkMode {
            color: #F5F5F5;
        }
    }

.crumbtrail-main {
	display: inline-block;
    width: calc(100% - 219px);
	margin: 2px 0 0 0;
}
.crumbtrail-accounting {
    display: inline-block;
    margin: 0 50px 0 10px;
    font-size: 14px;
	font-weight: 500;
	float: right;
    a {
        color: #0076a8;
        text-decoration: #0076a8 underline;
    }
}
.breadcrumb-active {
    &:hover {
        text-decoration: none;
    }
}

    .researchboard-panel-main {
        width: 100%;
    }

    .pannels-container {
        width: 100%;
        margin-top: 20px;
        display: flex;
        position: relative;
        flex-wrap: wrap;
        margin-left: 20px;
        margin-right: 20px;
    }

    .pannels-side-panel {
        width: auto;
        // height: 80vh;
    }

    .main-pannel {
        border: 1px solid $brandedcolor1;
        border-radius: 7px;
        box-sizing: border-box;
        padding: 32px;
        width: 69%;

        @include darkMode {
            border-color: $color13;
        }

        .article-preface {

            p,
            .p {
                line-height: 1.5em;
                line-height: 1.5em;
                font-size: 14px;
                margin: 1em 0;
            }

            a {
                color: #0076a8;
                text-decoration: underline;
            }
        }

        section.notices {
            font-size: 10px;
            text-align: center;
            margin: 0 auto;
            width: 100%;
            padding-top: 20px;

            p,
            .p {
                font-size: inherit;
            }
        }
    }

    p.article-copyright {
        text-align: center;
    }

    .main-pannel-left {
        padding-top: 16px;
    }

    .btn-load-more {
        color: #333;
        border-radius: 5px;
        padding: 0.5em;
        display: block;
        font-size: 14px;
        width: 100px;
        border: 1px solid $midlight_neutral;
        margin: 1.5em auto 1em auto;
        text-align: center;
        text-decoration: none;
        transition: all 0.2s ease;
        background: transparent;
    }

    .btn-load-more:hover {
        border-color: $middark_cool;
    }

    .title-and-search-container {
        margin-top: 6px;
        // margin-bottom: 24px;
    }

    .title-and-description-container {
        flex-grow: 1;
        margin: auto;
        margin-right: 20px;
        margin-left: 20px;
    }

    .catalog-searchbar-fixed {
        position: fixed;
        right: 26px;
        top: 45.64px;
        z-index: 5;
    }

    .catalog-searchbar {
        margin-top: 15px;
        position: absolute;
        right: 26px;
        z-index: 4;
    }

    .catalog-searchbar-input {
        width: 288.5px;
        margin-top: -32px;
        margin-right: -18.9%;
    }


    .catalog-wormhole-div {
        display: flex;
        width: 100%;
        flex: 0 50%;
        margin-left: 10px;
    }
    // .catalog-warmhole-notification {
    //     marginRight: '35px', marginTop: '7px'
    //     margin:7px
    // }


    .pannels-container-content {
        display: flex;
    }

    .pannels-container-main {
        width: 100%;
    }

    .catalog-main {
        display: flex;
        justify-content: space-between;
        margin-top: 33px;
        width: 100%;
        margin-right: 80px;
        margin-left: 80px;
    }

    .layout-loader {
        z-index: -1;
    }

    .recently-viewed-main {
        width: 66%;
    }

    .recently-viewed-item-body {
        padding-bottom: 5px;
    }

    .recently-viewed-item-heading {
        font-weight: bold;
    }

    .recently-viewed-item-heading-link {
        text-decoration: underline;
        cursor: pointer;
    }

    .recently-viewed-item-breadcrumb {
        font-size: small;
        color: #808080;
    }

    .chatbot-catalog {
        border: 1px solid #f8fcf7;
        //border: 1px solid   rgb(254, 255, 237);
        border-radius: 8px;
        //padding: 16px 2px 6px 2px;
        position: relative;
        background-color: #f8fcf7;
        // background-color:rgb(254, 255, 237);
        width: 100%;
        margin: 33px 80px 0px 80px;
        min-height: 93px;
        //   display: flex;
        // position: relative      ;
        grid-column: span 2 !important;
        padding: 32px 32px 32px 32px;
        @include darkMode {
			background-color: #212121;
            border: 1px solid #00B500;
		}
    }

    .nonchatbot-catalog {
        border: 1px solid white;
        //border: 1px solid   rgb(254, 255, 237);
        border-radius: 8px;
        padding: 16px 2px 6px 2px;
        position: relative;
        background-color: white;
        // background-color:rgb(254, 255, 237);
        width: 100%;
        margin: 33px 80px 0;
        min-height: 93px;
        //   display: flex;
        // position: relative      ;
        @include darkMode {
            border: 1px solid #757575 !important;
        }
    }

    .chatbot-box-top-row {
        margin-bottom: 8px;
        //margin-left: 12px;
    }

    .chatbot-heading {
        padding-left: 14px;
    }

    .catalog-searchbar-container {
        padding-left: 61px;
        box-sizing: border-box;
        padding-right: 20px;
        overflow-y: auto;
        position: relative;
        z-index: 1;
    }

    .chatbot-icon-container {
        display: flex;
        flex-shrink: 0;
        margin-top: auto;
        margin-bottom: auto;
        //margin-right: 2%;
        padding: 0px 5px 0 15px;
    }

.chatbot-box-icon {
    height: 25px;
    width: 25px;
    content: url(/images/icon-search-gr.svg);
    @include darkMode {
        content: url(/images/icon-search-dk.svg);
    }
}

    .chatbot-box-name {
        font-weight: 500;
        margin: 0;
        margin-bottom: -3px;
        font-size: 16px;

        @include darkMode {
            //background: #f5f5f5;
            color: #f5f5f5;
        }
    }

    .chatbot-box-description {
        font-weight: 400;
        font-size: 12px;
        margin: 5px 0 0 0;

        @include darkMode {
            //background: #f5f5f5;
            color: #f5f5f5;
        }
    }


    .catalog-searchbar-chatbot {
        //     margin-top: 15px;
        //    // position: absolute;
        //    //width: 90%;
        //     z-index: 4;
        //     //display: flex;
        //     position: relative;
        //     font-size: 14px;
        //     flex-grow: 1;
        //     align-items: center;
        //     border-radius: 4px;
        //     /* box-shadow: none; */
        //     /* cursor: pointer; */
        //     display: flex;
        //     /* flex-wrap: wrap; */
        //     /* justify-content: space-between; */
        //     min-height: 36px;
        //     /* transition: border-color .2s ease 0s; */
        //     box-sizing: border-box;
        //     border: 1px solid #d9d9d9;
        //     width: 100%;
        //margin-left: 6%;
        margin-top: 6px;
        z-index: 3;
        position: relative;
        /* font-size: 14px; */
        flex-grow: 1;
        align-items: center;
        border-radius: 4px;
        /* box-shadow: none; */
        /* cursor: pointer; */
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        /* min-height: 0px; */
        /* transition: border-color .2s ease 0s; */
        box-sizing: border-box;
        border: 1px solid #d9d9d9;
        width: 100%;
        background-color: white;
        @include darkMode {
            border: 1px solid #333333;
            background-color: #333333;
		}
    }

    .chatbot-form {
        border-radius: 8px;
        width: 85%;
        height: 37px;
        // border: 1px solid black;
        background: white;
        // border-radius: 2px;
        @include darkMode {
			background: #333333;
		}
    }

    .catalog-searchbar-input-chatbot {
        display: flex;
        width: 92%;
        padding: 0 20px 0 20px;
        margin: 0 4px;
        color: inherit;
        font-size: 14px;
        font-family: "Roboto", Verdana, sans-serif;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-height: 35px;
        border: none;
        outline: none;
        background: none;
        font-size: 14px;
        color: $darkest_neutral;
        border-radius: 2px;

        @include darkMode {
            border: none;
            background: #333333;
            color: #f5f5f5;
        }

        &::placeholder {
            color: $brandedcolor22;
        }

        &:focus {
            border: 1px solid white;
            border-radius: 2px;

            @include darkMode {
                border: 1px solid #333333;
                border-radius: 2px;
            }
        }

        background-color: white;
        // :focus {
        //     outline: none !important;
        //     border: none !important;
        // }
    }

    .searchbar-chatbot {
    }

    .chatbot-button-go {
        position: relative;
        // top: -32px;
        //right: -97px;
        float: right;
        margin-right: 0.3%;
        border-radius: 4px;
        background-color: #0076A8;
        @include darkMode {
            background-color: #00a3e0;
		}
        
    }

    .chatbot-go {
        display: inline-block;
        cursor: pointer;
        padding: 10px;
        height: 31px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        background-color: transparent;
        border: 1px solid transparent;
        text-transform: uppercase;
        border-radius: 6px;
        background: url(/images/pointer_pressed-right-white.svg) no-repeat 100%;
        background-size: 68px 16px;
        //  padding-right: 25px;
        // color: #0076a8;
        width: 72px;
        background-color: #0076a8;
        color: #ffffff;
        @include darkMode {
            background-color: #00a3e0;
		}
    }


    .non-chatbot-go {
        display: inline-block;
        cursor: pointer;
        //padding: 10px;
        height: 31px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        background-color: transparent;
        border: 1px solid transparent;
        //text-transform: uppercase;
        border-radius: 6px;
        // background: url(/images/pointer_pressed-right.svg) no-repeat 100%;
        // background-size: 68px 16px;
        //  padding-right: 25px;
        // color: #0076a8;
        width: 72px;
        background-color: #0076a8;
        color: #ffffff;
    }





    .chatbot-catalog-search-result-panel {
        margin-right: -18.9%;
        max-height: 900px;
        background-color: white;
        width: 90%;
        display: block;
        overflow: auto;
        position: relative;
        z-index: 6;
        margin-top: 54px;

        @include darkMode {
            background-color: #212121;
            padding-top: 8px;
        }
    }

    //ChatBot Catalog Search Result
    .chat-boat-catalog-modal {
        padding: 20px;
        width: calc(100% - 160px);
        background: #fff;
        box-shadow: 0 10px 18px rgba(9,30,66,.15), 0 0 1px rgba(9,30,66,.31);
        border-radius: 7px;
        height: calc(100vh - 120px);
        position: absolute;
        top: 120px;
        z-index: 4;
        box-sizing: border-box;
        margin: 33px 0px 0px 80px;

    @include darkMode {
        background-color: $brandedcolor7;
        border: 1px solid #444444;
    }
    //Scroll Bar------------------------------------
    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #9e9e9e;
        border-radius: 10px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
    //------------------------------------
    .catalog-box-icon {
        margin-top: -10px;
        height: 35px;
        width: 35px;
    }

        .icon-container {
            display: flex;
            flex-shrink: 0;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 0px;
            margin-left: -4px;
        }

        .chat-boat-catalog-modal-close {
            background: url("/images/chatbot-close.png") no-repeat center center;
            background-size: 35px 35px;
            display: block;
            height: 35px;
            position: absolute;
            right: 2em;
            top: 1.6em;
            text-indent: -9999px;
            width: 35px;
            transition: transform 0.2s ease;
            border: none;

            &:hover {
                transform: rotate(90deg);
                transition: transform 0.2s ease;
            }
            @include darkMode {
                background: url("/images/chatbot-close-dk.png") no-repeat center center;
                background-size: 35px 35px;
                filter: invert(100%);
            }
        }

        .chat-boat-catalog-modal-body {
            // min-width: 100%;

            .chat-boat-catalog-modal-search-block {
                margin-left: 20px;
                margin-right: 20px;
            }

            .chat-boat-catalog-modal-content-block {
                height: calc(100vh - 204px);
                overflow-y: auto;
                margin-left: 20px;
                margin-right: 20px;
                margin-top: 15px;
            }

            .catalog-chatboat-modal-input {
                background: url(/images/icon-search-gr.svg) no-repeat calc(0% + 15px) center;
                background-size: 15px 15px;
                border-radius: 2px;
                border: 1px solid #d9d9d9;
                font-size: 14px;
                margin: auto 0 auto auto;
                padding: 0.75em 0.75em 0.75em 3.5em;
                color: #000000;
                background-color: white;
                width: 90%;
                @include darkMode {
                    color: #f5f5f5;
                    border: 1px solid #444444;
                    background: url(/images/icon-search-dk.svg) no-repeat calc(0% + 15px) center;
                    background-size: 15px 15px;
                    background-color: #333333;
                }
            }

            .search-assistant {
	            background: #f8fcf7;
                display: flex;
                width: 85%;
                padding: 20px 0px 15px 20px;
                margin-top: 12px;
                border-radius: 21px;

                .search-assistant-ico {
                    width: 35px;

                    .search-assistant-ico-img {
                        background: url(/images/chatbot-search-assis.png) no-repeat 100%;
                        background-size: 25px 25px;
                        border: none;
                        padding-left: 18px;
                        height: 35px;
                        margin-top: -10px;
                        cursor: default;
                        
                        @include darkMode {
                            background: url(/images/chatbot-search-assist.png) no-repeat 100%;
                            background-size: 25px 25px;
                        }   
                    }
                }

                .search-assistant-body {
                    width: 95%;

                    .search-assistant-title {
                        font-weight: 500;
                    }

                    .search-assistant-response-container {
                        display: flex;
                        position: relative;

                        .search-assistant-response {
                            width: 95%;

                            .filter-value {
                                margin-top: 15px;

                                label {
                                    font-size: 14px;
                                    font-weight: 500;
                                }
                            }
                        }

                        .search-assistant-response-loader {
                            height: 100px;
                            overflow: hidden;
                        }

                        .search-assistant-response-content-loader {
                            height: 44.81px;
                            overflow: hidden;
                            font-size: 14px;
                            margin-top: 15px;
                            font-family: "Roboto", Verdana, sans-serif;
                        }

                        .search-assistant-response-content {
                            height: auto;
                            max-height: 125px;
                            overflow: hidden;
                            font-size: 14px;
                            line-height: 1.5em;
                            font-family: "Roboto", Verdana, sans-serif;
                            position: relative;
                            padding-right: 70px;

                            .response-opacity {
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                height: 18px;
                                background: #f8fcf7;
                                padding: 0;
                                margin: 0;
                                opacity: 0.7;

                                @include darkMode {
                                    background-color: #212121;
                                }
                            }
                        }

                        .research-assistant-suggestion {
                            font-size: 14px;
                            line-height: 1.6;
                            font-weight: 500;
                            font-family: "Roboto", Verdana, sans-serif;
                        }

                        .research-assistant-responses {
                            font-size: 14px;
                            line-height: 1.6;
                            margin: 0.5em 0;
                            font-family: "Roboto", Verdana, sans-serif;
                            p {
                                font-size: 14px !important;
                              }
                        }

                        .askme-followup-box {
                            margin: 15px 0 10px;

                            .ask-followup-question-button {
                                background: #0076A8;
                                border: 0;
                                display: flex;
                                align-items: center;
                                justify-content: left;
                                color: #fff;
                                font-weight: 500;
                                padding: 12px 15px;
                                box-sizing: border-box;
                                border-radius: 6px;
                                cursor: pointer;
                                @include darkMode {
                                    color: #F5F5F5;
                                }

                                .chat-icon-img {
                                    width: 16px;
                                }

                                .ask-followup-label {
                                    padding: 0px 14px;
                                    cursor: pointer;
                                }

                                .goto-next-icon {
                                    width: 12px
                                }
                            }
                        }



                        .search-assistant-share-img {
                            background: url(/images/chatbot-share.png) no-repeat 100%;
                            background-size: 25px 25px;
                            border: none;
                            padding-left: 18px;
                            height: 40px;
                            margin-top: -10px;
                            
                            @include darkMode {
                                background: url(/images/chatbot-share-white-img.png) no-repeat 100%;
                                background-size: 25px 25px;
                            }
                        }

                        .search-assistant-thumb-up-img {
                            background: url(/images/chatbot-thumbs-up.png) no-repeat 100%;
                            background-size: 25px 25px;
                            border: none;
                            padding-left: 18px;
                            height: 40px;
                            margin-top: -10px;

                            @include darkMode {
                                background: url(/images/white-thumbs-up.png) no-repeat 100%;
                                background-size: 25px 25px;
                            }
                        }

                        .search-assistant-thumb-down-img {
                            background: url(/images/chatbot-thumbs-down.png) no-repeat 100%;
                            background-size: 25px 25px;
                            border: none;
                            padding-left: 18px;
                            height: 40px;
                            margin-top: -10px;

                            @include darkMode {
                                background: url(/images/white-thumbs-down.png) no-repeat 100%;
                                background-size: 25px 25px;
                            }
                        }

                        .search-assistant-help-button {
                            margin-top: 14px;
                            margin-left: 20px;
                            width: 50px;
                            position: absolute;
                            right: 0;
                            overflow-y: hidden;
                            top: 0;
                            bottom: 0;
                            padding-top: 2px;
                        }
                    }

                    .search-expand {
                        display: grid;

                        .read-more-button {
                            background: none;
                            border: none;
                            color: #0076a8;
                            font-weight: 600;
                            margin-top: 15px;
                            @include darkMode {
                                color: #00A3E0;
                            }
                        }
                    }

                    .ai-content-disclaimer{
                        color: gray;
                        font-size: 12px;
                    }
                }
                @include darkMode {
                background-color: #212121;
                color: #f5f5f5;
                border: 1px solid #00B500;
                }
            }

            .modal-serach-result {
                margin-top: 10px;
                width: 100%;

            .search-result-title {
                font-size:18px;
                font-weight: 800;
                padding: 8px 0px 0px 0px;
                @include darkMode {
                    color: #F5F5F5;
                }
            }

                .chatbot-catalog-search-result-panel-search-active {
                    padding: 15px 0px 0px 0px;

                    .collection-result-item-main {
                        padding: 8px 0 0 0px;
                        font-size: 14px;
                        .result-title-icon{
                           img{
                            margin-left: -6px;
                           }
                        }
                    }

                    .result-title-icon {
                        display: flex;
                    }

                    a {
                        font-weight: bold;
                        text-decoration: underline;
                        margin-top: 2px;
                        @include darkMode {
                            color: #F5F5F5;
                        }
                    }

                    .snippet {
                        color: #9e9e9e;
                    }

                    .catalog-search-result-container-title {
                        color: #000000;
                        width: auto;
                        @include darkMode {
                           color: #F5F5F5;
                        }
                    }

                    .catalog-search-result-container {
                        padding: 15px 0px 15px 0;
                        width: 85%;
                    }
                }

                .catalog-search-result-container-title-link-disabled {
                    float: right;
                    margin-left: 15px;
                    font-size: 11px;

                .collection-search-view-all-disabled {
                    margin-top: 0px;
                }
            }

            .collection-search-view-all{
                text-decoration : none !important;
            }
        }
    }
}

.prod-body-container .chat-boat-catalog-modal {
    top: 95px !important;
}

.ra-error-notification .chat-boat-catalog-modal {
 top: 140px;
 height: calc(100vh - 140px);
 .chat-boat-catalog-modal-body .chat-boat-catalog-modal-content-block{
    height: calc(100vh - 230px);
 }
}

    .disclaimer {
        color: #808080;
        font-style: italic;
        font-size: 11px;
    }

    .disclaimer-box {
        padding: 7px 0 4px;
    }
    //User FeedBack
    .userfeedback-modal {
        padding: 0 0 1em 0;
        width: 50%;
        background: #ffffff;
        box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
        border-radius: 7px;
        height: auto;
        min-height: 240px;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;

        .modal-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #d9d9d9;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            padding: 0.35rem 0.5rem 0.35rem 1rem;
            box-sizing: border-box;
            height: 44px;
            background: #f2f2f2;

            .modal-title {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                color: #000000;
            }

            .modal-close {
                background: var(--icon-close) no-repeat center center;
                background-size: 20px 20px;
                display: block;
                height: 20px;
                width: 20px;
                transition: transform 0.2s ease;
                border: none;
            }
        }

        .modal-content {
            margin: 15px 20px 0px 15px;

            .sugegstion-item {
                margin: 10px 20px 0px 15px;

                .input-item {
                    display: inline-block;
                    min-width: 15px;
                    width: 15px;
                    height: 15px;
                    transition: all .15s;
                    border: 1px solid #ccc;
                    margin-right: 6px;
                    position: relative;
                    cursor: pointer;
                    border-radius: 3px;
                }
            }

            .title {
                font-size: 14px;
            }

            .modal-comment {
                display: flex;
                margin-top: 18px;
                box-sizing: border-box;
                width: 100%;
                height: 69px;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: flex-end;

                .modal-comment-input {
                    -webkit-appearance: none;
                    appearance: none;
                    resize: none;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 10px 10px 0;
                    border: none;
                    width: 100%;
                }

                .modal-comment-count {
                    font-size: 14px;
                    padding-right: 9px;
                    color: #ccc;
                }
            }
        }

        .modal-footer {
            margin-top: 0.8rem;
            text-align: right;
            padding: 10px 20px 0px 20px;
            display: flex;
            justify-content: end;

            .feedback-submit-image {
                height: 25px;
            }

            .modal-save {
                background-color: #0076a8;
                display: flex;
                cursor: pointer;
                height: 37px;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                border: 1px solid transparent;
                border-radius: 3px;
                width: 105px;
                color: #fff;
                text-align: left;
                padding: 5px 0px 0px 10px;

                span {
                    text-align: center;
                    padding-left: 10px;
                    padding-top: 5px;
                }
            }
        }
    }

    .tosdeclaration-intro{
    .response-opacity {
        position: absolute;
        bottom: 50px;
        width: 100%;
        height: 20px;
       background: #f8fcf7;
        padding: 0;
        margin: 0;
        opacity: 0.7;

        @include darkMode {
            background-color: #212121;
        }
    }

    .tosdeclaration-title{
        text-align: center;
    }
    .tosdeclaration-heading{
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    .tosdeclaration-content{
        ul, ol {
            margin: 10px 0 0 0;
            color: #000000;
            padding: 0 0 0 13px !important;
            font-size: 14px;
        }
        p {
            font-size: 14px;
        }
    }

    .tosdeclaration-footer{
        .agree{
            background: #0076A8;
            color: white;
            padding: 5px 10px;
            border-radius: 5px;
            border: none;
            margin-top: 20px;
            }
    }
}