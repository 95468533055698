/*------------------------------------*\
    Global
\*------------------------------------*/

:root  {
    --icon-home-bg-image: #{$icon-home-black};
    --toc-item-icon: url(/images/icon-article-bk.svg);
    --icon-in-article-toc: url(/images/toc-default.svg);
	//--icon-in-article-toc: url(/images/icons/iconTOC.svg);
	--icon-close: url("/images/icon-close-bk.svg");
    --btn-profile-bg-color: #{$lightest_neutral};
    --btn-profile-bg-color-open: #{$color1};
    --btn-profile-border-color:  #{$midlight_neutral};
    --catalog-box-bg-color: #{$lightest_neutral};
    --catalog-box-border-color: #{$brandedcolor1};
    --btn-search-icon-article-bg-color: #{$lightest_neutral};
    --main-border-color: #{$brandedcolor1};
    --a-tag-color: #{$darkest_neutral};
    --icon-profile: #{$icon-profile-black};
    --theme-bg: #{$lightest_neutral};
    --invert-colors-amount: invert(0);
    --article-section-border-color: #{$light_neutral};
	--modal-box-shadow-color: #{$middark_neutral};
	--associated-content-bg: #{$color7};
	--search-filters-border-color: #{$light_neutral};
	--search-filters-bg-color: #fafafa;
	--search-filter-badge-border-color-hover: #{$darkest_neutral};
	--search-filter-badge-bg-color: #{$lightest_neutral};
	--auto-translate-notice-bg-color: #feffd2;
	--rm-btn-bg-color: #{$lightest_neutral};
	--footer-beta-color: #{$brandedcolor6};
	--allresults: rgba(255,255,255,.9);
	--allresults-fixed: rgba(250,250,250,0.9);
	--checkbox-label: #{$darkest_neutral};
}

body {
	font-family: $primary_font_stack;
	font-size: 16px;
	line-height: normal;
	margin: 0;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	color: $darkest_neutral;
	position: relative;
    min-height: 100vh;
	
	@include darkMode() {
		color: #{$light_neutral};
		background: #{$brandedcolor7};
		--a-tag-color: #{$lightest_neutral};
		--icon-home-bg-image: #{$icon-home-white};
		--toc-item-icon: url(/images/icon-article-wh.svg);
		--icon-in-article-toc: url(/images/icon-article-toc-wh.svg);
		--icon-close: url("/images/icon-close-wh.svg");
		--icon-close-soft: url("/images/icon-close-soft-white.svg");
		--btn-profile-bg-color: #{$dark_mode_bg};
		--btn-profile-bg-color-open: black;
		--btn-profile-border-color: #{$dark_mode_border};
		--catalog-box-bg-color: #{$dark_mode_bg};
		--catalog-box-border-color: #{$dark_mode_border};
		--btn-search-icon-article-bg-color: #{$dark_mode_bg};
		--breadcrumb-color-hover: #ccc;
		--breadcrumb-color: #999;
		--breadcrumb-color-active: #{$color13};
		--breadcrumb-border-color: #{middark_neutral};
		--main-border-color: #{$dark_mode_border};
		--icon-profile: #{$icon-profile-white};
		--theme-bg: #{$dark_mode_bg};
		--invert-colors-amount: invert(1);
		--article-section-border-color: #{$middark_neutral};
		--modal-box-shadow-color: #{$darkest_neutral};
		--associated-content-bg: #{$dark_neutral};
		--search-filters-border-color: #{$dark_mode_border};
		--search-filters-bg-color: #{$dark_mode_bg};
		--search-filter-badge-border-color-hover: #{$light_neutral};
		--search-filter-badge-bg-color: #{$darker_neutral};
		--auto-translate-notice-bg-color: #c2c42b;
		--rm-btn-bg-color: #{$dark_mode_bg};
		--allresults: rgba(34,34,34,.9);
		--allresults-fixed: rgba(0,0,0,0.9);
		--checkbox-label: #{$light_neutral};

		.article-search-container-fixed {
		  --btn-search-icon-article-bg-color: #{$darkest_neutral};
		}

		button {
			color: inherit;
		}



	  }
}
:focus-visible {
	outline: 0px dotted $brandedcolor5;
	@include darkMode {
		outline: 0px dotted $brandedcolor8;
	}
}

::selection {
	background: $middarkest_neutral;
	color: $lightest_neutral;
	text-shadow: none;
	@include darkMode {
		background: $lighter_neutral;
		color: $darkest_neutral;
	}
}
th div::selection, th div mark::selection {
	background: $lightest_neutral;
	color: $middarkest_neutral;
	text-shadow: none;
	@include darkMode {
		background: $middarkest_neutral;
		color: $lightest_neutral;
	}
}
img {
    max-width: 100%;
    height: auto;
}
.skipnavigation {
	left:-999px;
    position:absolute;
    top:auto;
    width:1px;
    height:1px;
	text-indent: -9999px;
}
.home {
	overflow-y: hidden;
}
table {
	width: 100%;
	margin: 0 auto 15px auto;
	overflow-x: auto;
	border-collapse: collapse;
    display: block;
	max-width: 100%;
	table-layout: auto;
	p {
		font-size: 1em;
	}
	ol, ul {
		margin: 0 0 10px 0;
	}
	caption {
		font-size: 0.7em;
		font-weight: 700;
		margin: 0.75em auto;
		text-transform: uppercase;
	}
	tr:last-child {
		td {
			&:first-child {
				border-radius: 0 0 0 .5em;
			}
			&:last-child {
				border-radius: 0 0 .5em 0;
			}
		}
	}
	th, td {
		padding: 0.5em;
		border: 1px solid $mid_neutral;
	}
	thead {
		font-size: 15px;

		@include darkMode {
			background: $darkest_neutral;
		}

		th {
			background-color: $middarkest_neutral;
			color: $lightest_neutral;

			@include darkMode {
				background-color: #00000033;
				color: $light_neutral;
			}
		}
	}
	tbody {
		font-size: 13px;
		tr {
			@include darkMode {
				background-color: $dark_neutral;
			}
			td {
				vertical-align: top;
			}
		}
	}
	tfoot {
		font-size: 13px;
		font-weight: 700;
		td {
			background-color: $light_neutral;
		}
	}
	&.frame-none {
		td {
			border: none;
		}
	}
}


.rtf {
	margin: 0 0 30px 0;
	ul, ol {
		text-align: left;
	}
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
	margin: 0.5em 0;
}
h1 {
	font-size: 1.75em;
}
h2 {
	font-size: 1.25em;
}
h3, h4, h5 {
	font-size: 1.15em;
}
h6 {
	font-size: 1em;
}
p, ul, ol {
	font-size: 0.85em;
}

a {
	color: var(--a-tag-color);
	text-decoration: none;
}
ul {
	list-style-type: disc;
}
ul, ol {
	margin: 10px 0 0 0;
	color: $darkest_neutral;
	padding: 0 0 0 40px;
	li {
		padding: 0 0 10px 0;
	}
	ul, ol {
		font-size: 0.95em;
		padding: 0 0 0 25px;
		li {
			&:last-child {
				padding: 0;
			}
		}
	}
	@include darkMode {
        color: $lightest_neutral;
    }
}

/*First, Fourth, and Seventh levels (in addition to anything at/past level 10) of nested ol will be numeric (1, 2, 3)*/
ol, ol ol ol ol, ol ol ol ol ol ol ol, ol ol ol ol ol ol ol ol ol ol {
list-style-type: decimal;
}
/*Second, Fifth and Eigth level of nested ol will be lower-alpha (a, b, c)*/
ol ol, ol ol ol ol ol, ol ol ol ol ol ol ol ol {
	list-style-type: lower-alpha;
}
/*Third, Sixth and Ninth level of nested ol (in addition to anything deeper) will be lower-roman (i, ii, iii)*/
ol ol ol, ol ol ol ol ol ol, ol ol ol ol ol ol ol ol ol {
	list-style-type: lower-roman;
}

ol, ol li {
	&.roman {
		list-style: upper-roman;
		ol {
			list-style: lower-roman;
		}
	}
	&.alpha {
		list-style: upper-alpha !important;
		ol {
			list-style: upper-alpha !important;
		}
	}
	&.decimal, &[data-outputclass="codifiedParaListLev3"], &.codifiedParaListLev3 {
		list-style-type: decimal;
	}
	&.lower-alpha, &[data-outputclass="codifiedParaListLev1"], &.codifiedParaListLev1 {
		list-style-type: lower-alpha;
	}
	&.lower-roman, &[data-outputclass="codifiedParaListLev2"], &.codifiedParaListLev2 {
		list-style-type: lower-roman;
	}
	&.upper-roman {
		list-style-type: upper-roman !important;
	}
	&.upper-alpha {
		list-style-type: upper-alpha !important;
	}
}
ul, ul li, ol, ol li {
	&.square {
		list-style: square;
	}
	&.none {
		list-style: none;
	}
}
ul, ol {
	&.sl li {
		list-style: none;
	}
	li.sli {
		list-style: none;
	}
}
ul, ul li {
	&.bullet, &.disc {
		list-style-type: disc;
	}
	&.circle {
		list-style-type: circle;
	}
}
ul {
	&.diamond {
		list-style: none;
		& > li::before {
			content: "◆";
			margin: 0 0 0 -17px;
			padding: 0 6px 0 0;
		}
	}
	&.emdash{
		list-style: none;
		& > li::before {
			content: "—";
			margin: 0 0 0 -17px;
			padding: 0 6px 0 0;
		}
	}
}
ul li {
	&.diamond{
		list-style: none;
		&::before {
			content: "◆";
			margin: 0 0 0 -17px;
			padding: 0 6px 0 0;
		}
	}
	&.emdash{
		list-style: none;
		&::before {
			content: "—";
			margin: 0 0 0 -17px;
			padding: 0 6px 0 0;
		}
	}
}

div.imageleft {
	text-align: left;
	&> img.imageleft {
		display: initial;
	}
}
div.imageright {
	text-align: right;
	&> img.imageright {
		display: initial;
	}
}

/*------------------------------------*\
    Resets
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    General
\*------------------------------------*/
button {
	cursor: pointer;
	font-family: inherit;
}
.round-button {
	background-color: $midlight_cool;
	color: $lightest_neutral;
    background-size: 20px 20px;
    display: block;
    border-radius: 0.25em;
    text-decoration: none;
	height: 36px;
    width: 36px;
    transition: all .2s ease;
	border: none;
	&:hover {
		background-color: $middark_cool;
	}
	span {
		font-size: 1.5em;
		padding: 2px 0 0 2px;
	}
}
.dashboard-btn {
	background-color: $color7;
	border-radius: 0.25em;
	box-shadow: 0 0 5px $mid_neutral;
	transition: all 0.2s ease;
	height: 36px;
	width: 36px;
	border: none;
	&:hover, &.open {
		background-color: $lightest_neutral;
		box-shadow: 0 0 10px $mid_neutral;
		transition: all 0.2s ease;
	}
}
.search {
	input {
		font-size: 0.75em;
		background: transparent url("/images/icon-search-gr.svg") no-repeat;
		background-size: 15px 15.83px;
		background-position: right center;
		background-color: unset;
		border-bottom: 1px solid $mid_cool;
		border-top: none;
		border-right: none;
		border-left: none;
		transition: background 0.2s ease;
		padding: .5em 2em .5em .5em;
		margin: 1em 0 0 0;
		font-family: inherit;
		line-height: 1.15em;
		&:focus {
			transition: background 0.2s ease;
			background: transparent url("/images/icon-search.svg") no-repeat right center;
			background-size: 15px 15.83px;
			border-bottom: 1px solid $middarkest_neutral;
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			&::placeholder {
				color: $mid_neutral;
				/* Firefox */
				opacity: 1;
			}
			/* Internet Explorer 10-11 */
			&:-ms-input-placeholder {
				color: $mid_neutral;
			}
			/* Microsoft Edge */
			&::-ms-input-placeholder {
				color: $mid_neutral;
			}
		}
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		&::placeholder {
			color: $midlight_neutral;
			/* Firefox */
			opacity: 1;
		}
		/* Internet Explorer 10-11 */
		&:-ms-input-placeholder {
			color: $midlight_neutral;
		}
		/* Microsoft Edge */
		&::-ms-input-placeholder {
			color: $midlight_neutral;
		}
	}
}
.bordered-icon {
	width: 32px;
}
embed {
	width: 100%;
	height: 100vh;
}

input[type="radio"] {
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	/* Remove most all native input styles */
	appearance: none;
	/* For iOS < 15 */
	background-color: var(--form-background);
	/* Not removed via appearance */
	margin: 0;
	font: inherit;
	color: currentColor;
	width: 1.15em;
	height: 1.15em;
	border: 0.15em solid currentColor;
	border-radius: 50%;
	transform: translateY(-0.075em);
	display: grid;
	place-content: center;
}

.error-container {
	background-color: $lightest_neutral;
    padding: 2em;
	border-radius: .25em;
	margin: 1.125em auto;
    width: calc(93% - 4em);
	min-height: 54vh;
	box-shadow: 0 0 10px $midlight_neutral;

	@include darkMode {
		background-color: inherit;
		box-shadow: 0 0 10px $darkest_neutral;
	}
}
.profile-controls {
	margin: 5px 0 0 0;
}
.menu {
	border-radius: .25em;
	background-color: $lightest_neutral;
	margin: 0 .75em 0 0;
	height: 42px;
	box-shadow: 0 0 5px $mid_neutral;
	.dashboard-btn {
		border: none;
		box-shadow: none;
		background-color: $color7;
	}
}
.branding-placard {
	width: 110px;
	padding: .5em .75em .25em .75em;
	border-right: 1px solid $midlight_neutral;
}
.lib-picker {
	background-color: $color7;
	padding: 0 1em;
	height: 42px;
	p {
		font-weight: 300;
		font-size: 0.65em;
		padding: 1.25em 0 0 0;
		margin: 0;
	}
}
.memberfirm-select {
	button {
		width: 60px;
		height: 42px;
		background-image: url("/images/chevron-green-down.svg");
		background-position: calc(100% - 10px) center;
		background-repeat: no-repeat;
		background-size: 10px 5px;
		border-radius: 0 .25em .25em 0;
		&:hover {
			background-image: url("/images/chevron-blue-down.svg");
			background-size: 10px 5px;
		}
		img {
			position: relative;
			left: -10px;
			top: 2px;
			width: 20px;
		}
	}
	a {
		border-bottom: none;
		display: block;
	}
	ul {
		display: none;
		background-color: $lightest_neutral;
		border-radius: 0 0 .25em .25em;
		padding: .05em .25em .35em .25em;
		position: relative;
		margin: 0;
		box-shadow: 0 0 5px $mid_neutral;
		z-index: 2;
		li {
			list-style-type: none;
			border-radius: 0.25em;
			border: 1px solid $light_neutral;
			font-weight: 300;
			font-size: 12px;
			margin: 0.5em 0;
			padding: 0.35em;
			text-transform: uppercase;
			text-align: right;
			transition: border 0.2s ease;
			&:last-child {
				margin: 0.5em 0 0 0;
			}
			&:hover {
				border: 1px solid $middark_cool;
				transition: border 0.2s ease;
			}
			img {
				position: relative;
				top: 2px;
				width: 18px;
				float: left;
			}
		}
		.global {
			padding: .23em .35em .428em .35em;
			img {
				margin: 0 auto;
				display: block;
				float: unset;
				width: 16px;
			}
		}
	}
	&.open {
		button {
			background-color: $color1;
			background-image: url("/images/chevron-white-up.svg");
			background-size: 10px 5px;
			border-radius: 0 .25em 0 0;
		}
		ul {
			display: block;
		}
	}
}
.profile-btn {
	background-image: url("/images/icon-profile.svg"), url("/images/chevron-green-down.svg");
	background-position: 10px center, calc(100% - 10px) center;
	background-repeat: no-repeat, no-repeat;
	background-size: 16px 16px, 10.28px 5px;
	border-radius: 0.25em;
	box-shadow: 0 0 5px $mid_neutral;
	height: 42px;
	width: 55px;
	&:hover {
		background-image: url("/images/icon-profile-bl.svg"), url("/images/chevron-green-down.svg");
		background-size: 16px 16px, 10.28px 5px;
	}
}
.profile {
	&.open {
		.profile-btn {
				background-color: $color1;
				background-image: url("/images/icon-profile-wh.svg"), url("/images/chevron-white-up.svg");
				background-size: 16px 16px, 10.28px 5px;
		}
	}
}
.deleted {
	text-decoration: line-through;
}

.article-highlight {
	background-color: rgba(255,255,0, 0.5);
}
.policy, .subheader {
	font-weight: bold;
}
.left {
	float: left;
}
.right {
	float: right;
}
.hide {
	display:none !important;
}
.visually-hidden {
	position: absolute;
	width:1px;
    height:1px;
	text-indent: -9999px;
}
.clear {
	clear: both;
	display: block;
	height: 0;
}

.recent-search-item {
	height: 30px;
	padding-top: 10px;
	cursor: default;
	padding-left: 15px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	

	&:hover {
		background-color: #e6e6e6;
	}
}

.recent-search-tab {
	color: #0076A8;
	height: 30px;
	padding-top: 6px;
	padding-left:15px;
	text-transform: uppercase;
}

.recent-search-main { 
	width: 100%;
}

.session-modal {
    padding: 0 0 1em 0;
    width: 30%;
    background: $lightest_neutral;
    box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 7px;
    height: auto;
    min-height: 150px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    @include darkMode {
        background-color: $brandedcolor7;
    }

    .btn-session-no {
        margin-right: 10px;
    }

    .session-modal-header {
        background: $brandedcolor7;
		color:$brandedcolor3;
        border-bottom: 1px solid $brandedcolor1;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
        padding: .5em .5em .5em 1em;

        @include darkMode {
            background-color: $brandedcolor19;
            border-bottom: 1px solid $brandedcolor18;
        }

        p {
            font-size: 1.17em;
            margin: 0;
            overflow-wrap: break-word;
        }
    }
    .session-modal-body {
        padding: 10px;
    }
}

.full-screen-darken-overlay-fixed-session {
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 99;
    background-color: #000000;
    padding: 0;
    margin: 0;
    opacity: 0.7;
}

/*Desktop*/
@media (min-width: 1024px) {
	/*------------------------------------*\
    	Global
	\*------------------------------------*/
	.mobile-only {
		display: none !important;
	}
}

/*Mobile/Tablet*/
@media (max-width: 1023px) {
	/*------------------------------------*\
    	Global
	\*------------------------------------*/
	.desktop-only {
		display: none !important;
	}
}


/*------------------------------------*\
    	...LOADING...
\*------------------------------------*/
#load {
	position: absolute;
	width: 600px;
	height: 36px;
	left: 50%;
	top: 40%;
	margin-left: -300px;
	overflow: visible;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
}

#load div {
	position: absolute;
	width: 20px;
	height: 36px;
	opacity: 0;
	animation: move 2s linear infinite;
	-o-animation: move 2s linear infinite;
	-moz-animation: move 2s linear infinite;
	-webkit-animation: move 2s linear infinite;
	transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	color: $middark_neutral;
}

#load div:nth-child(2) {
	animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
}

#load div:nth-child(3) {
	animation-delay: 0.4s;
	-o-animation-delay: 0.4s;
	-webkit-animation-delay: 0.4s;
	-webkit-animation-delay: 0.4s;
}

#load div:nth-child(4) {
	animation-delay: 0.6s;
	-o-animation-delay: 0.6s;
	-moz-animation-delay: 0.6s;
	-webkit-animation-delay: 0.6s;
}

#load div:nth-child(5) {
	animation-delay: 0.8s;
	-o-animation-delay: 0.8s;
	-moz-animation-delay: 0.8s;
	-webkit-animation-delay: 0.8s;
}

#load div:nth-child(6) {
	animation-delay: 1s;
	-o-animation-delay: 1s;
	-moz-animation-delay: 1s;
	-webkit-animation-delay: 1s;
}

#load div:nth-child(7) {
	animation-delay: 1.2s;
	-o-animation-delay: 1.2s;
	-moz-animation-delay: 1.2s;
	-webkit-animation-delay: 1.2s;
}

@keyframes move {
	0% {
		left: 0;
		opacity: 0;
	}

	35% {
		left: 41%;
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 1;
	}

	65% {
		left: 59%;
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 1;
	}

	100% {
		left: 100%;
		-moz-transform: rotate(-180deg);
		-webkit-transform: rotate(-180deg);
		-o-transform: rotate(-180deg);
		transform: rotate(-180deg);
		opacity: 0;
	}
}

@-moz-keyframes move {
	0% {
		left: 0;
		opacity: 0;
	}

	35% {
		left: 41%;
		-moz-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 1;
	}

	65% {
		left: 59%;
		-moz-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 1;
	}

	100% {
		left: 100%;
		-moz-transform: rotate(-180deg);
		transform: rotate(-180deg);
		opacity: 0;
	}
}

@-webkit-keyframes move {
	0% {
		left: 0;
		opacity: 0;
	}

	35% {
		left: 41%;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 1;
	}

	65% {
		left: 59%;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 1;
	}

	100% {
		left: 100%;
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg);
		opacity: 0;
	}
}

@-o-keyframes move {
	0% {
		left: 0;
		opacity: 0;
	}

	35% {
		left: 41%;
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 1;
	}

	65% {
		left: 59%;
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 1;
	}

	100% {
		left: 100%;
		-o-transform: rotate(-180deg);
		transform: rotate(-180deg);
		opacity: 0;
	}
}

/*------------------------------------*\
    	...LOADING MAGIC CIRCLE...
\*------------------------------------*/
.loader {
	position: absolute;
	top: calc(50% - 32px);
	left: calc(50% - 32px);
	width: 64px;
	height: 64px;
	border-radius: 50%;
	perspective: 800px;
}

.inner {
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.inner.one {
	left: 0%;
	top: 0%;
	animation: rotate-one 1s linear infinite;
	border-bottom: 5px solid $midlight_neutral;
}

.inner.two {
	right: 0%;
	top: 0%;
	animation: rotate-two 1s linear infinite;
	border-right: 5px solid $middark_neutral;
}

.inner.three {
	right: 0%;
	bottom: 0%;
	animation: rotate-three 1s linear infinite;
	border-top: 5px solid $darkest_neutral;
}

@keyframes rotate-one {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}

	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@keyframes rotate-two {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}

	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@keyframes rotate-three {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}

	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

/*------------------------------------*\
    	...TOC Loading Shimmer...
	$lightest_neutral background color!
	$light_neutral
	$midlight_neutral
	$middark_neutral
\*------------------------------------*/
.shimmerArticleCard {
	.shimmerArticleCardBG {
		animation-duration: 3.8s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: shimmerAnimate;
		animation-timing-function: linear;
		background: $lightest_neutral;
		background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
		background-size: 1200px 100%;

		@include darkMode {
			background: linear-gradient(to right, #333 8%, #555 18%, #333 33%);
		}
	}

	@-webkit-keyframes shimmerAnimate {
		0% {
			background-position: -100% 0;
		}

		100% {
			background-position: 100% 0;
		}
	}

	@keyframes shimmerAnimate {
		0% {
			background-position: -1200px 0;
		}

		100% {
			background-position: 1200px 0;
		}
	}

	.shimmerArticleCardTitleLine {
		height: 30px;
		width: 65%;
		margin-left: 35px;
		margin-bottom: 12px;
		border-radius: 6px;
	}

	.shimmerArticleCardIntroLine {
		height: 25px;
		width: 55%;
		margin-bottom: 12px;
		border-radius: 7px;
	}

	.shimmerArticleCardContentLine {
		height: 20px;
		width: 85%;
		margin-bottom: 6px;
		border-radius: 4px;
	}

	.shimmerArticleCardContentLineEnd {
		width: 40%;
	}
}

.shimmerArticleWrapper {
	margin-top: 15px;
}

/*------------------------------------*\
    	...Loading Circle...
	$lightest_neutral == background color!
	$brandedcolor27 == crumbtrail background color
	$light_neutral
	$midlight_neutral
	$middark_neutral
\*------------------------------------*/
$offset: 187;
$duration: 1.4s;

.tocWrapper {
	margin-left: 3px;
	margin-bottom: 20px
}

.tocSpinnerWrapper {
	margin-right: 14px;
	width: 14px;
	height: 14px;
	display: block;
}

.spinner {
	animation: rotator $duration linear infinite;
}

.tocSpinnerText {
	color: $midlight_neutral;
}

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(270deg);
	}
}

.path {
	stroke-dasharray: $offset;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash $duration ease-in-out infinite, colors ($duration*4) ease-in-out infinite;
}


@keyframes colors {
	0% {
		stroke: $lightest_neutral;
	}

	25% {
		stroke: $light_neutral;
	}

	50% {
		stroke: $midlight_neutral;
	}

	75% {
		stroke: $middark_neutral;
	}

	100% {
		stroke: $darkest_neutral;

		@include darkMode {
			stroke: #FFF;
		}
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: $offset;
	}

	50% {
		stroke-dashoffset: $offset/4;
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: $offset;
		transform: rotate(450deg);
	}
}

// ChatBot
	   .conversation-container-header {
		//padding: 6px 25px 5px;
    	max-height: 37px;
    	box-sizing: border-box;
    	background-color: #e9e9e9;
     	z-index: 4;
    	position: sticky;
		//justify-content: space-between;
	
		@include darkMode {
			background-color: #111111;

		}
		.container-box-header {
			display: flex;
			width: 88%;
			justify-content: space-between;
		   }
		.container-main {
	display: flex;
    align-items: center;
    justify-content: space-evenly;
}
	
		.logo {
			margin: 0;
			position: relative;
			//bottom: 7px;
		}
		@media (min-width: 768px) {
			.logo {
				max-width: 180px;
			}
		}

		.deloitte-rp-logo {
			//margin: 10px 125px 0 0;
			.primary-logo {
				width: 87px;
				max-width: 87px;
				margin: 0 0 0 30px;
			}
			
		}
		.conversation-container-heading {
			//bottom: 6px;
			//position: relative;
			font-size: 1em;
		}
		.header-buttons {
			display: flex;
			align-items: center;
			//float: right;
			//position: absolute;
			//right: 100px;
			//bottom: 6px;
			cursor: pointer;
        .support {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 24px 24px;
			width: 24px;
			height: 24px;
			margin: 0 10px 0 0;
			padding: 0 0 0 15px;
			color: var(--a-tag-color);
			text-decoration: none;
			background-image: url(/images/question_mark-default.svg);
			@include darkMode {
				background-image: url(/images/question-mark-default.svg);
			}
			&:hover {
				background-image: url(/images/question_mark-hover.svg);
				@include darkMode {
					background-image: url(/images/question-mark-hover.svg);
				}
			}
			&:active {
				background-image: url(/images/question_mark-pressed.svg);
				@include darkMode {
					background-image: url(/images/question-mark-pressed.svg);
				}
			}
		
		
		}
		.profile-main {
			position: relative;
			height: 43px;
			width: 57px;
			margin: auto 0;
			z-index: 5;
			
		}
		.profile {
	background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    height: 25px;
    width: 25px;
    margin: 6px 0 0 0;
    cursor: pointer;
    color: #000000;
    font-size: 10px;
		
		 .user-initials {
			text-align: center;
			font-weight: 500;
			display: block;
			position: relative;
			top: 6px;
		}
		 .default-profile {
			position: relative;
			bottom: 5px;
		}
	}
	.page-settings {
		width: 170px;
		border: 1px solid $brandedcolor1;
		position: absolute;
		right: 0;
		top: 37px;
		border-radius: 5px;
		border-top-right-radius: 0;
		color: inherit;
		z-index: 1;
		background: #f5f5f5;
		background: var(--theme-bg);
		overflow: hidden;
		box-shadow: 0 4px 4px #b8b8b885;
	  
		@include darkMode {
		  background: $dark_mode_bg;
		  border-color: $color13;
		  box-shadow: 0 4px 4px #07070785;
		}
	  
		.page-settings-text {
		  padding: 18px 8px 6px 8px;
		  font-size: 11px;
		  display: block;
	  
		  @include darkMode {
			color: $light_neutral;
		  }
	  
		}	  
		.divide-bar{
			width: 100%;
			height: 1.5px;
			background-color: #ccc;
			border: none;
		}
	  }
	
	   }

	}
	   .conversation-main {

			height: calc(100vh - 225px);
			// background: #fdfcf7;
			width: 85%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 10%;
	   }
	   .search-assist {
		width: 80%;
		margin-left: 10%;
		.research-assistant-conversation {
			display: flex;
			width: 100%;
			margin-top: 35px;
			.search-input-box {
				width: 60%;
				height: 40px;
				.chatbot-input {
					box-sizing: border-box;
					border: 1px solid #ccc;
					font-size: 13px;
					background-color: white;
					background-image: url(/images/chatbot-search-assis.png);
					background-position: 10px 7px;
					background-repeat: no-repeat;
					padding: 12px 15px 10px 40px;
					background-size: 23px;
					width: 100%;
					height: 40px;
					border-radius: 4px 0 0 4px;
					font-weight: 600;
					resize: none;
					font-family: sans-serif, "Roboto", Verdana;

					@include darkMode {
						background-image: url(/images/chatbot-search-assist.png);
						background-color: #333333;
						color: #f5f5f5;
						border: 1px solid #444444;
						//border: 1px solid #444444;
					}
				}
				.textarea-input-scroll {
					position: absolute;
					bottom: 40px;
					border-bottom: 0;
					border-bottom-left-radius: 0;
					background-image: none;
					padding: 5px 10px 6px 10px;
					height: auto;
					width: 90%;
					border-top-right-radius: 4px;
					@include darkMode {
						background-image: none !important;
					}
				}
				.chatbot-input-label {
					display: block;
					border-top: 0;
					border-top-left-radius: 0;
				}

				@include darkMode {
					//background-color: #333333;
					//border: 1px solid #444444;
				}
			}
			.memberfirm-list {
				width: 15%;
				//pointer-events: none;
				.dd {
					width: 100%;
					.home-mf__trigger-label {
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: left;
					}
				}
				#category-dropdown {
			
					div button {
						height: 40px;
						border-radius: 0;
						border: 1px solid #ccc;


						@include darkMode {
							background-color: #333333;
							color: #D7D7D7;
							border: none;
							border-left: 1px solid #666666;
							background-image: url(/images/dropdown-arrow-blue-default-darkmode.svg);
						}
					}
					 .dd__container {
						//top: -92px;
						top:0;
						
					
					}
					.home-mf__containers {
						right: 0;
						//transform: translateX(-100%);
					}
				
					.an-dd-container-dropdown {
						top: auto;
						bottom: 0;
						margin-bottom: 40px;
						width: 275px;
						right: 0;
						left: auto;
					}
					.researchassistant-panel {
						position: absolute;
						bottom: 100%;
						width: 100%;
						border: 1px solid #d9d9d9;

						@include darkMode {
							border: 1px solid #757575;;
						}
						//box-shadow: 0 4px 4px #b8b8b885;
						.dd__scroll-area {
							//overflow-y: scroll;
							min-height: 88px;
						}
					}

				} 
				.home-mf__trigger-button {
					border-radius: 0;
				}
			}
			.tier-list {
				width: 15%;
				//pointer-events: none;
				.dd {
					width: 100%;
					.home-mf__trigger-label {
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: left;
					}
					.an-dd-category-label-dropdown {
						margin-left: auto;
					}
					.an-dd-container-dropdown {
						top: auto;
						bottom: 30px;
					}
				}
				#category-dropdown {
		
					.dd__trigger-button {
						height: 40px;
						border-radius: 0;
						border: 1px solid #ccc;

						@include darkMode {
							background-color: #333333;
							color: #D7D7D7;
							border: none;
							border-left: 1px solid #666666;
							background-image: url(/images/dropdown-arrow-blue-default-darkmode.svg);
						}
					}
                  .dd__container {
					top: auto;
					bottom: 39px;
					left: 0;
					box-shadow: none;
					background-color: none;
				  }

				  .dropdown-wrapper {
					grid-template-columns: 177px 1fr;
				  }

				  .uk-selector__menus {
                   	min-height: auto;
					min-width: 177px;
					border: none;
					background-color: transparent;
				
				  }
                 
				  .dropdown__submenu {
					min-height: 200px;
					max-height: 800px;

				}
				.uk-selector__menu-list {
					min-height: auto;
					
					.uk-selector__option {
						padding: auto;
						background-color: white;
						align-items: center;
						padding-left: 20px;
					}
					.uk-selector__option :hover {
						background-color: #2697a8;
                        color: #f5f5f5;  	
						//background-color: $middark_cool;
					}
					.dropdown-btn {
						background-color: #2697a8;
                         color: #f5f5f5;
						 height: 40px;
						 border-radius: 0;
    					 border: 1px solid #ccc;
						 @include darkMode {
							border: none;

						 }
						
					}
				
				}
				.showed {
					border-left: none;
					border-top: none;
					position: relative;
					//display: flex;
					position: sticky;
                    align-content: flex-end;
					background-color: transparent;

				}
				// .submenu-col {
				// 	width: 100%;
				// }

				}


				.framework-lists{
					background-color: var(--theme-bg);
				}
				.frameworks-panel {
					border: 1px solid; 
    				 border-radius: 4px; 
    				 border-top-left-radius: 0; 
     				border-color: #d9d9d9; 
    				 box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
					 @include darkMode {
						border: 1px solid #757575;;
					}
				}
				
				// .submenu-item {
				// 	//width: 20px;
				// 	min-height: 100px;
				// }
				
				// .menu-item {
				// 	width: 100px;
				// 	height: 100px;
				// }

				// .small-dropdown {
				// 	//width: 100px;
				// 	min-height: 60px;
				// 	max-height: 60px;
				// }
			}
			.send-message {
				width: 10%;
				padding: 4px;
				box-sizing: border-box;
				border: 1px solid #ccc;
				border-radius: 0 4px 4px 0;

				.move-catalog {
					width: 100%;
					height: 100%;
					background: #0076A8;
					border: 0;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					.right-arrow {
						width: 18px;
					}
					&:disabled {
						background: #D0D0CE;
					}
				}
				@include darkMode {
					background-color: #333333;
					color: #D7D7D7;
					border: none;
					border-left: 1px solid #666666;

				}
				@include darkMode {
					background-color: #333333;
					color: #D7D7D7;
					border: none;
					border-left: 1px solid #666666;

				}
			}
		}
		.disclaimer-container.hide-followup {
			margin-top: 20px !important;
		}

		.disclaimer-container.hide-footer {
			display: none;
		}
		
		.disclaimer-container {
			width: 100%;
			display: flex;
			justify-content: center;
			text-align: center;
			margin-top: 40px;
			.genai-disclaimer {
				width: 80%;
				
			}
			.disclaimer {

				@include darkMode {
				color: #D7D7D7;
				}
			}
		}
		.search-assist-body{
			position: relative;

			@include darkMode {
				//background-color: #111111;
			}
		}
		.followup-sections{
			position: absolute;
			bottom: -42px;
			width: 100%;
			background: #fff;
			z-index: 2;

			@include darkMode {
			background-color:#212121;
				//background-color: #333333;
			}
		}
		.show-followup-questions{
			border: 1px solid #D7D7D7;
            border-radius: 6px;

			@include darkMode {
				//background-color:#212121;
					background-color: #333333;
					border: 1px solid #666666;
					
				}
		}
		.followup-questions-container{

			.followup-questions-close{
				right: 10px;
				position: absolute;
				top: 3px;
				cursor: pointer;
				font-size: 22px;
				line-height: 1;
				direction: ltr;
			}
			.research-assistant-suggestion-container{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-start;
				padding: 10px 40px;
				max-width: 100%;
				box-sizing: border-box;
				.research-assistant-suggestions{
					background: #e9e9e9eb;
					padding: 4px 10px 4px 10px;
					border-radius: 4px;
					margin: 5px;
					width: max-content;

					@include darkMode {
						background-color: #212121;
						//background-color: #333333;
						border:1px solid #0076A8;

					}
					.research-assistant-suggestions-item {
							font-size: 12px;
							font-weight: 500;
							cursor: pointer;
						}
					}
			}
			.followup-questions-title{
				display: flex;
				align-items: center;
				padding: 5px 0px 5px 12px;
				cursor: pointer;
				.hidden-icon{
					display: none;
				}
				.right-arrow{
					width: 20px;
					height: 20px;
					transform: rotate(-90deg);
				}
				.down-arrow{
					width: 20px;
					height: 20px;
				}
				h3{
					font-size: 14px;
					color: #0076a8;
					font-weight: 600;
					margin-left: 10px;
				}
			}
		}
   }

		.assist-content {
			display: flex;
			// height: 100%;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: auto;
			background: #F8FCF7;
			padding: 30px 20px;
			box-sizing: border-box;
			border-radius: 12px;
		}

.chat-assist-image {

    width: 55px;
}

.search-assistant {
	display: flex;
	width: 100%;
	padding: 10px;
	margin-top: 12px;

	.search-assistant-ico {
		width: 35px;

		.search-assistant-ico-img {
			background: url(/images/chatbot-search-assis.png) no-repeat 100%;
			background-size: 25px 25px;
			border: none;
			padding-left: 18px;
			height: 35px;
			margin-top: -10px;
			cursor: default;

			@include darkMode {
				background: url(/images/chatbot-search-assist.png) no-repeat 100%;
				background-size: 25px 25px;
			}
		}
	}
	.research-assistant-popup {
		position: fixed;
		width: 100vw;
		height: 100vh;
		right: 0px;
		top: 0px;
		z-index: 7;
		background-color: #000000;
		padding: 0;
		margin: 0;
		opacity: 0.7;
	}
	.research-assistant-modal {
		padding: 0;
		width: 50%;
		background: #ffffff;
		box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
		border-radius: 7px;
		/* height: auto; */
		/* min-height: 240px; */
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 7;
	
		@include darkMode {
			background-color: #212121;
			color: #f5f5f5;
		}
		.modal-header {
			background: #F7F7F7;
			border-bottom: 1px solid #d9d9d9;
			padding: .5em 1em .5em;
			display: flex;
			align-items: center;
			justify-content: space-between;	
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
			@include darkMode {
				background-color: #212121;
				border-bottom: 1px solid #c2c2c2;
			}
			.modal-title {
				font-weight: bold;
			}
			.share-modal-close {
				background: var(--icon-close) no-repeat 50%;
				background-size: 20px 20px;
				height: 20px;
				border: none;
				@include darkMode {
					background: var(--icon-close-soft) no-repeat 50%;
				}
				&:hover {
					transform: rotate(90deg);
					transition: transform .2s ease;
				}
			}
		}
		.modal-body {
			padding: 0 35px 0;
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;
			overflow-y: auto;
			max-height: 400px;
			@include darkMode {
				background-color: #212121;
			}
			.modal-content {
				display: inline-block;
				font-family: "Roboto", Verdana, sans-serif;
				@include darkMode {
				}
				.modal-info {
					margin-top: 20px;
					margin-bottom: 20px;
				}
			}
			.tostitle {
				display: block;
				text-align: center;
			}
			.expandButtonContainer {
				text-align: center;
				.expandTandC {
					display: inline-block;
					font-weight: bold;
					cursor: pointer;
					color: #00a3e0;
					padding: 10px 0;
				}
			}
		}
	}
	.search-assistant-body {
		width: 95%;
		position: relative;
		.ra-info-icon {
			width: 14px;
			cursor: pointer;
			margin-left: 8px;
			margin-bottom: -1px;
		}
		.search-assistant-title {
			font-weight: 500;
			position: relative;
			display: flex;
			align-items: center;
			.question-icon {
				cursor: pointer;
				font-size: 11px;
				margin: 0 10px;
				padding: 0px 4px;
				color: #0076a8;
				border: 1.9px solid;
				border-radius: 46px;
				font-weight: bolder;
			}
		}
		.intro-content {
			font-size: 14px;
			line-height: 1.5em;
			width: 95%;
			font-family: "Roboto", Verdana, sans-serif;
		}

		.search-assistant-response-container {
			display: flex;
			position: relative;
			.research-assistant-loader {
				width: 100%;
			}

			.search-assistant-response {
				width: 95%;
				position: relative;
				.footnote-link {
					font-weight: bold;
					color: #0076a8;
					&:hover {
						color: #012169;
					}
				}
				.footnote-comma:hover {
					color: #0076a8;
				}
				.filter-value
                        {
							margin-top: 15px;
                            label{
                                font-size: 14px;
                                font-weight: 500;
                            }
					.conditional-sites-string {
						font-size: 14px;
    					font-weight: 500;	
	                  margin-top: 20px;

}
                        }
			}

			.search-assistant-response-content {
				height: auto;
				overflow: hidden;
				font-size: 14px;
				line-height: 1.5em;
				font-family: "Roboto", Verdana, sans-serif;
				max-height: 100px;
				padding-right: 70px;
				p{
					font-size: 14px !important;
				}
			}
			.opacity-box {
				position: absolute;
				bottom: 0;
				display: block;
				right: 0;
				height: 10px;
				width: 26px;
				background: #f8fcf7;
				margin-right: 22px;
				opacity: 0.7;
				z-index: 1;
				@include darkMode {
					background: #212121;
				}
			}

			.research-assistant-suggestion {
				font-size: 14px;
				line-height: 1.6;
				font-weight: 500;
				margin: 1em 0 1em 0;
				font-family: "Roboto", Verdana, sans-serif;
			}

			.research-assistant-responses {
				font-size: 14px;
				line-height: 1.6;
				margin: 0.5em 0;
				font-family: "Roboto", Verdana, sans-serif;
				padding-right: 25px;
				p{
					font-size: 14px !important;
				}
				ol li, ul li {
					p{
						margin: 0 !important;
					}
				}
			}

			.reasearch-assistant-generate {
				font-weight: 500
			}

			.icon-container {
				display: flex;
				flex-shrink: 0;
				margin-top: auto;
				margin-bottom: auto;
				margin-right: 10px;
			}

			.research-assistant-forms {
				font-size: 14px;
				line-height: 1.6;
				margin: 10px 0;
				font-family: "Roboto", Verdana, sans-serif;

				.form-title {
					display: flex;
					align-items: center;
					cursor: pointer;

					.up-down-arrow {
						width: 16px;
						height: 9px;
					}
					.right-arrow {
						width: 9px;
						height: 15px;
					}

					.hidden-icon {
						display: none;
					}

					.research-assistant-forms-heading {
						margin-left: 14px;
					}

					
				}

				.conditional-strings {
					font-size: 14px;
				}
			}

			.research-assistant-citation-data {
				font-size: 14px;
				margin: 5px 0 5px 27px;
				font-family: "Roboto", Verdana, sans-serif;
				display: flex;
				align-items: flex-start;

				.form-source-icon-container {
					display: flex;
					flex-shrink: 0;
				   
				}	
					.form-source-icon {
					width: 28px;
					width: 100%;
					height: 28px;
					}

				.ra-dependency-links {
					text-decoration: underline;
				}

				svg.annotation_comment {
					vertical-align: middle;
					margin-left: 7px;
				}

				.goto-next-icon {
					width: 10px;
					vertical-align: middle;
					margin-left: 7px;
					border-radius: 2px;
					cursor: pointer;
				}
			}

			.search-assistant-share-img {
				background: url(/images/chatbot-share.png) no-repeat 100%;
				background-size: 25px 25px;
				border: none;
				padding-left: 18px;
				height: 40px;
				margin-top: -10px;

				@include darkMode {
					background: url(/images/chatbot-share-white-img.png) no-repeat 100%;
					background-size: 25px 25px;
				}
			}

			.search-assistant-thumb-up-img {
				background: url(/images/chatbot-thumbs-up.png) no-repeat 100%;
				background-size: 25px 25px;
				border: none;
				padding-left: 18px;
				height: 40px;
				margin-top: -10px;


				@include darkMode {
					background: url(/images/white-thumbs-up.png) no-repeat 100%;
					background-size: 25px 25px;
				}
			}

			.search-assistant-thumb-down-img {
				background: url(/images/chatbot-thumbs-down.png) no-repeat 100%;
				background-size: 25px 25px;
				border: none;
				padding-left: 18px;
				height: 40px;
				margin-top: -10px;
				@include darkMode {
					background: url(/images/white-thumbs-down.png) no-repeat 100%;
					background-size: 25px 25px;
				}
			}

			.search-assistant-help-button {
				margin-top: 14px;
				margin-left: 20px;
				width: 50px;
				position: absolute;
				right: 0;
				overflow-y: hidden;
				top: 0;
				bottom: 0;
				padding-top: 2px;
			}
		}

		.ask-followup-question-button {
			background: #0076A8;
			border: 0;
			display: flex;
			align-items: center;
			justify-content: left;
			color: #fff;
			font-weight: 500;
			padding: 12px 15px;
			box-sizing: border-box;
			border-radius: 5px;
			cursor: pointer;

			.chat-icon-img {
				width: 16px;
			}

			.ask-followup-label {
				padding: 0px 14px;
				cursor: pointer;
			}

			.goto-next-icon {
				width: 12px
			}
		}

		.search-expand {
			display: grid;

			.read-more-button {
				background: none;
				border: none;
				color: #0076a8;
				font-weight: 600;
				margin-top: 5px;
			}
		}

		.ai-content-disclaimer{
			color: gray;
            font-size: 12px;
		}
	}
}
.resolve-document-source, .resolve-form-source{
	display: flex;
	//padding-left: 10px;
	font-family: "Roboto", Verdana, sans-serif;
    align-items: flex-start;
	.footnote-click {
		//bottom: 0;
		padding: 0px 15px 0 10px;
		//padding-right : 15px;

	}
	.sourcelink {
		padding-left: 5px;
		border: none;
		background: transparent;
		text-decoration: underline;
		cursor: pointer;
		font-style:italic;
		text-align: left;
	}
	.source-icon-container {
	display: flex;
    flex-shrink: 0;
   
}	
	.source-icon {
		//margin-top: -5px;
		height: 24px;
		width: 24px;

		@include darkMode {
			filter: invert(100%);
		}
	}
}

.new-convo {
	width: 100%; 
	text-align: center; 
	border-bottom: 1.5px solid #ccc ; 
	line-height: 0.1em;
	margin: 30px 0 30px; 
}

.new-convo .new-convo-msg {

	background:#fff; 
	color: #808080;
    padding:0 10px; 
	font-size: 12px;

	@include darkMode {
		background: #212121;
		color: #808080;
	}
}

.tosdeclaration-title{
	text-align: center;
}
.tosdeclaration-heading{
	font-size: 14px !important;
	font-weight: 600 !important;
}
.tosdeclaration-content{
	ul, ol {
		margin: 10px 0 0 0;
		color: #000000;
		padding: 0 0 0 13px !important;
		font-size: 14px;
	}
	p {
		font-size: 14px;
	}
}

.tosdeclaration-footer{
	.agree{
		background: #0076A8;
		color: white;
		padding: 5px 10px;
		border-radius: 5px;
		border: none;
		margin-top: 20px;
		}
}

.search-assistant-response-content-loader {
	height: 44.81px;
	overflow: hidden;
	font-size: 14px;
	margin-top: 15px;
	font-family: "Roboto", Verdana, sans-serif;
}

.textbracket {
	font-size: 12px;
}
#text-wrapper {
	height: 22.41px;
}
#text-info {
	height: 22.41px;
}

.loading-chatbot {
	margin: 0 20px 0 0;
	line-height: 1.6;
	height: 44.81px;
}
.loader-title1 {
	animation-name: ra-loader-title-1;
}
.loader-title2 {
	animation-name: ra-loader-title-2;
}
.loader-title3 {
	animation-name: ra-loader-title-3;
}
.loader-title4 {
	animation-name: ra-loader-title-4;
}
.loader-title5 {
	animation-name: ra-loader-title-5;
}
.loader-description1 {
	animation-name: ra-loader-description-1;
}
.loader-description2 {
	animation-name: ra-loader-description-2;
}
.loader-description3 {
	animation-name: ra-loader-description-3;
}
.loader-description4 {
	animation-name: ra-loader-description-4;
}
.loader-description5 {
	animation-name: ra-loader-description-5;
}
@keyframes ra-loader-title-1 {
	0% {
		position: relative;
		top: 0;
	}
	75% {
		position: relative;
		top: 0;
	}
	100% {
		position: relative;
		top: -55px;
	}
}
@keyframes ra-loader-description-1 {	
	0% {
		position: relative;
		top: 0;
	}
	75% {
		position: relative;
		top: 0;
	}
	100% {
		position: relative;
		top: -55px;
	}
}

@keyframes ra-loader-title-2 {
	0% {
		position: relative;
		top: 55px;
	}
	25% {
		position: relative;
		top: 0;
	}
	75% {
		position: relative;
		top: 0;
	}
	100% {
		position: relative;
		top: -55px;
	}
}
@keyframes ra-loader-description-2 {
	0% {
		position: relative;
		top: 55px;
	}
	30% {
		position: relative;
		top: 0;
	}
	75% {
		position: relative;
		top: 0;
	}
	100% {
		position: relative;
		top: -55px;
	}
}
@keyframes ra-loader-title-3 {
	0% {
		position: relative;
		top: 55px;
	}
	25% {
		position: relative;
		top: 0;
	}
	75% {
		position: relative;
		top: 0;
	}
	100% {
		position: relative;
		top: -55px;
	}
}
@keyframes ra-loader-description-3 {
	0% {
		position: relative;
		top: 55px;
	}
	30% {
		position: relative;
		top: 0;
	}
	75% {
		position: relative;
		top: 0;
	}
	100% {
		position: relative;
		top: -55px;
	}
}
@keyframes ra-loader-title-4 {
	0% {
		position: relative;
		top: 55px;
	}
	25% {
		position: relative;
		top: 0;
	}
	75% {
		position: relative;
		top: 0;
	}
	100% {
		position: relative;
		top: -55px;
	}
}
@keyframes ra-loader-description-4 {
	0% {
		position: relative;
		top: 55px;
	}
	30% {
		position: relative;
		top: 0;
	}
	75% {
		position: relative;
		top: 0;
	}
	100% {
		position: relative;
		top: -55px;
	}
}
@keyframes ra-loader-title-5 {
	0% {
		position: relative;
		top: 55px;
	}
	25% {
		position: relative;
		top: 0;
	}
}
@keyframes ra-loader-description-5 {
	0% {
		position: relative;
		top: 55px;
	}
	30% {
		position: relative;
		top: 0;
	}
}

.loading {
	font-size: 14px;
	font-family: "Roboto", Verdana, sans-serif;
	font-weight: bold;
	transform-style: preserve-3d ;
	span {
	  display: inline-block;
	}
}

.loading02 {
	span {
	  animation: loading02 5s linear .5s alternate;
	}
}
@keyframes loading02 {
	0% {
	  opacity: 1;
	}
	5% {
		 opacity: .1;
	}
	35% {
		 opacity: 1;
	}
	55% {
		 opacity: .1;
	}
	85% {
		 opacity: 1;
	}
	100% {
	  opacity: 1;
	}
  }
  