/*Additional Branded Colors, as of 4/1/22*/
$brandedcolor1: #d9d9d9; /*med light gray. borders*/
$brandedcolor2: #012169; /*blue 6*/
$brandedcolor3: #f0f0f0; /*opaque version of black, Alpha 06*/
$brandedcolor4: #041e42; /*blue 7*/
$brandedcolor5: #0076a8; /*blue 4*/
$brandedcolor6: #63666a; /*cool gray*/
$brandedcolor7: #212121; /*opaque version of black, Alpha 87*/
$brandedcolor8: #00a3e0; /*blue 3*/
$brandedcolor9: #97999b; /*cool gray 7*/
$brandedcolor10: #e0e0e0; /*opaque version of black, Alpha 12*/
$brandedcolor11: rgba(0, 163, 224, 0.24); /*blue 3, Alpha 24*/
$brandedcolor12: rgba(255, 255, 255, 0.87); /*white, Alpha 87*/
$brandedcolor13: #005587; /*blue 5*/
$brandedcolor14: rgba(0, 85, 135, 0.75); /*blue 5, Alpha 75*/
$brandedcolor15: #62b5e5; /*blue 2*/
$brandedcolor16: #f2f2f2; /*light gray*/
$brandedcolor17: #53565a; /*cool gray 10*/
$brandedcolor18: #c2c2c2; /*opaque version of black, Alpha 24*/
$brandedcolor19: rgba(0, 0, 0, 0.54); /*black, Alpha 54*/
$brandedcolor20: rgba(0, 0, 0, 0.03); /*black, Alpha 3*/
$brandedcolor21: #75787b; /*cool gray 8*/
$brandedcolor22: #a3a3a3; /*opaque version of  black, Alpha 36*/
$brandedcolor23: #a0dcff; /*blue 1*/
$brandedcolor24: rgba(255, 255, 255, 0.24); /*white, Alpha 24*/
$brandedcolor25: rgba(0, 0, 0, 0.24); /*black, Alpha 24*/
$brandedcolor26: rgba(255, 255, 255, 0.54); /*white, Alpha 54*/
$brandedcolor27: #e9e9e9;
$brandedcolor28: #343639;
$brandedcolor29: #9a9fa4;
$brandedcolor30: #d9f1f7; /* med light blue*/

/*Black, White, Grays*/
$lightest_neutral: #ffffff;
$lighter_neutral: #f5f5f5;
$light_neutral: #e8e8e8;

$midlight_neutral: #cccccc;
$mid_neutral: #999999;
$middark_neutral: #666666;
$middarker_neutral: #444444;
$middarkest_neutral: #333333;
$dark_neutral: #222222;
$darker_neutral: #111111;
$darkest_neutral: #000000;

$dark_mode_bg: $brandedcolor7;
$dark_mode_border: #757575;

/*Greens*/
$mid_cool: #43b02a;
/*Deloitte Green*/
$midlight_cool: #86BC24;

/*Blues*/
$middark_cool: #0097a9;


/*New colors - TODO: rename these once a full idea is gained on how these are used compared to what was defined above*/
$color1: #00b500; /*Bright Green*/
$color2: #338720; /*used on the link underline on hover.*/
$color4: #808080; /*used on the Annotation's text color and breadcrumb link color.*/
$color5: #4d4d4d; /*used on the General Compoennt's (Dynamic Component, Q&A Component) title, on hover.*/
$color6: #e6e6e6; /*used on embedded research border. used on the interactive toolbar's box shadow color.*/
$color7: #fafafa; /*used on See Also, bottom background color of Catalog, bg color of toc and toc accordion, and bg color of Manual Landing's popular links/recent update containers.*/
$color8: #e7f2d3; /*light green. used on the background color of the GAAP flag*/
$color9: #0072b5; /*blue. Used around the border of the text block in Article*/
$color10: #b52600; /*red. Used around the border of Pitfalls (Figure Component)*/
$color11: #ffede8; /*light orange. used on the background color of the GAAP flag*/
$color12: #e6e6fd; /*light blue. used on the background color of the GAAP flag*/
$color13: #757575; /*light gray. used on active breadcrumb in dark mode*/
$color14: #fffcf0; /*light yellow. used on the wormhole notification*/
$color15: #fbf0ef; /*light red. used to error notification*/

/*Add Member Firm button colors*/
$amf_default_bg: #f7f7f7;
$amf_active_bg:#d9f1f7;

$amf_dark_default:#00a3e0;
$amf_dark_hover:#a0dcff;
$amf_dark_active:#041342;