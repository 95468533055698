.loading-index {

    z-index: -1;
     position: 'absolute';
    top: 0;
    left: 0;
    right: 0p;
    bottom: 0;
        display: 'flex';
        align-items: 'center';
        justify-content: 'center';
        justify-items: 'center';
}