.pdf {
    .pdf-page {
        border: 1px solid var(--main-border-color);

        .linkAnnotation a {
            border: none;
            position: absolute !important;

            &::after {
                display: none;
            }
        }
    }

    .pdf-pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 14px 0 13px;

        button {
            appearance: none;
            background: var(--btn-search-icon-article-bg-color);
            background-image: url(/images/chevron-right-default.svg);
            background-position: center center;
            background-size: 28px 28px;
            background-repeat: no-repeat;
            padding: 16px 16px;
            // border: 1px solid var(--main-border-color);
            border: none;
            border-radius: 5px;
            transition: border 0.2s ease;
            width: auto;
            margin: 0;

            &:first-of-type {
                transform: rotate(180deg);

                &:hover{
                    background-image: url(/images/chevron-right-hover.svg);
                }

                &:active{
                    background-image: url(/images/chevron-right-pressed.svg);
                }
            }

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }

            &:not(:disabled):hover {
                background-image: url(/images/chevron-right-hover.svg);
            }

            &:not(:disabled):active {
                background-image: url(/images/chevron-right-pressed.svg);
            }
        }

        span {
            display: inline-block;
            margin: 0 12px;
            font-size: 12px;
        }
    }

    ::selection {
        @include darkMode {
            background: $middarkest_neutral;
            color: $lightest_neutral;
            text-shadow: none;
        }
    }
}
