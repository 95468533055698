.searchbar-next {
    position: sticky;
    top: 1rem;
    
    width: 100%;
    padding: 0;
    margin: 0 0 2em 0;

    font-size: 13px;

    background-color: #fff;
    background-size: 20px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 15px #ccc;

    @include darkMode {
        background-color: $brandedcolor7;
        border-color: #444;
        box-shadow: 0px 0px 15px #444;
        color: #fff;
    }

   // &:active,
    // &:focus-within > input {
    //     border: 1px solid black;
    // }
}

.searchbar-next-icon {
    flex-basis: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        margin: 0;
    }
}

.searchbar-next-input {
    flex-basis: 80%;
    flex-grow: 1;
    flex-shrink: 1;

    min-height: 50px;

    border: 0 none;

    @include darkMode {
        background-color: $brandedcolor7;
        color: #fff;
    }
}

.searchbar-next-counter {
    flex-basis: 50px;
    flex-grow: 0;
    flex-shrink: 1;
    justify-self: flex-end;

    display: flex;
    justify-content: center;
    align-items: center;

	padding: 0 .5rem;

    font-size: .9rem;
	border-right: 1px solid #ccc;
	color: #333;

    @include darkMode {
        color: #fff;
        border-color: #444;
    }
}

.searchbar-next-btns {
    flex-basis: 50px;
    flex-grow: 0;
    flex-shrink: 1;

    display: flex;
    justify-content: center;
    align-items: stretch;

	padding: 0;

	.searchbar-next-down {
        flex-basis: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0;

        background-color: transparent;
        border: 0 none;
        cursor: pointer;
        text-decoration: none;

        &:disabled {
			//cursor: not-allowed;
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);		  
		}

        img {
            margin: 0;
            transform: rotate(90deg);
        }
	}

	.searchbar-next-up {
        flex-basis: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0;

        background-color: transparent;
        border: 0 none;
        cursor: pointer;
        text-decoration: none;

        &:disabled {
			//cursor: not-allowed;
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);		  
		}

        img {
            margin: 0;
            transform: rotate(-90deg);
        }
	}
}

.searchbar-next-more {
	font-weight: bold;
    font-size: .8rem;
}