/*------------------------------------*\
    Trumps
\*------------------------------------*/

.prevent-transitions {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    transition: none !important;
}

q {
	&::before, &::after {
		content: "";
	}
}

.article-container-reading-mode {
	&.article-container {
		a[target="_blank"] {
			margin: 0;
			&:after {
				background-image: unset !important;
			}
		}
	}
}
