
.searchbar-publication-landing {
	padding: 0;
	margin: 0;
    width: calc(100% - 45px);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 45px;
}

.searchbar-publication-landing-disabled {
	cursor: not-allowed;
}

.searchbar-main-content {
    display: flex;
    justify-content:center;
}

.searchbar-main-content-wrapper {
    width: 70%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}

.publication-landing-body {
    flex:1 ;
}

.publication-landing-heading {
    font-size: 24px;
    max-width: 700px;
    text-align: center;
}