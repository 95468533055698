/*------------------------------------*\
    $MIXINS
\*------------------------------------*/

@mixin darkMode {
  html[data-theme='dark']  & {
    @content;
  }
}

@mixin alwaysMode {
  html[data-theme] & {
    @content;
  }
}

/** clearfix mixin.
* 
* @Summary Mixin to add micro clearfix into a selector.
* @see http://www.cssmojo.com/the-very-latest-clearfix-reloaded/ for futher information
* @example
*   .{Selector} {
*     @include clearfix();
*   }
*/
@mixin clearfix() {
  &:after {
    content: "" !important;
    display: block !important;
    clear: both !important;
  }
}

/** hidden-visually mixin.
* 
* @Summary Mixin to add accessibility hiding to elements.
* @example
*   .{Selector} {
*     @include hidden-visually();
*   }
*/
@mixin hidden-visually() {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

/** font-size mixin
* 
* @Summary Mixin to add rem font-size values with px fall-back
* @param $size
* @example
*   .{Selector} {
*     @include font-size(size);
*   }
*/
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
