
.parent-checkbox {
    margin-bottom: 10px;
}
.checkbox-label {
    font-size: 13px;
}

.checkbox-container {
    vertical-align: middle;
    margin-bottom: 12px;
    padding-right: 20px;
    box-sizing: border-box;
    width: 50%;
}

.tick-mark {
	position: relative;
	display: inline-block;
	width: 15px;
	height: 15px;
    margin-top: 3px;
    margin-left: -1px;

	&::before {
		position: absolute;
		left: 0;
		top: 50%;
		height: 50%;
		width: 3px;
		background-color: white;
		content: "";
		transform: translateX(10px) rotate(-45deg);
		transform-origin: left bottom;
	}
	&::after {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 3px;
		width: 100%;
		background-color: white;
		content: "";
		transform: translateX(10px) rotate(-45deg);
		transform-origin: left bottom;
	}
}
.checkmark-download-panel.disabled {
	background: unset;
	.tick-mark {
		&::before, &::after {
			position: unset;
			background-color: unset;
			transform: unset;
			transform-origin: unset;
		}
	}
}

.checkmark-download-panel {
    position: absolute;
    height: 100%;
    width: 100%;
    //transform: scale(0.75);
    background: $brandedcolor5;
    border: none;
    position: absolute;
    height: 148%;
    width: 148%;
    transform: scale(0.75);
    background: #0076a8;
    border: none;
    border-radius: 4px;
    margin-left: -4px;
    margin-top: -4px;
}

.checkmark-download-panel:not(.disabled) {
    position: absolute;
    height: 148%;
    width: 148%;
    transform: scale(0.75);
    background: $brandedcolor5;
    border: none;
    border-radius: 4px;
    margin-left: -4px;
    margin-top: -4px;
}

.checkbox-container-download-panel{
    width: 200%;
}

.checkbox-container-download-panel-child {
    margin-left: 24px;
}

.checkbox-container-download-panel-grandchild {
    margin-left: 48px;
}

.download-panel-article-text-checkbox {   
    margin-top: -10px;
}

@media only screen and (max-width: 1000px) {
    .checkbox-container {
        width: 100%;
    }
}

.check-svg {
  fill: none;
  stroke: $lightest_neutral;
  stroke-width: 2px;
}
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
.hidden-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  $lightest_neutral-space: nowrap;
  width: 1px;
  z-index: 99999;
}

.styled-checkbox {
    display: inline-block;
    min-width: 16px;
    width: 16px;
    height: 16px;
    transition: all 150ms;
    border: 1px solid $midlight_neutral;
    margin-right: 6px;
    position: relative;
    cursor: pointer;
    border-radius: 3px;
}

.styled-checkbox.checked:not(.disabled) {
    border-color: $brandedcolor5;
}

.checkmark {
    left: 50%;
    top: 50%;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
}

.checkmark-header {
    left: 50%;
    top: 50%;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    position: absolute;
}

.checkmark-settings-page{
    position: absolute;
    height: 100%;
    width: 100%;
    transform: scale(0.75);
    background: $brandedcolor5;
}


.styled-checkbox:not(.checked, .disabled):hover {
    border-color: $middark_cool;
}

.remove-filters-section-container {
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    display: flex;
    margin-bottom: 15px;
    padding: 1em;
    position: relative;
}

.remove-filters-options-container {
    flex-grow: 1;
    flex-wrap: wrap;
}

.remove-filter-container {
    background: $lightest_neutral $icon-x-black no-repeat calc(0% + 6px) center;
    background-size: 14px 14px;
    border-radius: 5px;
    border: 1px solid $middark_cool;
    display: inline-block;
    color: $darkest_neutral;
    font-size: 11px;
    padding-left: 25px;
    text-decoration: none;
    transition: all 0.2s ease;
    cursor: pointer;
    margin: 5px;
}

.remove-filter-container:hover {
    border-color: $darkest_neutral;
}

.remove-filter-text {
    border-left: 1px solid $middark_cool;
    display: inline-block;
    padding: 0.25em 0.5em;
    transition: all 0.2s ease;
}

.remove-filter-container:hover .remove-filter-text {
    border-color: $darkest_neutral;
}

.icon-x {
    background: $icon-x-black no-repeat center center;
    background-size: 20px 20px;
    background-color: transparent;
    border: none;
    text-indent: -9999px;
    height: 20px;
    width: 20px;
    min-width: 20px;
    margin-top: 5px;
    margin-left: 10px;
    transition: all 0.2s ease;
    cursor: pointer;

    @include darkMode {
        background-image: $icon-x-white;
    }

    &.icon-x-footnote-popup {
        padding-left: 10px;
        margin-top: 0;
        position: absolute;
        top: 20px;
        right: 15px;
        background-image: $icon-x-green;
        @include darkMode {
            background-image: $icon-x-green;
        }
    }
}

.icon-x:hover {
    transform: rotate(
        -90deg
        );
}

.child-checkboxes-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 24px;
}