/*------------------------------------*\
    Search Results
\*------------------------------------*/

.search-results-container {
    padding-right: clamp(10px, 11%, 150px);

    &.search-results-container-wide-mode {
        padding-left: clamp(10px, 11%, 150px);
    }

    .advertisement-container {
        display: flex;
        width: 600px;
        align-items: center;
        justify-content: space-between;
        padding: 20px 8px 20px 20px;
        border-radius: 12px;
        background: #F8FCF7;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 2em;
        @include darkMode {
			background: #212121;
            border: 1px solid #00b500;
		}
        .advertisement-box {
            display: flex;
            align-items: center;
            .chat-icon {
                line-height: 0;
                .chat-icon-img {
                    width: 25px;
                    margin: 0;
                    content: url('/images/chatbot-search-assis.png');
                    @include darkMode {
                        content: url('/images/chatbot-search-assis-wh.png');
                    }
                }
            }
            .advertisement-content {
                padding-left: 16px;
                .finding-answer {
                    display: flex;
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    font-weight: 500;
                    align-items: center;
                    .link-icon {
                        padding-left: 5px;
                        cursor: pointer;
                        .goto-next-icon {
                            width: 9px;
                            margin: auto 0;
                            height: 9px;
                            padding: 0;
                            border-radius: 2px 0 2px 2px;
                            content: url('/images/gotonext.svg');
                            @include darkMode{
                                content: url('/images/gotonext-dk.svg');
                            }
                        }
                    }
                    @include darkMode {
                        color: #f5f5f5;
                    }
                }
                .try-research-assistant {
                    display: flex;
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    align-items: center;
                    font-weight: 700;
                    @include darkMode {
                        color: #f5f5f5;
                    }
                }   
            }
        }
        .move-catalog {
            width: 95px;
            height: 35px;
            background: #417aa7;
            border: 0;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include darkMode {
                background: #00a3e0;
            }
            .right-arrow {
                width: 18px;
            }
        }
    }

    .search-block {
        text-align: left;
        margin: auto auto 2em auto;
        em {
            background-color:rgba(227,228,141,0.6);

            &.current {
                background-color: rgba(111, 194, 180, 0.6);
            }
        }
        .annot-modal.search-modal em{
            background-color: transparent;
        }

        .crumb-list{
            display:flex;
        }
        .crumb {
            font-size: 10px;
            color: var(--breadcrumb-color-active);
            margin: 0 0.3rem .5rem;

            span {
                display: inline-block;
                margin-right: 12px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .snippet {
            margin: .25rem 0 1rem;
        }

        .result-title-icon {
            display: flex;
            text-decoration: underline;
        }

        .icon-images {
            margin: -6px;
            max-width: 25px;
            width: 100%;
            height: 35px;
            padding: 0 5px;
        }
    }

    .search {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin: 1em 0 2em 0;
        font-size: 13px;
        background-size: 20px 20px;
        border: 1px solid #0097a9;

        &:active {
            border: 1px solid black;
        }
    }

    h1 {
        font-size: 1.25em;
        text-align: left;
        font-size: 1.5em;

        span {
            color: $middark_cool;
        }
    }

    h2 {
        font-size: 1em;
        font-weight: 400;

        a {
            font-size: 20px;
            font-weight: 500;
            cursor: pointer !important;

            &:hover {
                text-decoration-color: $middark_cool;
            }
        }
    }
    .search-article-container {
        h2 {
            font-weight: 600 !important;
            a {
                font-size: 14px;
            }
        }
        a {
            font-weight: 400;
            cursor: pointer !important;
            color: #0076a8;
            text-decoration: underline;
            font-size: 14px;
        }
    }
}
.search-article-container {
    width: 100%;
    min-height: auto;
}
.search-article-container .binary-block a.normal-link-in-reading-mode {
    color: #fff;
    text-decoration: none;
    background: #2489b4;
}
.search-article-container .file-container-indent {
    margin: 0;
    margin-right: 20px;
}

.search-modal-body {
    overflow-y: auto;
}
.search-article-container .article-title-button-container div p::before {
    display: none;
}
.search-modal-container{
    width: 69%;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    padding-left: 20px;
}
.search-modal-container.cf-right.shimmerArticleCard.shimmerArticleWrapper {
    width: 100%;
}
.search-modal-body::-webkit-scrollbar {
    width: 14px;
    height: 6px;
}

.search-modal-body::-webkit-scrollbar-thumb {
    background-color: $brandedcolor10;
    border-radius: 4px;
    scroll-padding-right: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;

    @include darkMode {
        background-color: $brandedcolor26;
    }
}

.search-template-modal {
    padding-top: 12.5px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.collection-search-navigablebar {
    top: .5rem;
    right: 17px ;
}

.collection-search-result-titles {
    margin-bottom: 1.5rem 
}

.collection-result-item-main {
padding: 8px 0 0 14px; 
font-size: 14px ;
}

.collection-result-item-main-container {
width: 90%;
}

.publication-listing-titles {
    margin-bottom: 1.5rem;
}

.search-message-ontoggle-alloff{
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 14px;
    color: inherit;
    font-style: italic;
}