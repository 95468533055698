/*------------------------------------*\
    MANUAL
\*------------------------------------*/
.manual {
	.toc {
		ul {
			padding: 0;
			font-weight: 700;
			font-size: 12px;
			line-height: 14px;
			list-style-type: none;

			li {
				padding: 0 0 0 13px;
				position: relative;

				&.expand {
					& > a {
						background-image: url("/images/tick-closed.svg");
						background-repeat: no-repeat;
						background-position: center left;
						background-size: 18px 18px;
						padding: 0 0 0 30px;
						transition: all 0.2s ease;

						&:hover {
							background-image: url("/images/tick-hover.svg");
							background-repeat: no-repeat;
							background-position: center left;
							background-size: 18px 18px;
							transition: all 0.2s ease;
						}
					}

					&.open {
						& > a {
							background-image: url("/images/tick-open.svg");
							background-repeat: no-repeat;
							background-position: center left;
							background-size: 18px 18px;
							transition: all 0.2s ease;

							&:hover {
								background-image: url("/images/tick-open-hover.svg");
								background-repeat: no-repeat;
								background-position: center left;
								background-size: 18px 18px;
								transition: all 0.2s ease;
							}
						}
					}
				}
			}

			ul {
				font-weight: 400;
				margin: 0 0 0 15px;
			}
		}
	}
}