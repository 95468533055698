.content-article-container {
    width: 85%;
    box-sizing: border-box;
    position: relative;
    padding: 0px;
    margin-left: auto;

    h1{
        font-size: 24px;
        line-height:28px ;
        line-height: 100%;
        text-align: left;
        vertical-align: top;
    
    }
    h2{
        font-size: 20px;
        line-height:23px ;
        line-height: 100%;
        text-align: left;
        vertical-align: top;
    
    }
    h3{
        font-size: 16px;
        line-height:19px ;
        line-height: 100%;
        text-align: left;
        vertical-align: top;
    
    }

    p, ul, ol, .p {
        font-size: 14px;
        line-height: 24px;
        line-height: 146%;
        text-align: left;
        vertical-align: top;
    }

    p, .p {
        margin-top:32px;
        margin-bottom:32px;
    }
	
    ol, ul {
        margin-top:32px;
        li {
            .p {
                margin: 0 !important;
            }
        }
    }
     
    div img{
        justify-content: center;
        max-width: 650px;
        align-content: center;
        height: auto;
        margin: 32px auto;
        width: auto;
        display: block;
    }

    .content-article-container-inner {
        max-width:85%;
    }
  }


.articlecontent-container {
    box-sizing: border-box;

    a {
        color: $brandedcolor5;
        text-decoration: underline;
        @include darkMode {
            color:$brandedcolor8;
        }
        &:hover {
            color: $brandedcolor2;
            @include darkMode {
                color: $brandedcolor9;
            }
        }
        &:active {
            color: $brandedcolor4;
            @include darkMode {
                color: $brandedcolor15;
            }
        }
    }
}

.topic-title {
    border: none;
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: 20px;
    margin-right: 60px;
  }

