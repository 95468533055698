/*------------------------------------*\
    Dashboard
\*------------------------------------*/
.dashboard {
    position: fixed;
    width: 535px;
	background-color: $lightest_neutral;
	border-radius: 7px;
	left: 5%;
	top: 50%;
    transform: translateY(-55%);
	box-shadow: 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
	z-index: 2;
	animation: transform 1s ease-in;
	h1 {
		margin: 0;
		a {
			border-bottom: none;
			display: block;
		}
		img {
			height: 50px;
		}
	}
	h2 {
		margin: 0 0 1em 0;
		font-size: 24px;
		font-weight: 400;
	}
}
.dashboard-content {
	padding: 50px 50px 20px;
	width: 80%;
}
.dashboard-buttons {
	h3 {
		font-size: 15px;
	}
	.dashboard-intro {
		margin: 20px 0 20px 5px;
		font-size: 16px;
	}
	.topic-buttons {
		margin: 0;
		padding: 0;
		display: flex;
		list-style-type: none;
		img {
			margin: 0 auto;
			width: 48px;
			padding: 13px 0 0 0;
		}
		li {
			padding: 0;
			margin: 0 2em 0 0;
			text-align: center;
			max-width: 76px;
			a {
				border: none;
				display: inline-block;
				.topic-img {
					border-radius: 50px;
					height: 72px;
					width: 72px;
					display: inline-block;
					border: 2px solid #0076A8;
					transition: border 0.2s ease;
				}
				.topic-title {
					font-size: 12px;
					line-height: 1.1em;
					margin: 0.75em 0 0 0;
					display: inherit;
					font-weight: 500;
					color: $darkest_neutral;
					transition: color 0.2s ease, text-decoration 0.2s ease;
					width: 90px;
				}
				&:hover {
					.topic-img {
						border: 2px solid $middark_cool;
						transition: border 0.2s ease;
					}
					.topic-title {
						color: $middarkest_neutral;
						text-decoration: underline;
						transition: color 0.2s ease, text-decoration 0.2s ease;
					}
				}

			}
		}
	}
}
.home-catalog-selector {
	font-size: 13px;
}

@media screen and (max-height: 730px){
	.dashboard{
		transform: scale(.9) translateY(-70%) !important;
	}
}
@media screen and (max-height: 650px){
	.dashboard{
		left: 2px !important;
		transform: scale(.8) translateY(-75%) !important;
	}
	.footer{
		transform: scaleY(.9) !important;
	}
}
@media screen and (max-height: 550px){
	.dashboard{
		transform: scale(.6) translateY(-100%) !important;
	}
	.footer{
		transform: scaleY(0.7) translate(0, 30px) !important;
	}
}
@media screen and (max-height: 470px){
	
	.footer{
		transform: scaleY(0.5) translate(0, 105px) !important;
	}
}
@media screen and (max-height: 430px){
	.dashboard{
		transform: scale(.5) translateY(-117%) !important;
		left: 0% !important;
	}
	.footer{
		transform: scaleY(0.5) translate(0, 70px) !important;
	}
}