/*------------------------------------*\
    Preview Warning
\*------------------------------------*/
.preview-warning {
	background-color: #fffbf3;
	border-top: 1px solid #ffeda8;
	border-bottom: 1px solid #ffeda8;
	color: $middarkest_neutral;
	position: sticky;
    top: 0;
    z-index: 10;
	p {
		margin: 5px 25px;
	}

	@include darkMode {
		background: $darkest_neutral;
    	color: $light_neutral;
    	border-color: $light_neutral;
	}
}