
.d-flex {
    display: flex;
}

.flex-gap-1 {
    gap: 1rem;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row; 
}

.flex-grow {
    flex-grow: 1;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.items-stretch {
    align-items: stretch;
}

.border-none {
    border: none;
}

.m-auto {
    margin: auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mt-auto {
    margin-top: auto;
}

.mb-auto {
    margin-bottom: auto;
}

.relative {
    position: relative;
}

.font-bold {
    font-weight: bold;
}

.hidden {
    visibility: hidden;
}

.p-absolute {
    position: absolute;
}
.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.invisible {
    visibility: hidden;
    opacity: 0;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.text-center {
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.opacity-zero {
    opacity: 0;
}

.font-weight-700 {
    font-weight: 700;
}

.u-disable {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}