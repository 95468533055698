.main-Container{
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 400px;
}

.body-container
{
    min-height: 350px;
    margin: 7%;
    margin-bottom: 1%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.body-container:hover{
    box-shadow: 0 8px 16px 0 rgba(200, 175, 175, 0.2);
}

.search-boc-container
{
    display: flex;
    justify-content: space-around;
    padding: 5px;
}

.searchbox{
    width: 70%;
    text-align: center;
    border-radius: 6px;
   
    border: 1px solid black;
}

.searchbox:hover{
    box-shadow: 0 4px 8px 0 rgba(12, 89, 213, 0.2);
}