/*------------------------------------*\
    FOOTER ALPHA
\*------------------------------------*/
.footer {
    margin: 20px 0 0 0;
    padding: 20px 20px;
    position: absolute;
    bottom: -100px;
    z-index: -2;

    &.footer-catalog,
    &.footer-homepage {
        margin: 50px 0 10px;
        background-color: $brandedcolor19;
        z-index: 1;

        .footer-item {
            display: inline-block;
            color: $lightest_neutral;

            a {
                color: inherit;
                text-decoration-color: $lightest_neutral;
            }
        }
    }

    &.footer-homepage {
        position: fixed;
        bottom: 0;
        padding: 15px 2em;
        width: calc(100% - 4em);
        justify-content: flex-start;
    }

    &.footer-catalog {
        justify-content: flex-start;
        width: auto;
        max-width: 100%;
        padding: 15px 2.1%;
        text-align: left;
    }
}

.footer-item {
    color: #808285;
    font-size: 12px;
    margin: 0 20px 0px 20px;
    font-weight: 500;

    a {
        text-decoration: underline;
        text-decoration-color: var(--footer-beta-color);
        color: inherit;
        border: none;
    }
}

html[data-theme="dark"] .footer-item {
    color: #F5F5F5;
}