/*------------------------------------*\
    Dashboard Background
\*------------------------------------*/
.dashboard-background {
    height: 100vh;
    object-fit: cover;
    font-family: 'object-fit: cover;';
	width: 100%;
}

