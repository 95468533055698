
$deloite-text-logo: url(/images/deloitte-rp-logo-new.svg);
$deloite-text-logo-white: url(/images/deloitte-rp-logo-wh.svg);
$deloitte-logo: url(/images/deloitte-mark-wh.svg);
$search-icon: url(/images/icon-search-gn.svg);
$search-icon-blue: url(/images/icon-search-bl.svg);
$arrow-right-green: url(/images/arrow-go-gn.svg);
$arrow-right-blue: url(/images/arrow-go-bl.svg);
$arrow-down-green: url(/images/icon-arrow-down-gn.svg);
$arrow-down-blue: url(/images/icon-arrow-down-bl.svg);
$arrow-up-white: url(/images/icon-arrow-up-wh.svg);
$arrow-up-green: url(/images/icon-arrow-up-gn.svg); 
$chevron-down-blue: url(/images/chevron-down-bl.svg);
$chevron-down-black-new: url(/images/chevron-down-bl.svg);
$chevron-down-black: url(/images/chevron-down-bk.svg);

$arrow-right-green-2: url(/images/chevron-right-gn.svg);
$arrow-right-blue-2: url(/images/chevron-right-bl.svg);

$caret-right-black: url(/images/icon-caret-right.svg);
$caret-down-black: url(/images/icon-caret-down.svg);


$icon-profile-black: url(/images/icon-profile-bk.svg);
$icon-profile-white: url(/images/icon-profile-wh.svg);
$icon-home-black: url(/images/icon-home-bk.svg);
$icon-home-blue: url(/images/icon-home-bl.svg);
$icon-home-white: url(/images/icon-home-wh.svg);
$icon-x-black: url(/images/icon-close-bk.svg);
$icon-x-white: url(/images/icon-close-wh.svg);
$icon-x-green: url(/images/icon-close-gr.svg);

$icon-manual: url(/images/icon-manual-off-bk.svg);
$icon-manual-white: url(/images/icon-manual-off-wh.svg);
$icon-guidebook: url(/images/icon-guidebook-off-bk.svg);
$icon-journal: url(/images/icon-journal-off-bk.svg);
$icon-expand: url(/images/icon-expanded-bk.svg);
$icon-expand-hover: url(/images/icon-expanded-bl.svg);
