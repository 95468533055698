.btn {
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    max-height: 36px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    background-color: transparent;
    border: 1px solid transparent;
    text-transform: uppercase;

    &:disabled,
    &-disabled {
        background-color: rgba(0,0,0, 0.03);
        color: $light_neutral ;
        opacity: .36;
        cursor: not-allowed;
    }

    img {
        margin: 0;
        padding: 0;
    }

    @include darkMode {
        color: white;
    }
}

.btn-primary {
    background-color: #0076a8;
    color: #ffffff;

    &:hover,
    &:focus {
        background-color: #012169;
    }

    &:active,
    &.active {
        background-color: #041e42;
    }

            @include darkMode {
                border: 1px solid $brandedcolor8;
                background: $brandedcolor8;
                color: $brandedcolor4;

                &:hover {
                    background: $brandedcolor25;
                    border: 1px solid $brandedcolor23;
                    color: $brandedcolor23;
                }

                &:active,
                &.active {
                    background: $brandedcolor24;
                    border: 1px solid $brandedcolor23;
                    color: $brandedcolor23;
                }
            }

}

.btn-secondary {
    background-color: #ffffff;
    border-color: #0076a8;
    color: #0076a8;

    &:hover,
    &:focus {
        background-color: rgba(0,0,0,0.06);
        border-color: #021269;
        color: #021269;
    }

    &:active,
    &.active {
        color: #041e42;
        border-color: #041e42;
    }

    svg {
        fill: #0076A8;
    }

    @include darkMode {
        background-color: transparent;

        &:hover,
        &:focus {
            background-color: transparent;
            border-color: white;
        }

        svg {
            fill: white;
        }
    }

}

.btn-critical {
    background-color: #da291c;
    color: #ffffff;

    &:hover,
    &:focus,
    &:active {
        background-color: #000000;
        border-color: #021269;
        color: #ffffff;
    }

}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: #218838;
        border-color: #1e7e34;
    }

}
.btn-text {
    
    color: #0076a8;

    &:disabled {
        background-color: rgba(0,0,0, 0.03);
        border-color: rgba(0,0,0,0.24);
        color: rgba(0,0,0, 0.24)
    }
}

.btn-slim {
    padding: 4px;
}

.btn-icon-round {
    border-radius: 50px;
    padding: 0;

    svg {
        fill: red;
    }
}
