.component {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  /* background-color: rgba(0,0,0,0.1); */
  color: #999;
  font-size: 23px;
  font-weight: bold;
}
