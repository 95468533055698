
.related-content-pub-title {
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;


}

.related-content-list-of-links-for-pub {
    margin-left: 20px;
}

.toc-list-item-related-content {
    margin-bottom: 8px;
}