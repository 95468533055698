body {
    margin: 0;
}

.no-access-anchor{
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}

.dashboard-body {
    font-family: "Roboto", Verdana, sans-serif;
    font-size: 16px;
    line-height: normal;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: #000;
    overflow-y: hidden;
}

.dashboard-background {
    height: 100vh;
    object-fit: cover;
    font-family: "object-fit: cover;";
    width: 100%;
}

.dashboard {
    position: fixed;
    width: 535px;
    background-color: #fff;
    border-radius: 7px;
    left: 5%;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
    box-shadow: 0 1px 1px rgba(9, 30, 66, .25), 0 0 1px rgba(9, 30, 66, .31);
    font-family: "Roboto", Verdana, sans-serif;
}

.dashboard h1 {
    margin: 0 0 1em;
    font-size: 30px;
    font-weight: 400;
}

.dashboard p {
    font-size: 16px;
    font-weight: 400;
}

.dashboard p i {
    font-size: 12px;
    font-weight: 400;
}

.dashboard-content {
    padding: 50px 50px 20px;
    width: 80%;
}

.dashboard-buttons .dashboard-intro {
    margin: 20px 0 20px 5px;
    font-size: 16px;
}

.dashboard-buttons .topic-buttons {
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
}

.dashboard-buttons .topic-buttons li a {
    border: none;
    display: inline-block;
}

.dashboard-buttons .topic-buttons img {
    margin: 0 auto;
    width: 48px;
    padding: 13px 0 0;
}

.dashboard-buttons .topic-buttons li a .topic-title {
    font-size: 12px;
    line-height: 1.1em;
    margin: .75em 0 0;
    display: inherit;
    font-weight: 500;
    color: #000;
    transition: color .2s ease, text-decoration .2s ease, -webkit-text-decoration .2s ease;
    width: 90px;
}

.topic-title {
    border: none;
}

.dashboard-buttons .topic-buttons li {
    padding: 0;
    margin: 0 2em 0 0;
    text-align: center;
    max-width: 76px;
}

.footer-homepage  {
    color: #fff;
    position: fixed;
    bottom: 0;
    padding: 15px 2em;
    width: calc(100% - 4em);
    justify-content: flex-start;
    margin: 50px 0 10px;
    background-color: rgba(0,0,0,.54);
    font-size: 12px;
    margin: 0 16px 0 0;
    font-weight: 500;
}

.footer-item {
    font-size: 12px;
    margin: 0 16px 0 0;
    font-weight: 500;
}

.footer-href {
    text-decoration-color: #fff;
    color: #fff;
}