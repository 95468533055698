.icon {
    aspect-ratio: 1 / 1;
    padding: 8px;
    width: 28px;
}

.icon-primary {
    fill: #00A3E0;
}

.icon-xs {
    width: 18px;
}

.icon-sm {
    width: 24px;
}

.icon-lg {
    width: 32px;
}

.icon-xl {
    width: 48px;
}